import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Row, Table, Carousel, Container} from "react-bootstrap";

const Feedbacks = () => {
    return (
        <>
            <Container className='h2 text-secondary text-center'>
                <Col md={12}>
                    Наші покупці про нас
                </Col>
            </Container>
            <Carousel indicators={false} className='d-sm-block d-none'>
                <Carousel.Item>
                    <Container>
                        <Table id='feedbacks'
                               className='text-center px-0 mb-3'>
                            <Row border="light" className='m-0 p-0'>
                                <Col>
                                    <div
                                        className='border border-gray-50 rounded bg-white'>
                                        <div style={{height: 160}}>
                                            <br/>
                                            {/*<FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>*/}
                                            {' '}
                                            Куртки також супер, і ціна і якість 👍
                                            <br/>
                                            Дякую менеджеру Сніжані за товар.
                                            <br/>
                                            Завжди чесно і приємно з такою людиною працювати.
                                        </div>
                                        <hr/>
                                        <div><b>Новий Буг, Лариса</b></div>
                                    </div>
                                </Col>
                                <Col className='bg-white px-2'>
                                    <div
                                        className='border border-gray-50 rounded bg-white'>
                                        <div style={{height: 160}}>
                                            {/*<FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>*/}
                                            {' '}
                                            Здравствуйте)
                                            Товар получила, и хочу вас поблагодарить!!!
                                            Вы лучшие! Все три мешка в идеале! Модные модели, ни одного отхода! Свитера просто Вау)
                                            А спорт микс вообще не ожидала, новое всё, модное и кофты с капюшоном супер! Я в восторге))
                                            {' '}
                                            {/*<FontAwesomeIcon icon="fa-solid fa-quote-right" className='text-danger'/>*/}
                                        </div>
                                        <hr/>
                                        <div className="bg-white"><b>Людмила, Вілково</b></div>
                                    </div>
                                </Col>
                                <Col className='bg-white'>
                                    <div
                                        className='border border-gray-50 rounded bg-white'>
                                        <div style={{height: 160}}>
                                            {/*<FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>*/}
                                            <br/>
                                            Снежана, здравствуйте.
                                            <br/>
                                            Спасибо большое за спорт, разобрала все класс.
                                            <br/>
                                            Как всегда ❤️❤️❤️
                                            {' '}
                                            {/*<FontAwesomeIcon icon="fa-solid fa-quote-right" className='text-danger'/>*/}
                                            <br/>
                                        </div>
                                        <hr/>
                                        <div className="bg-white"><b> Тетяна, м.Миколаїв</b></div>
                                    </div>
                                </Col>
                            </Row>
                        </Table>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Table id='feedbacks' className='text-center px-0 mb-3'>
                            <Row border="light" className='m-0 p-0'>
                                <Col>
                                    <div
                                        className='border border-gray-50 rounded bg-white'>
                                        <div style={{height: 160}}>
                                            <br/>
                                            Доброго дня )
                                            <br/>
                                            Товарчик просто космос))
                                            <br/>
                                            Дякую, правда носків жесть скільки багато😂
                                            <br/>
                                            Але все дуже сподобалось )
                                            <br/>
                                        </div>
                                        <hr/>
                                        <div><b>Ірина, Миронівка</b></div>
                                    </div>
                                </Col>
                                <Col className='bg-white px-2'>
                                    <div
                                        className='border border-gray-50 rounded bg-white'>
                                        <div style={{height: 160}}>
                                            <br/>
                                            Доброго дня!
                                            <br/>
                                            Забула подякувати вас за чудові ниточки!!!
                                            <br/>
                                            Я дуже задоволена.
                                            <br/>
                                            Чекаю ще , якщо з‘являться!
                                        </div>
                                        <hr/>
                                        <div className="bg-white"><b>Алла, Харків</b></div>
                                    </div>
                                </Col>
                                <Col className='bg-white'>
                                    <div
                                        className='border border-gray-50 rounded bg-white'>
                                        <div style={{height: 160}}>
                                            <br/>
                                            <br/>
                                            Дуже класні рюкзаки, лосіни супер!!!
                                            <br/>
                                            Дякую Вам!!!!
                                            <br/>
                                        </div>
                                        <hr/>
                                        <div className="bg-white"><b> Світлана, Полтава</b></div>
                                    </div>
                                </Col>
                            </Row>
                        </Table>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Table id='feedbacks' className='text-center px-0 mb-3'>
                            <Row border="light" className='m-0 p-0'>
                                <Col>
                                    <div
                                        className='border border-gray-50 rounded bg-white'>
                                        <div style={{height: 160}}>
                                            <br/>
                                            <br/>
                                            Добрый день, огромное спасибо за спортивные штаны.
                                            <br/>
                                            Есть ещё такие?
                                            <br/>
                                        </div>
                                        <hr/>
                                        <div><b>Південне, Наталія</b></div>
                                    </div>
                                </Col>
                                <Col className='bg-white px-2'>
                                    <div
                                        className='border border-gray-50 rounded bg-white'>
                                        <div style={{height: 160}}>
                                            <br/>
                                            <br/>
                                            Доброго дня, товар отримали.
                                            <br/>
                                            Все дуже добре, дякую
                                        </div>
                                        <hr/>
                                        <div className="bg-white"><b>Тарас, Винники</b></div>
                                    </div>
                                </Col>
                                <Col className='bg-white'>
                                    <div
                                        className='border border-gray-50 rounded bg-white'>
                                        <div style={{height: 160}}>
                                            <br/>
                                            <br/>
                                            Дуже гарний підхід маєте до клієнтів.
                                            <br/>
                                            Дякую
                                            <br/>
                                        </div>
                                        <hr/>
                                        <div className="bg-white"><b> Олена</b></div>
                                    </div>
                                </Col>
                            </Row>
                        </Table>
                    </Container>
                </Carousel.Item>
            </Carousel>

            <Carousel indicators={false} className='d-sm-none d-block text-center px-0 mb-3 '>
                <Carousel.Item>
                    <Container style={{height: 150}}>
                        <div>
                            <br/>
                            <FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>
                            {' '}
                            Куртки також супер, і ціна і якість 👍
                            <br/>
                            Дякую менеджеру Сніжані за товар.
                            <br/>
                            Завжди чесно і приємно з такою людиною працювати.
                            {' '}
                            <FontAwesomeIcon icon="fa-solid fa-quote-right" className='text-danger'/>
                            <br/>
                        </div>
                        <div><b>Новий Буг, Лариса</b></div>
                    </Container>
                </Carousel.Item>

                <Carousel.Item>
                    <Container style={{height: 150}}>
                        <div>
                            <FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>
                            {' '}
                            Здравствуйте)
                            Товар получила, и хочу вас поблагодарить!!!
                            Вы лучшие! Все три мешка в идеале! Модные модели, ни одного отхода! Свитера просто Вау)
                            А спорт микс вообще не ожидала, новое всё, модное и кофты с капюшоном супер! Я в восторге))
                            {' '}
                            <FontAwesomeIcon icon="fa-solid fa-quote-right" className='text-danger'/>
                        </div>
                        <div className="bg-white"><b>Людмила, Вілково</b></div>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container style={{height: 150}}>
                        <div>
                            <FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>
                            {' '}
                            Снежана, здравствуйте.
                            <br/>
                            Спасибо большое за спорт, разобрала все класс.
                            <br/>
                            Как всегда ❤️❤️❤️
                            {' '}
                            <FontAwesomeIcon icon="fa-solid fa-quote-right" className='text-danger'/>
                            <br/>
                        </div>
                        <div className="bg-white"><b> Тетяна, м.Миколаїв</b></div>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container style={{height: 150}}>
                        <div>
                            <br/>
                            <FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>
                            {' '}
                            Доброго дня )
                            <br/>
                            Товарчик просто космос))
                            <br/>
                            Дякую, правда носків жесть скільки багато😂
                            <br/>
                            Але все дуже сподобалось )
                            {' '}
                            <FontAwesomeIcon icon="fa-solid fa-quote-right" className='text-danger'/>
                            <br/>
                        </div>
                        <div><b>Ірина, Миронівка</b></div>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container style={{height: 150}}>
                        <div>
                            <FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>
                            {' '}
                            Доброго дня!
                            <br/>
                            Забула подякувати вас за чудові ниточки!!!
                            <br/>
                            Я дуже задоволена.
                            <br/>
                            Чекаю ще , якщо з‘являться!
                            {' '}
                            <FontAwesomeIcon icon="fa-solid fa-quote-right" className='text-danger'/>
                        </div>
                        <div className="bg-white"><b>Алла, Харків</b></div>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container style={{height: 150}}>
                        <div>
                            <br/>
                            <FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>
                            {' '}
                            Дуже класні рюкзаки, лосіни супер!!!
                            <br/>
                            Дякую Вам!!!!
                            {' '}
                            <FontAwesomeIcon icon="fa-solid fa-quote-right" className='text-danger'/>
                            <br/>
                        </div>
                        <div className="bg-white"><b> Світлана, Полтава</b></div>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container style={{height: 150}}>
                        <div>
                            <br/>
                            <FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>
                            {' '}
                            Добрый день, огромное спасибо за спортивные штаны.
                            <br/>
                            Есть ещё такие?
                            {' '}
                            <FontAwesomeIcon icon="fa-solid fa-quote-right" className='text-danger'/>
                            <br/>
                        </div>
                        <div><b>Південне, Наталія</b></div>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container style={{height: 150}}>
                        <div>
                            <br/>
                            <FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>
                            {' '}
                            Доброго дня, товар отримали.
                            <br/>
                            Все дуже добре, дякую
                            {' '}
                            <FontAwesomeIcon icon="fa-solid fa-quote-right" className='text-danger'/>
                        </div>
                        <div className="bg-white"><b>Тарас, Винники</b></div>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container style={{height: 150}}>
                        <div>
                            <br/>
                            <FontAwesomeIcon icon="fa-solid fa-quote-left" className='text-danger'/>
                            {' '}
                            Дуже гарний підхід маєте до клієнтів.
                            <br/>
                            Дякую
                            {' '}
                            <FontAwesomeIcon icon="fa-solid fa-quote-right" className='text-danger'/>
                            <br/>
                        </div>
                        <div className="bg-white"><b> Олена</b></div>
                    </Container>
                </Carousel.Item>
            </Carousel>

        </>

    )
};
export default Feedbacks;
