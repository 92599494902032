import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import BaseNavbar from "./BaseNavbar";
import Container from "react-bootstrap/Container";
import PushMessage from "../PushMessage";
import axios from "axios";
import {apiAddr} from "../../utils/api";

const SmsSend = () => {
    const {phone} = useParams();
    const [message, setMessage] = useState('');
    const [id, setId] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Sms відправлено',
        variant: 'light'
    });

    const handleChange = event => {
        const {value} = event.target;
        setMessage(value);
    };
    const handleCheck = () => {
        axios.get(apiAddr + '/sms_cred')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    console.log('check')
                    fetch('https://api.smscentre.com.ua/http/get_smslimit.php?login='
                        + response.data.login
                        + '&passwd='
                        + response.data.password
                        , {
                            method: 'GET',
                            headers: {
                                'Accept': '*/*'
                            },
                        })
                        .then((response) => response.text())
                        .then((response) => {
                            if (response.includes('RETURNCODE=00')) {
                                let smslimit = response.slice(24)
                                setToast({
                                    message: 'Кількість доступних смс : ' + smslimit + ' sms',
                                    variant: 'light'
                                })
                                setShow(true)
                            }
                        })
                        .catch(error => {
                            setToast({
                                message: error.message,
                                variant: 'warning'
                            })
                            setShow(true)
                        })
                }
            })
    }
    const handleCheckStatus = () => {
        axios.get(apiAddr + '/sms_cred')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    fetch('https://api.smscentre.com.ua/http/query_sm.php?login='
                        + response.data.login
                        + '&passwd='
                        + response.data.password
                        + '&smsid='
                        + id
                        , {
                            method: 'GET',
                            headers: {
                                'Accept': '*/*'
                            },
                        })
                        .then((response) => response.text())
                        .then((response) => {
                            console.log(response)
                            if (response.includes('RETURNCODE=00')) {
                                const stateEn = response.slice(24).trim()
                                console.log(stateEn)
                                if (stateEn == 'TOSEND') {
                                    setToast({...toast, message: "Статус відправленої смс : В ЧЕРЗІ НА ВІДПРАВКУ"})
                                    setShow(true)
                                } else if (stateEn == 'ENROUTE') {
                                    setToast({...toast, message: "Статус відправленої смс : В ПРОЦЕСІ ДОСТАВКИ"})
                                    setShow(true)
                                } else if (stateEn == 'PAUSED') {
                                    setToast({...toast, message: "Статус відправленої смс : ПРИЗУПИНЕНО"})
                                    setShow(true)
                                } else if (stateEn == 'CANCELED') {
                                    setToast({...toast, message: "Статус відправленої смс : ВІДМІНЕНО"})
                                    setShow(true)
                                } else if (stateEn == 'DELIVERED') {
                                    setToast({...toast, message: "Статус відправленої смс : УСПІШНО ДОСТАВЛЕНО"})
                                    setShow(true)
                                } else if (stateEn == 'FAILED') {
                                    setToast({...toast, message: "Статус відправленої смс : ПОМИЛКА ДОСТАВКИ"})
                                    setShow(true)
                                } else if (stateEn == 'EXPIRED') {
                                    setToast({...toast, message: "Статус відправленої смс : СПЛИВ ТЕРМІН ДОСТАВКИ"})
                                    setShow(true)
                                } else if (stateEn == 'UNDELIVERABLE') {
                                    setToast({...toast, message: "Статус відправленої смс : НЕ МОЖЕ БУТИ ДОСТАВЛЕНО"})
                                    setShow(true)
                                } else if (stateEn == 'REJECTED') {
                                    setToast({...toast, message: "Статус відправленої смс : ВІДХИЛЕНО СЕРВЕРОМ"})
                                    setShow(true)
                                } else if (stateEn == 'BADCOST') {
                                    setToast({...toast, message: "Статус відправленої смс : НЕ ВИЗНАЧЕНА ВАРТІСТЬ"})
                                    setShow(true)
                                } else if (stateEn == 'UNKNOWN') {
                                    setToast({...toast, message: "Статус відправленої смс : НЕВІДОМИЙ"})
                                    setShow(true)
                                } else {
                                    setToast({...toast, message: "Статус відправленої смс : " + stateEn})
                                    setShow(true)
                                }
                            }
                        })
                        .catch(error => {
                            setToast({
                                message: error.message,
                                variant: 'warning'
                            })
                            setShow(true)
                        })
                }
            })
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        if (!submitted) {
            // setIsLoading(true)
            let number = phone.slice(1)
            let messageCut = message.slice(0, 70)
            axios.get(apiAddr + '/sms_cred')
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        axios.get('https://api.smscentre.com.ua/http/submit_sm.php?login='
                            + response.data.login
                            + '&passwd='
                            + response.data.password
                            + '&destaddr=%2B'
                            + number
                            + '&msgtext='
                            + messageCut
                            + '&msgchrset=cyr',
                    )
                            .then((response) => {
                                setSubmitted(true)
                                if (response.data.includes('RETURNCODE=00')) {
                                    let smsid = response.data.slice(21)
                                    setId(smsid)
                                    // setIsLoading(false)
                                    setToast({
                                        message: 'Sms відправлено успішно. ID : ' + smsid,
                                        variant: 'light'
                                    })
                                    setShow(true)
                                }
                            })
                            .catch(error => {
                                setToast({
                                    message: error.message,
                                    variant: 'warning'
                                })
                                setShow(true)
                            })
                    }
                });
        }
    }

    return (
        <>
            <BaseNavbar/>
            <Container className='w-25 p-1 mt-4'>
                <InputGroup className='mt-4'>
                    <InputGroup.Text column-sm="3">Номер</InputGroup.Text>
                    <Form.Control
                        column-sm='9'
                        name='phone'
                        value={phone || ''}
                        disabled
                        className='fw-bold'
                        type="text"
                    />
                    {PushMessage({toast}, [show, setShow])}
                </InputGroup>
                <Form.Group as={Row} className='mt-3'>
                    <Col sm="12">
                        <Form.Control
                            name='message'
                            placeholder='текст повідомлення...'
                            value={message.slice(0, 70) || ''}
                            onChange={handleChange}
                            as="textarea"
                            rows='4'
                        />
                    </Col>
                </Form.Group>
                <Row className='text-center px-3'>
                    <Button
                        disabled={submitted}
                        onClick={event => {
                            handleSubmit(event)
                        }}
                        className='mt-3'
                        variant='outline-primary'>
                        ВІДПРАВИТИ SMS
                    </Button>
                    <Button
                        onClick={handleCheckStatus}
                        className='mt-3'
                        variant='outline-info'>
                        ПЕРЕВІРИТИ СТАТУС
                    </Button>

                    <Button
                        onClick={handleCheck}
                        className='mt-3'
                        variant='outline-warning'>
                        ПЕРЕВІРИТИ ЗАЛИШОК
                    </Button>
                </Row>
            </Container>
        </>
    );
};

export default SmsSend;
