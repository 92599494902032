import React, {useState} from 'react';
import BaseNavbar from "./BaseNavbar";
import {Button, Col, Form, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import PushMessage from "../PushMessage";
import {useNavigate} from "react-router-dom";
import {apiAddr} from "../../utils/api";

const NewCustomer = () => {
    const [customer, setCustomer] = useState(
        {
            name: '',
            comment: '',
            phone: '',
            email: '',
            viber: '',
            phone2: '',
            region: '',
            city: '',
            address: '',
            delivery: '',
            orders: [],
            user: 0,
            created: new Date(),
            is_deleted: false,
            is_active: true,
            generation: 2
        }
    );
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Нового клієнта додано',
        variant: 'light'
    });
    const handleChange = event => {
        const {name, value} = event.target;
        setCustomer({...customer, [name]: value});
        console.log(name + ' : ' + value)
    };
    const handleCheck = event => {
        const {name} = event.target;
        let val = !customer[name]
        setCustomer({...customer, [name]: val});
        console.log(name + ' : ' + val)
    };
    const handleSubmit = () => {
        const values =
            {
                name: customer.name,
                comment: customer.comment,
                phone: customer.phone,
                email: customer.email,
                viber: customer.viber,
                phone2: customer.phone2,
                region: customer.region,
                city: customer.city,
                address: customer.address,
                delivery: customer.delivery,
                // orders: customer.orders,
                user: customer.user,
                created: customer.created,
                isDeleted: customer.is_deleted,
                isActive: customer.is_active,
                generation: 2
            }

      if (customer.phone.length!==13 || customer.phone.slice(0, 1)!=='+') {
          setToast({message: 'Введіть номер телефону в вірному форматі', variant: 'danger'})
          setShow(true)
      } else {
            fetch(apiAddr+'/customers', {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 201) {
                        setToast({message: 'Нового клієнта додано', variant: 'light'})
                        setShow(true);
                    }
                })
                .catch((error) => {
                    setToast({message: error.message, variant: 'warning'})
                    setShow(true);
                });
        }

    }

    return (
        <>
            <BaseNavbar/>
            <Container>
                {PushMessage({toast}, [show, setShow])}
                <Form.Group as={Row} className='mt-2'>
                    <Form.Group as={Col} className='mt-3'>
                        <Form.Group as={Row} className='mt-4  text-danger'>
                            <Form.Label column sm="2">Імʼя</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='name'
                                    value={customer.name || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4  text-danger'>
                            <Form.Label column sm="2">Телефон</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='phone'
                                    value={customer.phone || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4  text-danger'>
                            <Form.Label column sm="2">Область</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='region'
                                    value={customer.region || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4 text-danger'>
                            <Form.Label column sm="2">Місто</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='city'
                                    value={customer.city || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Адреса</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='address'
                                    value={customer.address || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-3'>
                            <Form.Group as={Col} className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    label="активний"
                                    name='is_active'
                                    onChange={handleCheck}
                                    checked={customer.is_active}
                                />
                            </Form.Group>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group as={Col} className='mt-3'>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='email'
                                    value={customer.email || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Viber</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='viber'
                                    value={customer.viber || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Телефон2</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='phone2'
                                    value={customer.phone2 || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Доставка</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='delivery'
                                    value={customer.delivery || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Примітки</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='comment'
                                    value={customer.comment || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Container className='text-center'>
                            <Form.Group as={Row} className='mt-4 text-center'>
                                <Col>
                                    <Button
                                        className='mx-auto'
                                        onClick={() => navigate(-1)}
                                        variant='outline-danger'>
                                        Відмінити
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className='mx-auto'
                                        onClick={handleSubmit}
                                        variant="outline-success">
                                        ВПЕРЕД
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Container>

                    </Form.Group>
                </Form.Group>
            </Container>

        </>
    );
};

export default NewCustomer;
