import Container from "react-bootstrap/Container";
import {Button, Col, Row, Table} from "react-bootstrap";
import {ExportToXLS} from "./ExportToXLS";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Loader} from "../Loader";
import {apiAddr, pubApiAddr} from "../../utils/api";
import slugify from "../../utils/slugify";



const Prices = () => {
    const [products, setProducts] = useState([
        {
            id: '',
            code: '',
            category: '',
            name: '',
            tara: 0,
            unit: '',
            price_ko: 0,
            show_price: false,
            is_top: false,
        }
    ]);
    const centsToEur = (eur) => {
        return eur / 100
    }
    const [isLoading, setIsLoading] = useState(true);

    const ProductRow = (product,  index) => {
        return (
            <tr
                itemProp="offers"
                itemScope
                itemType="https://schema.org/Offer" key={index}>
                <td className='d-none d-sm-block'>
                    <a
                        itemProp="productID"
                        title={product.name+' категорії '+product.category}
                        className='text-secondary fw-bold'
                        href={'/product/' + product.id +'/'+slugify(product.name+' '+product.category)}>
                        <span itemProp="productID">{product.code}</span>
                    </a>
                    <span
                        itemProp="image"
                        content={product.image}
                    />
                    <link itemProp="availability" href="https://schema.org/InStock" content="In stock"/>
                </td>
                <td>
                    <a
                        itemProp="url"
                        title={product.name+' категорії '+product.category}
                        className='text-secondary fw-bold'
                        href={'/product/' + product.id +'/'+slugify(product.name+' '+product.category)}>
                        <span itemProp="name">{product.name}</span>
                    </a>
                </td>
                <td>
                    <span itemProp="category">{product.category}</span>
                </td>
                <td>{product.tara}{' '}{product.unit}</td>
                <td>
                    <link itemProp="availability" href="https://schema.org/InStock" content="In stock"/>
                    <span itemProp="priceCurrency" content="EUR"/>
                    <span itemProp="price" content={centsToEur(product.price_ko).toFixed(2)}>
                    {centsToEur(product.price_ko).toFixed(2)}
                    </span>
                </td>
            </tr>
        )
    };

    const [isShowMore, setIsShowMore] = useState(true);
    const toggleReadMore = () => {
        setIsShowMore(!isShowMore);
    };

    const ProductTable = (prods) => prods.map((product, id) => ProductRow(product,  id))

    const TableHeader =
        <thead className='bg-secondary text-white'>
            <tr>
                <th className='d-none d-sm-block'>КОД</th>
                <th>НАЗВА</th>
                <th>КАТЕГОРІЯ</th>
                <th>УПАКОВКА</th>
                <th className='d-none d-sm-block'>ЦІНА, € за кг</th>
                <th className='d-sm-none d-block'>ЦІНА</th>
            </tr>
        </thead>

    useEffect(() => {
        axios.get(pubApiAddr+'/product')
            .then((response) => {
                if (response) {
                    setIsLoading(false);
                }
                if (response.status === 200 && response.data.products) {
                    setProducts(response.data.products.filter(product => product.show_price))
                }
            });
    }, []);

    if (isLoading) {
        return (
            <Loader/>
        )
    }

    return (
        <Container id='prices' className=' text-center px-0 mb-3'>
            <h2 className='text-secondary'>
                Оптові ціни
            </h2>

            <br/>
            <h5 className='text-secondary'>
                Ми продаємо секонд-хенд тільки гуртом.
                Мінімальна гуртова партія замовлення для відправки - 30 кг.
                Оптові ціни на весь асортимент товару доступні в актуальному прайс-листі, який ви можете відкрити або скачати нижче

            </h5>
            <Table striped bordered hover size='sm' className='text-center'>
                {TableHeader}
                <tbody>
                    {isShowMore?  ProductTable(products.filter(product => product.is_top)) : ProductTable(products)}
                </tbody>
            </Table>
            <Row className='my-4'>
                <Col className='d-none d-sm-block'>

                        <ExportToXLS exportData={products} fileName={'euromix_secondhand_price'}/>

                </Col>
                <Col>
                    <Button
                        onClick={(e)=> toggleReadMore()}
                        className=''
                        variant="outline-secondary"
                        type="submit">
                        <h3>
                            {isShowMore? 'Розгорнути прайс-лист' : 'Згорнути прайс-лист'}
                        </h3>
                    </Button>
                </Col>
            </Row>
            <p>
                Всі ціни в прайс-листі зазначені в Євро за 1 кг, якщо не вказано інше.
                {/*Оплата здійснюється в гривнях на рахунок продавця за курсом на день оплати і згідно виставленого рахунку.*/}
                {/*Відправка товару здійснюється за передоплатою або післяплатою.*/}
                {/*При оплаті післяплатою оплата проводиться кур'єру на місці або в відділенні кур'єрської служби при отриманні товару.*/}
            </p>
            <hr/>
        </Container>
    )
};
export default Prices;
