import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Button, Col, Form, InputGroup, Row, Table} from "react-bootstrap";
import PushMessage from "../PushMessage";
import dateForm from "../../utils/dateForm";
import {useNavigate, useParams} from "react-router-dom";
import dateNorm from "../../utils/dateForm";
import BaseNavbar from "./BaseNavbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {apiAddr} from "../../utils/api";
import {OrderStatuses} from "../../utils/library";
import UpdateState from "./UpdateState";
import LoadingOverlay from "react-loading-overlay-ts";
import NewUpdateState from "./NewUpdateState";

const EditOrder = () => {
        const navigate = useNavigate();
        const {id} = useParams();
        const [changedProducts, setChangedProducts] = useState([]);
        const [order, setOrder] = useState({
            id: null,
            code: null,
            date: new Date(),
            status: 'Новий',
            payment: '',
            delivery: '',
            comment: '',
            customer: null,
            customer_name: '',
            created: {},
            updated: {},
            products: [
                {
                    id: null,
                    code: '',
                    name: '',
                    cost_brutto: 0,
                    price: 0,
                    ordered_quantity: 0,
                    summ: 0
                }
            ],
            uah: null,
            eur: null,
            usd: null,
            quantity: 0,
            summ: 0,
            user: null,
            user_name: '',
        })
        const [products, setProducts] = useState([
            {
                id: '',
                code: '',
                name: '',
                category: '',
                cost_netto: '',
                cost_brutto: '',
                price_ko: '',
                price_mo: '',
                comment: '',
                tara: 0,
                unit: '',
                quantity: 0,
                ordered: false,
                ordered_quantity: 0,
                summ: 0
            }
        ]);
        const [customer, setCustomer] = useState([{}]);
        const [users, setUsers] = useState([])

        const [hideUnordered, setHideUnordered] = useState(true)
        const [currate, setCurrate] = useState(0)
        const toggleHideUnordered = () => {
            setHideUnordered(!hideUnordered)
        }
        const centsToEur = (eur) => {
            return eur / 100
        }
        const [large, setLarge] = useState(true)
        const toggleLarge = (pos) => {
            setLarge(pos)
            pos ? setProducts(largeProducts(products)) : setProducts(littleProducts(products))
        }
        const [show, setShow] = useState(false);
        const [toast, setToast] = useState({
            message: 'Дані замовлення оновлено',
            variant: 'light'
        });
        const [overlayActive, setOverlayActive] = useState(false);
        const upProducts = (prods) => {
            prods.forEach(element => {
                element.ordered = false;
                element.ordered_quantity = 0;
                element.summ = 0;
                order.products.forEach(pr => {
                        if (Number(element.id) === Number(pr.id)) {
                            element.ordered = true
                            element.ordered_quantity = pr.ordered_quantity;
                            element.summ = pr.summ;
                        }
                    }
                )
            })
            return (prods)
        }
        const largeProducts = (prods) => {
            prods.forEach(function (element) {
                element.summ = element.price_ko * element.ordered_quantity;
            });
            return (prods)
        }

        const littleProducts = (prods) => {
            prods.forEach(function (element) {
                element.summ = element.price_mo * element.ordered_quantity;
            });
            return (prods)
        }

        const CalculateQuantity = () => {
            let Quantity = 0
            products.forEach(product => {
                if (product.category !== 'послуга') {
                    Quantity += product.ordered_quantity
                }
            })
            return Quantity
        }
        const CalculateSumm = () => {
            let Summ = 0
            products.forEach(product => {
                Summ = Summ + Number(product.summ)
            })
            return Math.round(Summ*100)/100
        }

        const handleChange = event => {
            const {name, value} = event.target;
            if (name === 'user') {
                let usr = users.find(el => el.id.toString() === value)
                setOrder({...order, user_name: usr.name, user: usr.id});
            } else {
                setOrder({...order, [name]: value});
            }
        };

        const handleProductChange = (event, type) => {
            const {name, value} = event.target;
            const pid = Number(name)
            const Arr = [...products]
            const aid = Arr.findIndex(el => Number(el.id) === pid) //find id in products of handled product
            const Prr = [...order.products]
            const bid = Prr.findIndex(el => Number(el.id) === pid) // find id in order.product of handled product
            let PV = Arr[aid].ordered_quantity

            if (type === 'incr') {
                Arr[aid].ordered_quantity = Arr[aid].ordered_quantity + Arr[aid].tara;
                Arr[aid].quantity = Arr[aid].quantity - Arr[aid].tara;
                changedProducts.push({id: pid, change: Arr[aid].ordered_quantity - PV})
            } else if (type === 'decr') {
                Arr[aid].ordered_quantity = Arr[aid].ordered_quantity - Arr[aid].tara;
                Arr[aid].quantity = Arr[aid].quantity + Arr[aid].tara;
                changedProducts.push({id: pid, change: Arr[aid].ordered_quantity - PV})
            } else {

                Arr[aid].ordered_quantity = Number(value);
                Arr[aid].quantity = Arr[aid].quantity + PV - Number(value);
                changedProducts.push({id: pid, change: Arr[aid].ordered_quantity - PV})
            }
            if (
                Arr[aid].ordered === false) { // for prev unordered product set default values and push to order.products
                Arr[aid].ordered = true;
                Prr.push({
                    id: pid,
                    code: Arr[aid].code,
                    name: Arr[aid].name,
                    ordered_quantity: Arr[aid].ordered_quantity,
                    summ: large ? Arr[aid].ordered_quantity * Arr[aid].price_ko : Arr[aid].ordered_quantity * Arr[aid].price_mo
                })
                setOrder({...order, products: Prr})
                Arr[aid].summ = large ? Arr[aid].ordered_quantity * Arr[aid].price_ko : Arr[aid].ordered_quantity * Arr[aid].price_mo;
                setProducts(Arr);
                console.log('pushed new pr to ord prod')
                console.log(Prr)
            } else {
                Prr[bid].ordered_quantity = Arr[aid].ordered_quantity
                Arr[aid].summ = large ? Arr[aid].ordered_quantity * Arr[aid].price_ko : Arr[aid].ordered_quantity * Arr[aid].price_mo;
                Prr[bid].summ = Arr[aid].summ
                setProducts(Arr);
                setOrder({...order, products: Prr})
            }
        }
        const setOrderCode = () => {
            if (order.code === '') {
                return (36000 + order.id) + '-' + dateNorm(order.created)
            } else {
                return order.code
            }
        }
        const CustomerUpdate = (id, update) => {
            setIsFetching(true)
            fetch(apiAddr + '/customers/' + id, {
                method: 'PUT',
                body: JSON.stringify(update),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 200) {
                        setIsFetching(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }
        const handleCustomer = (event, id) => {
            const {name, value} = event.target;
            if (event.keyCode === 13) {
                let update = {[name]: value};
                setCustomer({...customer, [name]: value})
                CustomerUpdate(id, update)
            }
        }
        const handleSubmit = () => {
            setIsFetching(true)
            setOverlayActive(true)
            const ordprod = products.filter(product => product.ordered === true);
            const p = []
            // CHECK CHANGES IN ORDERED PRODUCTS
            const ArrC = []
            changedProducts.forEach(chp => {
                const aidx = ArrC.findIndex(a => a.id === chp.id)
                if (aidx !== -1) {
                    ArrC[aidx] = {id: chp.id, change: ArrC[aidx].change + chp.change}
                } else {
                    ArrC.push(chp)
                }
            })
            const unchanged = ArrC.filter(a => a.change !== 0).length === 0
            console.log(unchanged)
            if (unchanged) {
                ordprod.forEach(o => {
                    p.push({
                        id: o.id,
                        code: o.code,
                        name: o.name,
                        cost_brutto: o.cost_brutto,
                        price: o.summ / o.ordered_quantity,
                        ordered_quantity: o.ordered_quantity,
                        summ: o.summ,
                        large: large
                    })
                })
                const values =
                    {
                        code: setOrderCode(),
                        customer: Number(order.customer),
                        customerName: order.customer_name,
                        comment: order.comment,
                        status: order.status,
                        payment: order.payment,
                        created: order.created,
                        updated: new Date(),
                        user: Number(order.user),
                        userName: order.user_name,
                        uah: Number(order.uah),
                        eur: Number(order.eur),
                        usd: Number(order.usd),
                        date: order.date,
                        delivery: order.delivery,
                        isDeleted: false,
                        userUpdated: Number(order.user),
                        products: p,
                        quantity: CalculateQuantity(),
                        summ: Math.round(CalculateSumm())
                    }

                fetch(apiAddr + '/orders/' + order.id, {
                    method: 'PUT',
                    body: JSON.stringify(values),
                    headers: {'Content-Type': 'application/json',}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            setToast({
                                message: 'Дані замовлення оновлено.',
                                variant: 'light'
                            });
                            setShow(true)
                            setIsFetching(false)
                            setOverlayActive(false)
                        }
                    })
                    .catch((error) => {
                        setToast({
                            message: error.message,
                            variant: 'danger'
                        });
                        setShow(true)
                    })
            } else {

                console.log(ArrC.filter(a => a.change !== 0))
                const toUpdate = ArrC.filter(a => a.change !== 0)
                ordprod.forEach(o => {
                    p.push({
                        id: o.id,
                        code: o.code,
                        name: o.name,
                        cost_brutto: o.cost_brutto,
                        price: o.summ / o.ordered_quantity,
                        ordered_quantity: o.ordered_quantity,
                        summ: o.summ,
                        large: large
                    })
                })
                const values =
                    {
                        code: setOrderCode(),
                        customer: Number(order.customer),
                        customerName: order.customer_name,
                        comment: order.comment,
                        status: order.status,
                        payment: order.payment,
                        created: order.created,
                        updated: new Date(),
                        user: Number(order.user),
                        userName: order.user_name,
                        uah: Number(order.uah),
                        eur: Number(order.eur),
                        usd: Number(order.usd),
                        date: order.date,
                        delivery: order.delivery,
                        isDeleted: false,
                        userUpdated: Number(order.user),
                        products: p,
                        quantity: CalculateQuantity(),
                        summ: Math.round(CalculateSumm())
                    }

                fetch(apiAddr + '/orders/' + order.id, {
                    method: 'PUT',
                    body: JSON.stringify(values),
                    headers: {'Content-Type': 'application/json',}
                })
                    .then((response) => {
                        // PRODUCTS STATE UPD
                        NewUpdateState('get', toUpdate)
                        setToast({
                            message: 'Дані замовлення оновлено.',
                            variant: 'light'
                        });
                        setTimeout(() => {
                            setShow(true)
                            setIsFetching(false)
                            setOverlayActive(false)
                        }, 1000)
                    })
                    .catch((error) => {
                        setToast({
                            message: error.message,
                            variant: 'danger'
                        });
                        setShow(true)
                    })

            }
        }

        const [isFetching, setIsFetching] = useState(false);
        const [loaded, setLoaded] = useState(false);
        const [loaded1, setLoaded1] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
        const [isLoading1, setIsLoading1] = useState(true);
        const [isLoading2, setIsLoading2] = useState(true);
        const [isLoading3, setIsLoading3] = useState(true);
        const [isLoading4, setIsLoading4] = useState(true);

        const [user, setUser] = useState({
            user: '',
            role: ''
        });
        const Statuses = () => {
            if (order.status === 'Оплачений' && user.role !== 'ROLE_ADMIN') {
                return (
                    OrderStatuses.slice(1).map(s => <option key={s.id} value={s.name}>{s.name}</option>)
                )
            } else {
                return (
                    OrderStatuses.map(s => <option key={s.id} value={s.name}>{s.name}</option>)
                )
            }
        }

        useEffect(() => {
            let currentUser = localStorage.getItem('user')
            let operate = localStorage.getItem('operate')
            setUser(
                {
                    user: currentUser,
                    role: operate
                })
        }, [localStorage]);

        useEffect(() => {
                if (!loaded1) {
                    setIsLoading4(true);
                    axios.get(apiAddr + '/orders/' + id)
                        .then((response) => {
                            if (response.status === 200 && response.data) {
                                setOrder(response.data)
                                setLarge(response.data.products[0].large)
                                setLoaded1(true)
                                setIsLoading4(false);
                            }
                        })
                }
            }
            , []);

        useEffect(() => {
                if (!loaded) {
                    setIsLoading(true);
                    axios.get(apiAddr + '/products')
                        .then((response) => {
                            if (response.status === 200 && response.data) {
                                setProducts(upProducts(response.data['hydra:member']))
                                setLoaded(true)
                                setIsLoading(false);
                            }
                        })
                } else {
                    setProducts(upProducts(products))
                }
            }
            , [order]);

        useEffect(() => {
                axios.get(apiAddr + '/rates')
                    .then((response) => {
                        if (response.status === 200 && response.data) {
                            let lastElement = response.data['hydra:member'][0].rate;
                            setCurrate(lastElement / 100)
                            setIsLoading1(false);
                        }
                    });
            }
            , [order]);

        useEffect(() => {
                if (order.customer) {
                    axios.get(apiAddr + '/customers/' + order.customer)
                        .then((response) => {
                            if (response.status === 200 && response.data) {
                                let cust = response.data;
                                setCustomer(cust);
                                setIsLoading2(false);
                            }
                        });
                }
                return
            }
            , [order.customer]);

        useEffect(() => {
            axios.get(apiAddr + '/users')
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        let usrs = response.data['hydra:member'];
                        setUsers(usrs);
                        setIsLoading3(false);
                    }
                });
        }, []);

        const Order = (product) => {
            return (
                <InputGroup className="">
                    <Button
                        name={product.id}
                        disabled={order.status === 'Відправлено'}
                        variant="outline-secondary"
                        value={product.ordered_quantity}
                        onClick={event => {
                            handleProductChange(event, 'incr')
                        }}
                    >
                        +
                    </Button>
                    <Form.Control
                        name={product.id}
                        disabled={order.status === 'Відправлено'}
                        type="number"
                        value={product.ordered_quantity || ''}
                        onChange={event => {
                            handleProductChange(event, 'edit')
                        }}
                    />
                    <Button
                        name={product.id}
                        disabled={order.status === 'Відправлено'}
                        variant="outline-secondary"
                        value={product.ordered_quantity}
                        onClick={event => {
                            handleProductChange(event, 'decr')
                        }}
                    >
                        -
                    </Button>
                </InputGroup>
            )
        }

        const ProductRow = (product, index) => {
            return (
                <tr key={index} className={(hideUnordered) && (product.ordered !== true) ? 'd-none' : ''}>
                    <td><a className='text-dark fw-bold' href={'/base/product/' + product.id}>{product.code}</a></td>
                    <td width={160} className='text-center'>
                        {Order(product)}
                    </td>
                    <td>{product.name}</td>
                    <td className='text-center'>{product.category}</td>
                    <td className='text-center'>
                        {large ?
                            product.price_ko ? centsToEur(product.price_ko).toFixed(2) : '0.00'
                            :
                            product.price_mo ? centsToEur(product.price_mo).toFixed(2) : '0.00'}
                    </td>
                    <td className='text-center'>{product.quantity}</td>
                    <td>{product.comment}</td>
                    <td className='text-center'>{(product.summ / 100).toFixed(2) || ''}</td>
                </tr>
            )
        };
        const ProductTable = products.map((product, index) => ProductRow(product, index))
        const TableHeader =
            <thead>
                <tr>
                    <th>КОД</th>
                    <th>РЕЗЕРВ</th>
                    <th>НАЗВА</th>
                    <th>КАТЕГОРІЯ</th>
                    <th>ЦІНА</th>
                    <th>СКЛАД</th>
                    <th>ПРИМ</th>
                    <th>СУМА</th>
                </tr>
            </thead>
        const Panel =
            <Form.Group as={Row} className='w-100 bordered rounded bg-secondary bg-gradient mx-0 mb-2 text-white pt-3'>
                <Form.Group as={Col} sm={2} className="mb-3">
                    <Button variant="light">
                        Курс : {currate.toFixed(2)}
                    </Button>
                </Form.Group>
                <Form.Group as={Col} sm={5} className="mt-2">
                    <Form.Check
                        inline
                        disabled={order.status === 'Відправлено'}
                        defaultChecked={large}
                        name='group'
                        label="Великий гурт"
                        type='radio'
                        onClick={(event) => toggleLarge(true)}
                        id='inline-radio-1'
                    />
                    <Form.Check
                        inline
                        disabled={order.status === 'Відправлено'}
                        defaultChecked={!large}
                        name='group'
                        label="Дрібний гурт"
                        type='radio'
                        onClick={(event) => toggleLarge(false)}
                        id='inline-radio-2'
                    />
                </Form.Group>
                <Form.Group as={Col} sm={3} className="mt-2 text-right">
                    <Form.Check
                        disabled={order.status === 'Відправлено'}
                        type="checkbox"
                        label="Відкрити інші товари"
                        onClick={(event) => toggleHideUnordered()}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={2} className="mb-3">
                    <Button
                        className='mr-auto'
                        onClick={() => navigator.clipboard.writeText((currate * CalculateSumm() / 100).toFixed(0))}
                        variant="light">
                        <b>
                        {(currate * CalculateSumm() / 100).toFixed(0)}
                        </b>
                        {' грн'}
                    </Button>
                </Form.Group>
            </Form.Group>

        if (isLoading && isLoading1 && isLoading2 && isLoading3 && isLoading4) {
            return (<Loader/>)
        }

        return (
            <>
                <LoadingOverlay
                    active={overlayActive}
                    spinner
                    text='Зачекайте на запис даних до бази даних ...'
                >
                    <BaseNavbar/>
                    <Container className='mt-3'>
                        <a href={'print/' + order.id}>
                            <FontAwesomeIcon size='2x' color='grey' icon={faPrint}/>
                        </a>
                        {PushMessage({toast}, [show, setShow])}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Group as={Col} className="mb-3">
                                <Form.Control
                                    type="text"
                                    disabled={order.status === 'Відправлено'}
                                    name='name'
                                    defaultValue={customer.name || ''}
                                    onKeyDown={event => handleCustomer(event, customer.id)}
                                    className='text-center fw-bold'

                                />
                                <Form.Group as={Row} className='mt-3'>
                                    <Form.Label column sm="2">Телефон</Form.Label>
                                    <Col sm="8">
                                        <Form.Control
                                            name='phone'
                                            disabled={order.status === 'Відправлено'}
                                            defaultValue={customer.phone || ''}
                                            onKeyDown={event => handleCustomer(event, customer.id)}
                                            type="text"
                                        />
                                    </Col>
                                    <Col sm="2">
                                        <Button
                                            href={'/base/sms/' + customer.phone}
                                            target="_blank"
                                            className="mx-auto mb-2 px-3 text-primary border-primary"
                                            variant="outline-light">
                                            SMS
                                        </Button>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mt-3'>
                                    <Form.Label column sm="2">Viber</Form.Label>
                                    <Col sm="8">
                                        <Form.Control
                                            name='viber'
                                            disabled={order.status === 'Відправлено'}
                                            defaultValue={customer.viber || ''}
                                            onKeyDown={event => handleCustomer(event, customer.id)}
                                            type="text"
                                        />
                                    </Col>
                                    <Col sm="2">
                                        <Button
                                            href={"viber://chat?number=" + customer.viber}
                                            style={{
                                                color: '#7360f2',
                                                borderColor: '#7360f2'
                                            }

                                            }
                                            className="mx-auto mb-2 px-3"
                                            variant="outline-light">
                                            Viber
                                        </Button>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mt-3'>
                                    <Form.Label column sm="2">Регіон</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            name='region'
                                            disabled={order.status === 'Відправлено'}
                                            defaultValue={customer.region || ''}
                                            onKeyDown={event => handleCustomer(event, customer.id)}
                                            type="text"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mt-3'>
                                    <Form.Label column sm="2">Місто</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            name='city'
                                            disabled={order.status === 'Відправлено'}
                                            defaultValue={customer.city || ''}
                                            onKeyDown={event => handleCustomer(event, customer.id)}
                                            type="text"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mt-3'>
                                    <Form.Label column sm="2">Адреса</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            name='address'
                                            disabled={order.status === 'Відправлено'}
                                            defaultValue={customer.address || ''}
                                            onKeyDown={event => handleCustomer(event, customer.id)}
                                            type="text"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mt-3'>
                                    <Form.Label column sm="2">Доставка</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            name='delivery'
                                            disabled={order.status === 'Відправлено'}
                                            defaultValue={customer.delivery || ''}
                                            onKeyDown={event => handleCustomer(event, customer.id)}
                                            type="text"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='mt-3'>
                                    <Form.Label column sm="2">Примітки</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            name='comment'
                                            disabled={order.status === 'Відправлено'}
                                            as="textarea"
                                            defaultValue={customer.comment || ''}
                                            onKeyDown={event => handleCustomer(event, customer.id)}
                                            rows={3}/>
                                    </Col>
                                </Form.Group>
                                <small className='text-secondary '>
                                    * Для запису зміни поля замовника потрібно підтвердити клавішею ENTER
                                </small>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3">
                                <Form.Group as={Row}>
                                    <Form.Label column sm="2">Номер</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            name='code'
                                            value={setOrderCode() || order.code}
                                            disabled
                                            type="text"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-3">
                                    <Form.Label column sm="2">Дата</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            name='date'
                                            disabled={order.status === 'Відправлено'}
                                            value={dateForm(order.date)}
                                            onChange={handleChange}
                                            type="date"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-3">
                                    <Form.Label column sm="2">Статус</Form.Label>
                                    <Col sm="10">
                                        <Form.Select
                                            name='status'
                                            disabled={order.status === 'Відправлено' && user.role !== 'ROLE_ADMIN'}
                                            value={order.status}
                                            onChange={handleChange}
                                            type="text">
                                            {Statuses()}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-3">
                                    <Form.Label column sm="2">Оплата</Form.Label>
                                    <Col sm="10">
                                        <Form.Select
                                            name='payment'
                                            disabled={order.status === 'Відправлено'}
                                            value={order.payment}
                                            onChange={handleChange}
                                            type="text"
                                        >
                                            <option>Форма оплати</option>
                                            <option value="Готівка">Готівка</option>
                                            <option value="Безготівка">Безготівка</option>
                                            <option value="Післяплата">Післяплата</option>
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-3">
                                    <Form.Label column sm="2">Доставка</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            name='delivery'
                                            disabled={order.status === 'Відправлено'}
                                            value={order.delivery}
                                            onChange={handleChange}
                                            type="text"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-3">
                                    <Form.Label column sm="2">Оплата</Form.Label>
                                    <Col sm="10">
                                        <InputGroup>
                                            <InputGroup.Text>ГРН</InputGroup.Text>
                                            <Form.Control
                                                name='uah'
                                                disabled={order.status === 'Відправлено'}
                                                value={order.uah}
                                                onChange={handleChange}
                                                type="number"/>
                                        </InputGroup>
                                        <InputGroup>
                                            <InputGroup.Text>EUR</InputGroup.Text>
                                            <Form.Control
                                                name='eur'
                                                disabled={order.status === 'Відправлено'}
                                                value={order.eur}
                                                onChange={handleChange}
                                                type="number"/>
                                        </InputGroup>
                                        <InputGroup>
                                            <InputGroup.Text>USD</InputGroup.Text>
                                            <Form.Control
                                                name='usd'
                                                disabled={order.status === 'Відправлено'}
                                                value={order.usd}
                                                onChange={handleChange}
                                                type="number"/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-3">
                                    <Form.Label column sm="2">Коментар</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            name='comment'
                                            disabled={order.status === 'Відправлено'}
                                            value={order.comment}
                                            onChange={handleChange}
                                            type="text"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-3">
                                    <Form.Label column sm="2">Оформив</Form.Label>
                                    <Col sm="10">
                                        <Form.Select
                                            name='user'
                                            disabled={order.status === 'Відправлено'}
                                            value={order.user}
                                            onChange={handleChange}
                                            type="text">
                                            <option>Оберіть працівника</option>
                                            {(users.length > 0) ? (users.map((us, id) => (
                                                        <option key={id} value={us.id}>
                                                            {us.name}
                                                        </option>
                                                    )
                                                ))
                                                : ''}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </Form.Group>
                        {Panel}
                        <Table bordered hover responsive className='mt-2'>
                            {TableHeader}
                            <tbody>
                                {ProductTable}
                                <tr className='text-center'>
                                    <td>{''}</td>
                                    <td>{CalculateQuantity()}</td>
                                    <td colSpan={5}>Сума</td>
                                    <td>{(CalculateSumm() / 100).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Form.Group as={Row} className="mb-3 text-center">
                            <Form.Group as={Col} className="mb-3">
                                <Button
                                    className='mx-auto'
                                    onClick={() => navigate(-1)}
                                    variant='outline-danger'>
                                    Назад
                                </Button>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3">
                                <Button
                                    disabled={isFetching}
                                    onClick={handleSubmit}
                                    variant="success">
                                    ЗБЕРЕГТИ
                                </Button>
                            </Form.Group>
                        </Form.Group>
                    </Container>
                </LoadingOverlay>
            </>
        )
    }
;

export default EditOrder;
