import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Button, Col, Modal, Table} from "react-bootstrap";
import PushMessage from "../PushMessage";
import {apiAddr} from "../../utils/api";
import BaseNavbar from "./BaseNavbar";

const UserList = () => {

    const [users, setUsers] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const cellStyled = (active) => {
        if (active !== true) return 'text-muted text-center'
        return 'text-black text-center'
    }
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    const handleDelete = (event, id) => {
        event.preventDefault()
        setId(id)
        handleShow()
    }
    const handleRestore = (event, id) => {
        event.preventDefault()
        setIsLoading(true);
        fetch(apiAddr + '/users/' + id, {
            method: 'PUT',
            body: JSON.stringify({
                active: true,
                roles: ["ROLE_USER"]
            }),
            headers: {'Content-Type': 'application/json',}
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false)
                    setModalShow(false)
                   setToast({
                        message: 'Працівника повернуто',
                        variant: 'light'
                    });
                    setShow(true)
                    setDeleted(true)
                }
            })
            .catch((error) => {
                console.log(error.message)
            });
    }
    const userDelete = (event, Id) => {
        event.preventDefault()
        setIsLoading(true);
            fetch(apiAddr + '/users/' + Id, {
                method: 'PUT',
                body: JSON.stringify({
                    active: false,
                    roles: []
                }),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 200) {
                        setIsLoading(false)
                        setModalShow(false)
                        setToast({
                            message: 'Працівника видалено',
                            variant: 'light'
                        });
                        setShow(true)
                        setDeleted(true)
                    }
                })
                .catch((error) => {
                    setToast({
                        message: error.message,
                        variant: 'warning'
                    });
                    setShow(true)
                });
        }
    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Працівника видалено',
        variant: 'light'
    });
    const [deleted, setDeleted] = useState(null)
    const [Id, setId] = useState(0)
    const DeleteModal = (id) => {
        return (
            <>
                <Modal show={modalShow} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Підтвердити видалення</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Видалення користувача {id}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Відміна
                        </Button>
                        <Button variant="danger" onClick={event => userDelete(event, Id)}>
                            ВИДАЛИТИ
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )

    }

    useEffect(() => {
            setIsLoading(true);
            axios.get(apiAddr + '/users')
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        setUsers(response.data['hydra:member'])
                        setDeleted(false)
                        setIsLoading(false);
                    }
                })
        }
        , [deleted]);


    const UserRow = (user, id) => {
        return (
            <tr key={id} className={cellStyled(user.active)}>
                <td>
                    <a className='text-dark fw-bold' href={'/base/user/' + user.id}>
                        {user.name}
                    </a>
                 </td>
                <td className='text-center'>{user.email}</td>
                <td>{user.roles[0]}</td>
                {user.active? <td
                    onClick={event => handleDelete(event, user.id)}
                    className='fw-bold text-danger'> X
                </td>
                    :
                    <td
                    onClick={event => handleRestore(event, user.id)}
                    className='fw-bold text-success'> O
            </td>}
            </tr>
        )
    };
    const UserTable = users.map((user, id) => UserRow(user, id))
    const UserHeader =
        <thead>
            <tr>
                <th>ІМʼЯ</th>
                <th>МЕЙЛ</th>
                <th>ПРАВА</th>
                <th>ВИДАЛИТИ</th>
            </tr>
        </thead>

    if (isLoading) {
        return (<Loader/>)
    }

    return (
        <>
            <BaseNavbar/>

            {PushMessage({toast}, [show, setShow])}

            {DeleteModal(Id)}

            <Container className='mt-3'>
                <Col>
                    <Button
                        className="mt-2 mb-2 text-success"
                        href='/base/user/new'
                        // onClick={(e) =>window.location ='/base/user/new'}
                        variant='outline-success'>
                        Новий
                    </Button>
                </Col>
                <Table bordered hover responsive>
                    {UserHeader}
                    {users.length > 0 && <tbody>
                        {UserTable}
                    </tbody>}
                </Table>
            </Container>
        </>
    )
};

export default UserList;
