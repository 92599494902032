import Contacts from "../components/Site/Contacts";
import ContactForm from "../components/Site/ContactForm";
import TopBar from "../components/Site/TopBar";
import Product from "../components/Site/Product";

const ProductPage = () => {
    return (
            <>
                <TopBar/>

                <Product/>

                <ContactForm/>

                <Contacts/>

            </>
        )
};

export default ProductPage;
