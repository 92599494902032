import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Button, Col, Form, Modal, Row, Table} from "react-bootstrap";
import dateNorm from "../../utils/dateForm";
import {apiAddr} from "../../utils/api";
import PushMessage from "../PushMessage";
import UpdateState from "./UpdateState";

const OrderList = () => {

    const [orders, setOrders] = useState([
        {
            id: 0,
            code: '',
            date: new Date(),
            status: '',
            payment: '',
            delivery: '',
            comment: '',
            customer: null,
            customer_name: null,
            created: {},
            updated: {},
            products: [],
            uah: 0,
            eur: 0,
            usd: 0,
            quantity: 0,
            summ: 0,
            user: null,
            user_name: null,
            is_deleted: false
        }
    ])
    const [filters, setFilters] = useState(
        {
            startDate: dateNorm(new Date),
            endDate: dateNorm(new Date),
            user_name: 'Всі',
            payment: 'Всі',
            customer: 'Всі'
        }
    )
    const [users, setUsers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const filteredOrders =
        orders.filter(order =>
            ((filters.payment !== 'Всі') ? (order.payment === filters.payment) : true)
            && ((filters.user_name !== 'Всі') ? (order.user_name === filters.user_name) : true)
            && ((filters.customer !== 'Всі') ? (order.customer_name === filters.customer) : true)
            && dateNorm(order.date) >= filters.startDate
            && dateNorm(order.date) <= filters.endDate
            && !order.is_deleted)

    const CalculateQuantity = (ords) => {
        let Quantity = 0
        ords.forEach(order => {
            Quantity = Quantity + (order.quantity ? order.quantity : 0)
        })
        return Quantity
    }
    const CalculateSumm = (ords) => {
        let Summ = 0
        ords.forEach(ord => {
            Summ = Summ + Number(ord.summ ? ord.summ : 0)
        })
        return Summ
    }
    const CalculateByCurrencies = (ords, curr) => {
        let Summ = 0
        ords.forEach(ord => {
            Summ = Summ + Number(ord[curr])
        })
        return Summ
    }
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    const handleDelete = (event, id) => {
        event.preventDefault()
        setId(id)
        handleShow()
    }
    const orderDelete = (event, Id) => {
        event.preventDefault()
        setIsFetching(true)
        setIsLoading(true);
        const order = orders.find(order => order.id === Id)
        if (order.products.length < 1) {
            fetch(apiAddr + '/orders/' + Id, {
                method: 'PUT',
                body: JSON.stringify({
                    isDeleted: true,
                    products: [],
                    quantity: 0,
                    summ: 0
                }),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 200) {
                        setIsLoading(false)
                        setIsFetching(false)
                        setModalShow(false)
                        setShow(true)
                        setDeleted(true)
                    }
                })
                .catch((error) => {
                    console.log(error.message)
                });
        } else {
            fetch(apiAddr + '/orders/' + Id, {
                method: 'PUT',
                body: JSON.stringify({
                    isDeleted: true,
                    products: [],
                    quantity: 0,
                    summ: 0
                }),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 200) {
                        UpdateState('add', order.products)
                        setDeleted(true)
                        setIsLoading(false)
                        setIsFetching(false)
                        setModalShow(false)
                        setShow(true)
                        console.log('order deleted')
                    }
                })
                .catch((error) => {
                    console.log(error.message)
                });
        }
    }
    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Замовлення видалено ;-(',
        variant: 'light'
    });
    const [deleted, setDeleted] = useState(null)
    const [Id, setId] = useState(0)
    const handleFilters = event => {
        const {name, value} = event.target;
        setFilters({...filters, [name]: value});
    };
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);

    const [isFetching, setIsFetching] = useState(false);

    const [user, setUser] = useState({
        user: '',
        role: ''
    });

    useEffect(() => {
        let currentUser = localStorage.getItem('user')
        let operate = localStorage.getItem('operate')
        setUser(
            {
                user: currentUser,
                role: operate
            })
    }, [localStorage]);

    useEffect(() => {
        setIsLoading(true)
        axios.get(apiAddr + '/orders')
            .then((response) => {
                    if (response.status === 200 && response.data) {
                        setOrders(response.data['hydra:member'])
                        setIsLoading(false)
                    }
                }
            )
    }, [deleted]);
    useEffect(() => {
        setIsLoading1(true)
        axios.get(apiAddr + '/users')
            .then((response) => {
                    if (response.status === 200 && response.data) {
                        setUsers(response.data['hydra:member'].filter(u => !u.is_deleted))
                        setIsLoading1(false)
                        setFilters({...filters, user_name: 'Всі'})
                    }
                }
            )
    }, []);
    useEffect(() => {
        setIsLoading2(true)
        axios.get(apiAddr + '/customers')
            .then((response) => {
                    if (response.status === 200 && response.data) {
                        setCustomers(response.data['hydra:member'])
                        setIsLoading2(false)
                    }
                }
            )
    }, []);

    const statusStyled = (order) => {
        if (order.status === "Новий") return 'text-primary text-center fw-bolder'
        if (order.status === "Оплачений") return ' text-success text-center fw-bold'
        if (order.status === "Відправлено") return 'text-secondary text-center'
        return 'text-muted bg-light'
    }

    const DeleteModal = (id) => {
        return (
            <>
                <Modal show={modalShow} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Підтвердити видалення</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Видалення накладної xxx{id}-xxxx-xx-xx</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Відміна
                        </Button>
                        <Button variant="danger" onClick={event => orderDelete(event, Id)}>
                            ВИДАЛИТИ
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )

    }
    const OrderRow = (order, id) => {
        return (
            <tr key={id}>
                <td>
                    <a className='text-dark fw-bold' href={'/base/order/' + order.id}>
                        <small>{order.code === '' ? ((36000 + order.id) + '-' + dateNorm(order.created)
                        ) : order.code}</small>
                    </a>
                </td>
                <td><small>{order.date
                    .toString()
                    .slice(0, 10)
                    .split('-').reverse().join('-')
                }</small></td>
                <td><b>{order.user_name ? order.user_name.slice(0, 1) : ''}</b></td>
                <td className='text-center'>{
                    order.uah ? order.uah + ' грн' : ''}
                    {order.usd ? ' $' + order.usd : ''}
                    {order.eur ? ' €' + order.eur : ''
                    }</td>
                <td className='text-center'><b>{order.payment.slice(0, 1)}</b></td>
                <td>
                    <a className='text-dark fw-bold' href={'/base/customer/' + order.customer}>
                        {order.customer_name}
                    </a>
                </td>
                <td className={statusStyled(order)}>
                    {order.status}
                </td>
                <td className='text-center'>{order.delivery}</td>
                <td>{order.comment}</td>
                <td className='text-center'>{order.quantity || ''}</td>
                <td className='text-center'>{(order.summ / 100).toFixed(2) || ''}</td>
                {(order.status === 'Новий') && <td
                    onClick={event => handleDelete(event, order.id)}
                    className='fw-bold text-danger'> X
                </td>}
                {(order.status === 'Оплачений' && user.role === 'ROLE_ADMIN') && <td
                    onClick={event => handleDelete(event, order.id)}
                    className='fw-bold text-danger'> X
                </td>}
                {(order.status === 'Відправлено' || order.status === 'Оплачений' && user.role === 'ROLE_USER') && <td
                    className='fw-bold text-danger'>
                </td>}

            </tr>
        )
    };
    const OrderTable = filteredOrders.map((order, id) => OrderRow(order, id))
    const TableHeader =
        <thead>
            <tr className='text-center'>
                <th>КОД</th>
                <th>ДАТА</th>
                <th colSpan={3}>ОПЛАТА</th>
                <th>ЗАМОВНИК</th>
                <th>СТАТУС</th>
                <th>ДОСТАВКА</th>
                <th>ПРИМ</th>
                <th>КГ</th>
                <th>СУМА</th>
                <th></th>
            </tr>
        </thead>

    const Filters =
        <Form.Group as={Row} className='my-3'>
            <Col sm="2">
                Дата з
                <Form.Control
                    name='startDate'
                    value={filters.startDate}
                    onChange={handleFilters}
                    type="date"/>
            </Col>
            <Col sm="2">
                Дата по
                <Form.Control
                    name='endDate'
                    value={filters.endDate}
                    onChange={handleFilters}
                    type="date"/>
            </Col>
            <Col sm="2">
                Форма оплати
                <Form.Select
                    name='payment'
                    value={filters.payment}
                    onChange={handleFilters}
                    type="text">
                    <option value='Всі'>Всі</option>
                    <option value="Готівка">Готівка</option>
                    <option value="Безготівка">Безготівка</option>
                    <option value="Післяплата">Післяплата</option>
                </Form.Select>
            </Col>
            <Col sm="2">
                Оформив
                <Form.Select
                    name='user_name'
                    value={filters.user_name}
                    onChange={handleFilters}
                    type="text">
                    <option value='Всі'>Всі</option>
                    {(users.length > 0) ? (users.map((us, id) => (
                                <option key={id} value={us.name}>
                                    {us.name}
                                </option>
                            )
                        ))
                        : ''}
                </Form.Select>
            </Col>
            <Col sm="4">
                Замовник
                <Form.Select
                    name='customer'
                    value={filters.customer}
                    onChange={handleFilters}
                    type="text">
                    <option value='Всі'>Всі</option>
                    {(customers.length > 0) ? (customers.map((us, id) => (
                                <option key={id} value={us.name}>
                                    {us.name}
                                </option>
                            )
                        ))
                        : ''}
                </Form.Select>
            </Col>
        </Form.Group>

    if (isLoading && isLoading1 && isLoading2) {
        return (
            <Loader/>
        )
    }

    return (
        <Container>
            {Filters}

            {PushMessage({toast}, [show, setShow])}

            {DeleteModal(Id)}

            <Table striped bordered hover responsive size='sm'>
                {TableHeader}
                <tbody>
                    {OrderTable}
                    <tr className='text-center'>
                        <td></td>
                        <td>Підсумок</td>
                        <td></td>
                        <td>Каса :</td>
                        <td colSpan={4}>
                            <b className='text-secondary'>{CalculateByCurrencies(filteredOrders, 'uah')} грн </b>
                            {' , '}
                            <b className='text-success'>${CalculateByCurrencies(filteredOrders, 'usd')} </b>
                            {' , '}
                            <b className='text-primary'>€{CalculateByCurrencies(filteredOrders, 'eur')} </b>
                        </td>
                        <td></td>
                        <td>{CalculateQuantity(filteredOrders)} кг</td>
                        <td>€{(CalculateSumm(filteredOrders) / 100).toFixed(2)}</td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    )
};

export default OrderList;
