import {Button, Form} from "react-bootstrap";
import {useState} from "react";
import PushMessage from "../PushMessage";
import {apiAddr} from "../../utils/api";

const ContactForm = () => {
    const [notification, setNotification] = useState({
        name: '',
        phone: '',
        date: new Date(),
        notrobot: false,
    })
    const [sent, setSent] = useState(false);
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Дякуємо! Ми зателефонуємо Вам найближчим часом',
        variant: 'success'
    });
    const handleChange = event => {
        const {name, value} = event.target;
        setNotification({...notification, [name]: value});
    };
    const handleCheck = event => {
        let val = !notification.notrobot
        setNotification({...notification, notrobot: val});
    };
    const handleSubmit = () => {
        if (notification.notrobot&&notification.name.length>2&&notification.phone.length>9) {
            const values =
                {
                    name: notification.name,
                    phone: notification.phone,
                    status: 'Новий',
                    date: notification.date
                }
            fetch(apiAddr+'/notifications', {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 201) {
                        setToast({message: 'Дякуємо! Ми зателефонуємо Вам найближчим часом', variant: 'light'})
                        setShow(true);
                        setSent(true)
                    }
                })
                .catch((error) => {
                    setToast({message: error, variant: 'warning'})
                    setShow(true);

                });
        } else {
            setToast({message: 'Заповніть коректно Ваші дані', variant: 'warning'})
            setShow(true);
        }
    }
    return (
        <>
            <div className=' my-4 py-3 px-2 px-sm-5 bg-warning text-secondary bg-gradient rounded'>
                <Form className='row text-center justify-content-sm-center'>
                    <Form.Group className="mb-3 col-4 col-sm-3" controlId="Phone">
                        <Form.Label><b className='text-white'>Номер</b></Form.Label>
                        <Form.Control
                            disabled={sent}
                            name='phone'
                            onChange={handleChange}
                            type="text"
                            placeholder="Телефон"/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-4" controlId="Name">
                        <Form.Label><b className='text-white'>Ваше імʼя</b></Form.Label>
                        <Form.Control
                            disabled={sent}
                            name='name'
                            onChange={handleChange}
                            type="text"
                            placeholder="Імʼя"/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-4 col-sm-2 text-center" controlId="formBasicCheckbox">
                        <Form.Label><nobr><b className='text-white'>Ви не робот?</b></nobr></Form.Label>
                        <Form.Check
                            onClick={handleCheck}
                            name='notrobot'
                            className='mt-2 text-center'
                            type="checkbox"
                            label='Я не робот!'/>
                    </Form.Group>
                    <Form.Text className="text-muted">
                        Ми ніколи і нікому не передаємо ваші особисті дані
                    </Form.Text>
                    <div className='mb-2 mt-3'>
                        <Button
                            disabled={sent}
                            variant="danger"
                            size='lg'
                            onClick={handleSubmit}
                           >
                            Зателефонуйте мені
                        </Button>
                    </div>
                    {PushMessage({toast}, [show,  setShow])}
                </Form>
            </div>
        </>
    )
};
export default ContactForm;
