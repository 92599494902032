import React from 'react';

const slugify = (text) => {

    text = text.toString().toLowerCase().trim();

    const sets = [
        {to: 'a', from: '[А]'},
        {to: 'b', from: '[Б]'},
        {to: 'v', from: '[В]'},
        {to: 'g', from: '[ҐГ]'},
        {to: 'd', from: '[Д]'},
        {to: 'e', from: '[Е]'},
        {to: 'zh', from: '[Ж]'},
        {to: 'z', from: '[З]'},
        {to: 'y', from: '[И]'},
        {to: 'i', from: '[І]'},
        {to: 'yi', from: '[Ї]'},
        {to: 'y', from: '[Й]'},
        {to: 'k', from: '[К]'},
        {to: 'l', from: '[Л]'},
        {to: 'm', from: '[М]'},
        {to: 'n', from: '[Н]'},
        {to: 'o', from: '[О]'},
        {to: 'p', from: '[П]'},
        {to: 'r', from: '[Р]'},
        {to: 's', from: '[С]'},
        {to: 't', from: '[Т]'},
        {to: 'u', from: '[У]'},
        {to: 'f', from: '[Ф]'},
        {to: 'h', from: '[Х]'},
        {to: 'ts', from: '[Ц]'},
        {to: 'ch', from: '[Ч]'},
        {to: 'sh', from: '[ШЩ]'},
        {to: '', from: '[Ь]'},
        {to: 'yu', from: '[Ю]'},
        {to: 'ya', from: '[Я]'},
    ];

    sets.forEach(set => {
        text = text.replace(new RegExp(set.from,'gi'), set.to)
    });

    return text
        .replace(/\s+/g, '-')    // Replace spaces with -
        // .replace(/[^-a-zа-я\u0370-\u03ff\u1f00-\u1fff]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-')    // Replace multiple - with single -
        .replace(/^-+/, '')      // Trim - from start of text
        .replace(/-+$/, '')      // Trim - from end of text
}
export default slugify;
