import {Col, Row, Table} from "react-bootstrap";


const FootNavbar = () => {
    return (
        <>
            <Table id='contacts' className='text-white-50 text-center bg-dark w-100 my-0'>
                <Row className=' justify-content-md-center bg-gradient'>
                    <Col sm={3} xs={6}>
                        <a href="/articles"><h6>Про секонд-хенд</h6></a>
                    </Col>
                    <Col sm={2} className='d-none d-sm-block'>
                        <a href="/#delivery"><h6>Доставка</h6></a>
                    </Col>
                    <Col sm={2} className='d-none d-sm-block'>
                        <a  href="/weare"><h6>Контакти</h6></a>
                    </Col>
                    <Col sm={2} className='d-none d-sm-block'>
                        <a  href="/#payment"><h6>Оплата</h6></a>
                    </Col>
                    <Col sm={2} xs={6}>
                        <a  href="/list"><h6>Прайс-лист</h6></a>
                    </Col>
                </Row>
            </Table>
        </>
    )

};

export default FootNavbar;