import BaseNavbar from "../components/Base/BaseNavbar";
import InvoiceList from "../components/Base/InvoiceList";

const Invoices = () => {
    return(
            <>
                <BaseNavbar/>

                <InvoiceList/>
            </>
        )
};

export default Invoices;
