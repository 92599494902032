import BaseNavbar from "../components/Base/BaseNavbar";

const Base = () => {
    return (
        <>
            <BaseNavbar/>

        </>
    )

};

export default Base;