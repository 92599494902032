import React, {useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import PushMessage from "../PushMessage";
import Container from "react-bootstrap/Container";
import {apiAddr} from "../../utils/api";
import jwtDecode from "jwt-decode";

const LoginForm = () => {

    const [user, setUser] = useState({
        email: '',
        password: '',
        roles: []
    });
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Успішна авторизація',
        variant: 'light'
    });
    const handleChange = event => {
        const {name, value} = event.target
        setUser({...user, [name]: value})
    };

    const handleLogin = (user, role, token) => {
        localStorage.removeItem('user')
        localStorage.removeItem('operate')
        localStorage.removeItem('token')
        localStorage.setItem('user', user);
        localStorage.setItem('operate', role);
        localStorage.setItem('token', token);
    }

    const handleSubmit = event => {
        event.preventDefault();
        const values = {
            username: user.email,
            password: user.password
        }
        fetch(apiAddr + '/login_check', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {'Content-Type': 'application/json',}
        })
            .then((response) => response.json()
                .then((data) => {
                    if (data.token) {
                            const token = data.token
                            const user = jwtDecode(token)
                            handleLogin(user.username, user.roles[0], token)
                            setToast({
                                message: 'Успішна авторизація',
                                variant: 'light'
                            })
                            setShow(true)
                            window.location.href = '/base/orders'
                        } else {
                            setToast({
                                message: response.statusText,
                                variant: 'danger'
                            })
                            setShow(true)
                        }
                    }
                )
            )
            .catch((error) => {
                    console.log(error.message);
                    setToast({
                        message: error.message,
                        variant: 'danger'
                    })
                    console.log(error.message);
                    setShow(true)
                }
            )
    };
    return (
        <Container>
            <Col className="m-auto">
                {PushMessage({toast}, [show, setShow])}
                <Form
                    onSubmit={handleSubmit}
                    id="login"
                    method="post"
                >
                    <Form.Group as={Row} controlId="email">
                        <Form.Label column sm="3">
                            Email
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="email"
                                type="email"
                                placeholder="name@example.com"
                                value={user.email}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="password">
                        <Form.Label column sm="3">
                            Пароль
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="password"
                                type="password"
                                placeholder="Пароль"
                                value={user.password}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>
                    <Button variant="success" type="submit">
                        ВХІД
                    </Button>
                </Form>
            </Col>
        </Container>
    );
};

export default LoginForm;
