import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Button, Col, Form, InputGroup, Row, Table} from "react-bootstrap";
import PushMessage from "../PushMessage";
import dateForm from "../../utils/dateForm";
import {useNavigate, useParams} from "react-router-dom";
import dateNorm from "../../utils/dateForm";
import BaseNavbar from "./BaseNavbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {apiAddr} from "../../utils/api";
import {InvoiceStatuses} from "../../utils/library";
import NewUpdateState from "./NewUpdateState";
import LoadingOverlay from "react-loading-overlay-ts";

const EditOrder = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [changedProducts, setChangedProducts] = useState([]);
    const [order, setOrder] = useState({
        id: null,
        name: null,
        date: new Date(),
        status: 'Новий',
        seller: '',
        created: {},
        products: [],
        quantity: 0,
        summ: 0,
    })
    const [products, setProducts] = useState([
        {
            id: '',
            code: '',
            name: '',
            category: '',
            cost_netto: '',
            cost_brutto: '',
            price_ko: '',
            price_mo: '',
            comment: '',
            tara: 0,
            unit: '',
            quantity: 0,
            ordered: false,
            ordered_quantity: 0,
            summ: 0
        }
    ]);
    const [hideUnordered, setHideUnordered] = useState(true)
    const toggleHideUnordered = () => {
        setHideUnordered(!hideUnordered)
    }
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Дані замовлення оновлено',
        variant: 'light'
    });
    const [overlayActive, setOverlayActive] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading1, setIsLoading1] = useState(true);

    useEffect(() => {
            setIsLoading1(true)
            axios.get(apiAddr + '/invoices/' + id)
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        setOrder(response.data)
                        setIsLoading1(false);
                    }
                })
        }
        , []);

    useEffect(() => {
            if (!loaded) {
                setIsLoading(true)
                axios.get(apiAddr + '/products')
                    .then((response) => {
                        if (response.status === 200 && response.data) {
                            setProducts(upProducts(response.data['hydra:member']))
                            setLoaded(true)
                            setIsLoading(false);
                        }
                    })
            } else {
                setProducts(upProducts(products))
            }
        }
        , [order]);

    const upProducts = (prods) => {
        prods.forEach(function (element) {
            element.ordered = false;
            element.ordered_quantity = 0;
            element.summ = 0;
            order.products.forEach(pr => {
                    if (Number(element.id) === Number(pr.id)) {
                        element.ordered = true
                        element.ordered_quantity = pr.ordered_quantity;
                        element.summ = pr.summ;
                    }
                }
            )
        })
        return (prods)
    }

    const CalculateQuantity = () => {
        let Quantity = 0
        products.forEach(product => {
            Quantity = Quantity + product.ordered_quantity
        })
        return Quantity
    }
    const CalculateSumm = () => {
        let Summ = 0
        products.forEach(product => {
            Summ = Summ + Number(product.summ)
        })
        return Math.round(Summ*100)/100
    }

    const handleChange = event => {
        const {name, value} = event.target;
        setOrder({...order, [name]: value});
    };

    const handleProductChange = (event, type) => {
        const {name, value} = event.target;
        const pid = Number(name)
        const Arr = [...products]
        const aid = Arr.findIndex(el => Number(el.id) === pid) //find id in products of handled product
        const Prr = [...order.products]
        const bid = Prr.findIndex(el => Number(el.id) === pid) // find id in order.product of handled product
        let PV = Arr[aid].ordered_quantity

        if (type === 'incr') {
            Arr[aid].ordered_quantity = Arr[aid].ordered_quantity + Arr[aid].tara;
            Arr[aid].quantity = Arr[aid].quantity + Arr[aid].tara;
            changedProducts.push({id: pid, change: Arr[aid].ordered_quantity - PV})
        } else if (type === 'decr') {
            Arr[aid].ordered_quantity = Arr[aid].ordered_quantity - Arr[aid].tara;
            Arr[aid].quantity = Arr[aid].quantity - Arr[aid].tara;
            changedProducts.push({id: pid, change: Arr[aid].ordered_quantity - PV})
        } else {
            Arr[aid].ordered_quantity = Number(value);
            Arr[aid].quantity = Arr[aid].quantity - PV + Number(value);
            changedProducts.push({id: pid, change: Arr[aid].ordered_quantity - PV})
        }
        if (
            Arr[aid].ordered === false) { // for prev unordered product set default values and push to order.products
            Arr[aid].ordered = true;
            Prr.push({
                id: pid,
                code: Arr[aid].code,
                name: Arr[aid].name,
                ordered_quantity: Arr[aid].ordered_quantity,
                summ: Arr[aid].ordered_quantity * Arr[aid].cost_netto
            })
            setOrder({...order, products: Prr})
            Arr[aid].summ = Arr[aid].ordered_quantity * Arr[aid].cost_netto;
            setProducts(Arr);
            console.log('pushed new pr to ord prod')
            console.log(Prr)
        } else {
            Prr[bid].ordered_quantity = Arr[aid].ordered_quantity
            Arr[aid].summ = Arr[aid].ordered_quantity * Arr[aid].cost_netto;
            Prr[bid].summ = Arr[aid].summ
            setProducts(Arr);
            setOrder({...order, products: Prr})
        }
    }
    const setOrderName = () => {
        if (order.code === '') {
            return (1100 + order.id) + '-' + dateNorm(order.created)
        } else {
            return order.code
        }
    }
    const handleSubmit = () => {
        setIsFetching(true)
        setOverlayActive(true)
        const ordprod = products.filter(product => product.ordered === true);
        const p = []
        // CHECK CHANGES IN ORDERED PRODUCTS
        const ArrC = []
        changedProducts.forEach(chp => {
            const aidx = ArrC.findIndex(a => a.id === chp.id)
            if (aidx !== -1) {
                ArrC[aidx] = {id: chp.id, change: ArrC[aidx].change + chp.change}
            } else {
                ArrC.push(chp)
            }
        })
        const unchanged = ArrC.filter(a => a.change !== 0).length === 0
        console.log(unchanged)
        if (unchanged) {
            ordprod.forEach(o => {
                p.push({
                    id: o.id,
                    code: o.code,
                    name: o.name,
                    cost_netto: o.cost_netto,
                    ordered_quantity: o.ordered_quantity,
                    summ: o.summ
                })
            })
            const values =
                {
                    name: setOrderName(),
                    comment: order.comment,
                    seller: order.seller,
                    status: order.status,
                    date: order.date,
                    isDeleted: false,
                    products: p,
                    quantity: CalculateQuantity(),
                    summ: Math.round(CalculateSumm())
                }

            fetch(apiAddr + '/invoices/' + order.id, {
                method: 'PUT',
                body: JSON.stringify(values),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 200) {
                        setToast({
                            message: 'Дані накладної оновлено.',
                            variant: 'light'
                        });
                        setShow(true)
                        setIsFetching(false)
                        setOverlayActive(false)
                    }
                })
                .catch((error) => {
                    setToast({
                        message: error.message,
                        variant: 'danger'
                    });
                    setShow(true)
                })
        } else {
            console.log(ArrC.filter(a => a.change !== 0))
            const toUpdate = ArrC.filter(a => a.change !== 0)
            ordprod.forEach(o => {
                p.push({
                    id: o.id,
                    code: o.code,
                    name: o.name,
                    cost_netto: o.cost_netto,
                    ordered_quantity: o.ordered_quantity,
                    summ: o.summ
                })
            })
            const values =
                {
                    name: setOrderName(),
                    comment: order.comment,
                    seller: order.seller,
                    status: order.status,
                    date: order.date,
                    isDeleted: false,
                    products: p,
                    quantity: CalculateQuantity(),
                    summ: Math.round(CalculateSumm())
                }

            fetch(apiAddr + '/invoices/' + order.id, {
                method: 'PUT',
                body: JSON.stringify(values),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    // PRODUCTS STATE UPD
                    NewUpdateState('add', toUpdate)
                    setToast({
                        message: 'Дані замовлення оновлено.',
                        variant: 'light'
                    });
                    setTimeout(() => {
                        setShow(true)
                        setIsFetching(false)
                        setOverlayActive(false)
                    }, 1000)
                })
                .catch((error) => {
                    setToast({
                        message: error.message,
                        variant: 'danger'
                    });
                    setShow(true)
                })
        }
    }

    const Order = (product) => {
        return (
            <InputGroup className="">
                <Button
                    name={product.id}
                    variant="outline-secondary"
                    value={product.ordered_quantity}
                    onClick={event => {
                        handleProductChange(event, 'incr')
                    }}>
                    +
                </Button>
                <Form.Control
                    name={product.id}
                    type="number"
                    value={product.ordered_quantity || ''}
                    onChange={event => {
                        handleProductChange(event, 'edit')
                    }}/>
                <Button
                    name={product.id}
                    variant="outline-secondary"
                    value={product.ordered_quantity}
                    onClick={event => {
                        handleProductChange(event, 'decr')
                    }}>
                    -
                </Button>
            </InputGroup>
        )
    }

    const ProductRow = (product, id) => {
        return (
            <tr key={id} className={(hideUnordered) && (product.ordered !== true) ? 'd-none' : ''}>
                <td><a className='text-dark fw-bold' href={'/base/product/' + product.id}>{product.code}</a></td>
                <td width={160} className='text-center'>
                    {Order(product)}
                </td>
                <td>{product.name}</td>
                <td className='text-center'>{product.category}</td>
                <td className='text-center'>
                    {(product.cost_netto / 100).toFixed(2) || '0.00'}
                </td>
                <td className='text-center'>{product.quantity}</td>
                <td className='text-center'>{(product.summ / 100).toFixed(2) || ''}</td>
            </tr>
        )
    };
    const ProductTable = products.map((product, id) => ProductRow(product, id))
    const TableHeader =
        <thead>
            <tr>
                <th>КОД</th>
                <th>ЗАМОВЛЕНО</th>
                <th>НАЗВА</th>
                <th>КАТЕГОРІЯ</th>
                <th>ВАРТІСТЬ</th>
                <th>СКЛАД</th>
                <th>СУМА</th>
            </tr>
        </thead>
    const InvoiceData =
        <>
            <Form.Group as={Row} className="mb-3">
                <Form.Group as={Col} className="mb-3">
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">Постачальник</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name='seller'
                                value={order.seller || ''}
                                onChange={handleChange}
                                type="text"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm="3">Статус</Form.Label>
                        <Col sm="9">
                            <Form.Select
                                name='status'
                                value={order.status}
                                onChange={handleChange}
                                type="text">
                                {InvoiceStatuses.map(s => <option key={s.id} value={s.name}>{s.name}</option>)}
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">Дата</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name='date'
                                value={dateForm(order.date)}
                                onChange={handleChange}
                                type="date"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm="3">Коментар</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name='comment'
                                value={order.comment}
                                onChange={handleChange}
                                type="text"/>
                        </Col>
                    </Form.Group>
                </Form.Group>
            </Form.Group>
        </>

    const Panel =
        <Form.Group as={Row} className='w-100 bordered rounded bg-secondary bg-gradient mx-0 mb-2 text-white pt-3'>
            <Form.Group as={Col} sm={3} className="mt-2 text-right">
                <Form.Check
                    type="checkbox"
                    label="Відкрити інші товари"
                    onClick={(event) => toggleHideUnordered()}
                />
            </Form.Group>
        </Form.Group>

    if (isLoading && isLoading1) {
        return (<Loader/>)
    }

    return (
        <>
            <LoadingOverlay
                active={overlayActive}
                spinner
                text='Зачекайте на запис даних до бази даних ...'
            >
                <BaseNavbar/>
                <Container className='mt-3'>
                    <a href={'print/' + order.id}>
                        <FontAwesomeIcon size='2x' color='grey' icon={faPrint}/>
                    </a>
                    {PushMessage({toast}, [show, setShow])}
                    {InvoiceData}
                    {Panel}
                    <Table bordered hover responsive className='mt-2'>
                        {TableHeader}
                        <tbody>
                            {ProductTable}
                            <tr className='text-center'>
                                <td>{''}</td>
                                <td>{CalculateQuantity()}</td>
                                <td colSpan={4}>Сума</td>
                                <td>{(CalculateSumm() / 100).toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Form.Group as={Row} className="mb-3 text-center">
                        <Form.Group as={Col} className="mb-3">
                            <Button
                                className='mx-auto'
                                onClick={() => navigate(-1)}
                                variant='outline-danger'>
                                Назад
                            </Button>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                            <Button
                                disabled={isFetching}
                                onClick={handleSubmit}
                                variant="success">
                                ЗБЕРЕГТИ
                            </Button>
                        </Form.Group>
                    </Form.Group>
                </Container>
            </LoadingOverlay>
        </>
    )
};

export default EditOrder;
