import BaseNavbar from "../components/Base/BaseNavbar";
import CustomerList from "../components/Base/CustomerList";

const Customers = () => {
    return (
        <>
            <BaseNavbar/>

            <CustomerList/>
        </>
    )
};

export default Customers;
