import React from 'react';
import {useParams} from "react-router-dom";
import Articles from "./ArticlesText";
import TopBar from "./TopBar";
import Contacts from "./Contacts";
import Container from "react-bootstrap/Container";
import DocumentMeta from "react-document-meta";

const ArticlePage = () => {
    const {slug} = useParams();
    const breadcrumbs = (article) => {
        return (
            <div itemScope itemType="http://schema.org/BreadcrumbList" className='bg-danger bg-gradient rounded px-1'>
                <h3 className='text-light'>
                    <a
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                        href='/'
                        className='text-light'>
                        Головна
                        <meta itemProp="item" content="https://secondhand.uz.ua"/>
                        <meta itemProp="position" content="1"/>
                        <meta itemProp="name" content="Головна"/>
                    </a>
                    {' > '}
                    <a
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                        href='/articles'
                        className='text-light'>
                        Статті
                        <meta itemProp="item" content="https://secondhand.uz.ua/articles"/>
                        <meta itemProp="position" content="2"/>
                        <meta itemProp="name" content="Статті"/>
                    </a>
                    {' > '}
                    {article.title}
                </h3>
            </div>
    )}
    const ArticleRow = (text) => (
        <div className='mt-3'>
            <h3>
                {text.subtitle}
            </h3>

            <div>
                {text.text.split(/\n/g).map(text=> <p key={text.id}>{text}</p>)}
            </div>
        </div>
    )

        const ArticleColumn = (content) => (
            content.map(content => ArticleRow(content))
    )

    const id = Articles.findIndex(article => article.slug===slug)
    const article = Articles[id]
    const meta = {
        title: article.title+'. Про секонд-хенд. Cеконд хенд оптом Євромікс',
        description: article.title+' ➽ Безкоштовна доставка по Україні ✆ 099-464-00-04 ✆ 067-464-04-04',
        meta: {
            name: {
                keywords: "про секонд-хенд, секонд-хенд Україна, Секонд-хенд статті, одяг секонд-хенд, гурт секонд-хенд, секонд-хенд, second-hand, гуртівня секонд-хенд",
            }
        }

    }
    return (
        <>
            <DocumentMeta {...meta}/>

            <TopBar/>

            {breadcrumbs(article)}

            <Container className='text-secondary'>
                <h1>
                    {article.title}
                </h1>
                {ArticleColumn(article.content)}
                © secondhand.uz.ua | використання матеріалів дозволяється виключно з посиланням на джерело
            </Container>

            <Contacts/>
        </>

    );
};

export default ArticlePage;
