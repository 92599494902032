import {BrowserRouter, Routes, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Site from "./pages/Site";
import Base from "./pages/Base";
import NoPage from "./pages/NoPage";
import Products from "./pages/Products";
import List from "./pages/List";
import Customers from "./pages/Customers";
import NewRate from "./pages/NewRate";
import Orders from "./pages/Orders";
import NewProduct from "./components/Base/NewProduct";
import NewCustomer from "./components/Base/NewCustomer";
import Notifications from "./pages/Notifications";
import EditProduct from "./components/Base/EditProduct";
import EditOrder from "./components/Base/EditOrder";
import PrintOrder from "./components/Base/PrintOrder";
import EditCustomer from "./components/Base/EditCustomer";
import Invoices from "./pages/Invoices";
import NewInvoice from "./components/Base/NewInvoice";
import Login from "./pages/Login";
import {useEffect, useState} from "react";
import SmsSend from "./components/Base/SmsSend";
import EditInvoice from "./components/Base/EditInvoice";
import ProductPage from "./pages/ProductPage";
import ProductListAdmin from "./components/Base/ProductListAdmin";
import UserList from "./components/Base/UserList";
import Analytics from "./components/Base/Analytics";
import NewUser from "./components/Base/NewUser";
import EditUser from "./components/Base/EditUser";
import Articles from "./pages/Articles";
import ArticlePage from "./components/Site/ArticlePage";
import MassSmsSend from "./components/Base/MassSmsSend";
import WeAre from "./pages/WeAre";
import Check from "./components/Base/Check";

export default function App() {

    const [user, setUser] = useState({
        user: '',
        role: ''
    });

    useEffect(() => {
        let currentUser = localStorage.getItem('user')
        let operate = localStorage.getItem('operate')
        setUser(
            {
                user: currentUser,
                role: operate
            })
    }, [localStorage]);

    if (user.role === 'ROLE_USER') {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Site/>}/>
                    <Route path="/list" element={<List/>}/>
                    <Route path="/weare" element={<WeAre/>}/>
                    <Route path="/base/login" element={<Login/>}/>
                    <Route exact path="/product/:id/*" element={<ProductPage/>}/>
                    <Route path="/articles" element={<Articles/>}/>
                    <Route path="/articles/:slug" element={<ArticlePage/>}/>
                    <Route path="/admin/*" element={<Login/>}/>

                    <Route path="/base" element={<Base/>}/>
                    <Route path="/base/products" element={<Products/>}/>
                    <Route path="/base/orders" element={<Orders/>}/>
                    <Route exact path="/base/order/:id" element={<EditOrder/>}/>
                    <Route exact path="/base/order/print/:id" element={<PrintOrder/>}/>
                    <Route path="/base/customers" element={<Customers/>}/>
                    <Route path="/base/customer/new" element={<NewCustomer/>}/>
                    <Route exact path="/base/customer/:id" element={<EditCustomer/>}/>
                    <Route path="/base/notifications" element={<Notifications/>}/>
                    <Route path="/base/sms/:phone" element={<SmsSend/>}/>
                    <Route path="/404" element={<NoPage/>}/>
                    <Route path="*" element={<NoPage/>}/>
                </Routes>
            </BrowserRouter>
        )
    }
    if (user.role === 'ROLE_ADMIN') {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Site/>}/>
                    <Route path="/list" element={<List/>}/>
                    <Route path="/weare" element={<WeAre/>}/>
                    <Route path="/base/login" element={<Login/>}/>
                    <Route exact path="/product/:id/*" element={<ProductPage/>}/>
                    <Route path="/articles" element={<Articles/>}/>
                    <Route path="/articles/:slug" element={<ArticlePage/>}/>

                    <Route path="/base" element={<Base/>}/>
                    <Route path="/base/products" element={<Products/>}/>
                    <Route path="/base/orders" element={<Orders/>}/>
                    <Route exact path="/base/order/:id" element={<EditOrder/>}/>
                    <Route exact path="/base/order/print/:id" element={<PrintOrder/>}/>
                    <Route path="/base/customers" element={<Customers/>}/>
                    <Route path="/base/customer/new" element={<NewCustomer/>}/>
                    <Route exact path="/base/customer/:id" element={<EditCustomer/>}/>
                    <Route path="/base/notifications" element={<Notifications/>}/>
                    <Route path="/base/sms/:phone" element={<SmsSend/>}/>

                    <Route exact path="/base/product/:id" element={<EditProduct/>}/>
                    <Route exact path="/base/products/admin" element={<ProductListAdmin/>}/>
                    <Route path="/base/invoices" element={<Invoices/>}/>
                    <Route exact path="/base/invoice/new" element={<NewInvoice/>}/>
                    <Route exact path="/base/invoice/:id" element={<EditInvoice/>}/>
                    <Route path="/base/rate/new" element={<NewRate/>}/>
                    <Route path="/base/product/new" element={<NewProduct/>}/>
                    <Route path="/base/users" element={<UserList/>}/>
                    <Route path="/base/analytics" element={<Analytics/>}/>
                    <Route path="/base/check" element={<Check/>}/>
                    <Route path="/base/user/new" element={<NewUser/>}/>
                    <Route exact path="/base/user/:id" element={<EditUser/>}/>
                    <Route path="/base/masssms" element={<MassSmsSend/>}/>
                </Routes>
            </BrowserRouter>
        )
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Site/>}/>
                    <Route path="/list" element={<List/>}/>
                    <Route path="/weare" element={<WeAre/>}/>
                    <Route path="/base/login" element={<Login/>}/>
                    <Route exact path="/product/:id/*" element={<ProductPage/>}/>
                    <Route path="/articles" element={<Articles/>}/>
                    <Route path="/articles/:slug" element={<ArticlePage/>}/>
                    <Route path="/base/*" element={<Login/>}/>
                    <Route path="/admin/*" element={<Login/>}/>
                    <Route path="/404" element={<NoPage/>}/>
                    <Route path="*" element={<NoPage/>}/>
                </Routes>
            </BrowserRouter>
        )
    }
}


