import Carousel from "react-bootstrap/Carousel";

const CarouselHome = () => {
    return (
        <>
            <Carousel fade indicators={false} controls={false} >
                <Carousel.Item>
                    <img
                        className=" d-block w-100 text"
                        src="/images/secondhand_europe.jpg"
                        height="50%"
                        alt="Second slide"
                    />

                    <Carousel.Caption>
                        <h2 className='d-sm-block d-none'>Секонд-хенд оптом з Європи</h2>
                        <h4 className='mb-0 d-sm-block d-none'>Якісний секонд-хенд з найбільших на перевірених часом складів та фабрик у Європі</h4>
                    </Carousel.Caption >
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/images/secondhand_delivery.jpg"
                        height="50%"
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <br/>
                        <h2 className='d-sm-block d-none'>Регулярні поставки з Європи</h2>
                        <h4 className='mb-0 d-sm-block d-none'>
                            Ми швидко та регулярно доставляємо секонд-хенд з фабрик та складів у Європі
                        </h4>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/images/secondhand_ukraine_delivery.jpg"
                        height="50%"
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h2 className='d-sm-block d-none'>Швидка та безкоштовна доставка по Україні</h2>
                        <h4 className='mb-0 d-sm-block d-none'> Доставка секонд-хенду по Україні безкоштовна. Щоденні відправки у всі населенні пункти</h4>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    )
};
export default CarouselHome;
