import ProductList from "../components/Base/ProductList";
import BaseNavbar from "../components/Base/BaseNavbar";

const Products = () => {
    return(
            <>
                <BaseNavbar/>

                <ProductList/>
            </>
        )
};

export default Products;
