import React, {useState} from 'react';
import BaseNavbar from "./BaseNavbar";
import {Button, Col, Form, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import PushMessage from "../PushMessage";
import {useNavigate} from "react-router-dom";
import {apiAddr} from "../../utils/api";

const NewUser = () => {
    const [user, setUser] = useState(
        {
            name: '',
            email: '',
            role: ["ROLE_USER"],
            password: '',
            active: true,
            is_deleted: false
        }
    );
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Нового працівника додано',
        variant: 'light'
    });
    const handleChange = event => {
        const {name, value} = event.target;
        setUser({...user, [name]: value});
    };
    const handleSubmit = () => {
        const values =
            {
                name: user.name,
                email: user.email,
                roles: [],
                plainPassword: user.password,
                is_deleted: false,
                active: true,
            }
        console.log(values)
        fetch(apiAddr + '/register', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {'Content-Type': 'application/json',}
        })
            .then((response) => {
                if (response.status === 201) {
                    setToast({
                        message: 'Нового працівника додано',
                        variant: 'light'
                    })
                    setShow(true);
                }
            })
            .catch((error) => {
                setShow(true);
                setToast({message: error.message, variant: 'warning'})
            });
    }

    return (
        <>
            <BaseNavbar/>
            <Container>
                {PushMessage({toast}, [show, setShow])}
                <Form.Group as={Row} className='mt-2'>
                    <Form.Group as={Col} className='mt-3'>
                        <Form.Group as={Row} className='mt-4 text-danger'>
                            <Form.Label column sm="2">Імʼя</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='name'
                                    value={user.name || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4  text-danger'>
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='email'
                                    value={user.email || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group as={Col} className='mt-3'>
                        <Form.Group as={Row} className='mt-4 text-danger'>
                            <Form.Label column sm="2">Пароль</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='password'
                                    value={user.password || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4 text-danger'>
                            <Form.Label column sm="2">Права</Form.Label>
                            <Col sm="10">
                                <Form.Select
                                    name='role'
                                    value={user.role || ''}
                                    onChange={handleChange}
                                    type="text"
                                >
                                    <option value="ROLE_USER">
                                        Менеджер
                                    </option>
                                    <option value="ROLE_ADMIN">
                                        Адмін
                                    </option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Container className='text-center'>
                            <Form.Group as={Row} className='mt-4 text-center'>
                                <Col>
                                    <Button
                                        className='mx-auto'
                                        onClick={() => navigate(-1)}
                                        variant='outline-danger'>
                                        Відмінити
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className='mx-auto'
                                        onClick={handleSubmit}
                                        variant="outline-success">
                                        ВПЕРЕД
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Container>

                    </Form.Group>
                </Form.Group>
            </Container>

        </>
    );
};

export default NewUser;
