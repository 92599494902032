import React from "react";
import {Col} from "react-bootstrap";
import LoginForm from "../components/Base/LoginForm";

const Login = () => {
    return (
        <Col className="col-md-6 m-auto p-md-5 p-sm-1">
            <LoginForm/>
        </Col>
    );
};
export default Login