import React, {useEffect, useState} from 'react';
import BaseNavbar from "./BaseNavbar";
import {Button, Col, Form, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import PushMessage from "../PushMessage";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {apiAddr} from "../../utils/api";
import {Loader} from "../Loader";

const EditUser = () => {
    const {id} = useParams();

    const [user, setUser] = useState(
        {
            name: '',
            email: '',
            role: [],
            password: '',
            is_deleted: false,
            active: true
        }
    );
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Дані працівника оновлено',
        variant: 'light'
    });
    const [isLoading, setIsLoading] = useState(true);
    const handleChange = event => {
        const {name, value} = event.target;
        setUser({...user, [name]: value});
        console.log(name + ' : ' + value)
    };
    useEffect(() => {
            setIsLoading(true)
            axios.get(apiAddr + '/users/' + id)
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        setUser({
                            ...response.data,
                            role: response.data.roles[0],
                            // password: '******'
                        })
                        setIsLoading(false);
                    }
                })
        }
        , []);
    const handleSubmit = () => {
        const values = {
            name: user.name,
            plainPassword: user.password,
            roles: (user.role === '') ? [] : [user.role]}

        fetch(apiAddr+'/users/' + user.id, {
            method: 'PUT',
            body: JSON.stringify(values),
            headers: {'Content-Type': 'application/json',}
        })
            .then((response) => {
                if (response.status === 200) {
                    setToast({
                        message: 'Дані працівника оновлено',
                        variant: 'light'
                    });
                    setShow(true);
                }
            })
            .catch((error) => {
                setShow(true);
                setToast({message: error, variant: 'danger'})
                console.log(error)
            });
    }

    if (isLoading) {
        return (<Loader/>)
    }

    return (
        <>
            <BaseNavbar/>
            <Container>
                {PushMessage({toast}, [show, setShow])}
                <Form.Group as={Row} className='mt-2'>
                    <Form.Group as={Col} className='mt-3'>
                        <Form.Group as={Row} className='mt-4 text-danger'>
                            <Form.Label column sm="2">Імʼя</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='name'
                                    value={user.name || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4  text-danger'>
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='email'
                                    value={user.email || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group as={Col} className='mt-3'>
                        <Form.Group as={Row} className='mt-4 text-danger'>
                            <Form.Label column sm="2">Пароль</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='password'
                                    value={user.password}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4 text-danger'>
                            <Form.Label column sm="2">Права</Form.Label>
                            <Col sm="10">
                                <Form.Select
                                    name='role'
                                    value={user.role || ''}
                                    onChange={handleChange}
                                    type="text"
                                >
                                    <option value=''>
                                        Без доступу
                                    </option>
                                    <option value='ROLE_USER'>
                                        Менеджер
                                    </option>
                                    <option value='ROLE_ADMIN'>
                                        Адмін
                                    </option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Container className='text-center'>
                            <Form.Group as={Row} className='mt-4 text-center'>
                                <Col>
                                    <Button
                                        className='mx-auto'
                                        onClick={() => navigate(-1)}
                                        variant='outline-secondary'>
                                        Назад
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className='mx-auto'
                                        onClick={handleSubmit}
                                        variant="outline-success">
                                        ВПЕРЕД
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Container>

                    </Form.Group>
                </Form.Group>
            </Container>

        </>
    );
};

export default EditUser;
