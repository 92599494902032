import Contacts from "../components/Site/Contacts";
import TopBar from "../components/Site/TopBar";
import PriceList from "../components/Site/PriceList";
import DocumentMeta from 'react-document-meta';
import React from "react";

const List = () => {
    const meta = {
        title: 'Оптовий прайс-лист ЄвроМікс - оптові ціни на секонд-хенд із Європи. ',
        description: 'Гуртові ціни на секонд-хенд з Європи в прайс-листі секонд-хенд ➽ Безкоштовна доставка по Україні ✆ 099-464-00-04 ✆ 067-464-04-04',
        meta: {
            name: {
                keywords: "прайс секонд-хенд, прайс-лист секонд-хенд, ціни секонд-хенд, ціна секонд-хенд"
            }
        }
    }
    const breadcrumbs = () => {
        return (
            <div itemScope itemType="http://schema.org/BreadcrumbList"  className='bg-danger bg-gradient rounded px-2'>
                <h3 className='text-light'>
                    <a
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                        href='/'
                        className='text-light'>
                        Головна
                        <meta itemProp="item" content="https://secondhand.uz.ua"/>
                        <meta itemProp="position" content="1"/>
                        <meta itemProp="name" content="Головна"/>
                    </a>
                    {' > '}
                    Прайс-лист
                </h3>
            </div>
        )
    }
    return(
        <>
            <DocumentMeta {...meta}/>

            <TopBar/>

            {breadcrumbs()}


            <h1 className='text-center'>Прайс-лист секонд-хенд Євромікс - гуртові ціни {new Date().getFullYear()} рік</h1>

            <PriceList/>

            <h5 className='text-center'>
                Ми продаємо секонд-хенд тільки гуртом. Мінімальна гуртова партія замовлення для відправки - 30 кг.
            </h5>
            <p  className='text-center'>
                Всі ціни зазначені в Євро за 1 кг, якщо не вказано інше. Оплата здійснюється в гривнях на рахунок продавця за курсом на день оплати і згідно виставленого рахунку.
                <br/>
                Відправка товару здійснюється за передоплатою або післяплатою. При оплаті післяплатою оплата проводиться кур'єру на місці або в відділенні кур'єрської служби при отриманні товару.
            </p>

            <Contacts/>
        </>
    )
};

export default List;
