import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Image, Table} from "react-bootstrap";
import {pubApiAddr} from "../../utils/api";
import slugify from "../../utils/slugify";

const PriceList = () => {

    const [products, setProducts] = useState([
        {
            id: '',
            code: '',
            name: '',
            tara: 0,
            unit: '',
            price_ko: 0,
            comment: '',
        }
    ]);
    const centsToEur = (eur) => {
        return eur / 100
    }
    const [isLoading, setIsLoading] = useState(true);

    const ProductRow = (product, index) => {
        return (
            <tr itemProp="offers"
                itemScope
                itemType="https://schema.org/Offer" key={index}>
                <td className='d-none d-sm-block'>
                    <a
                        title={product.name + ' категорії ' + product.category}
                        className='text-secondary fw-bold'
                        href={'/product/' + product.id +'/'+slugify(product.name+' '+product.category)}>
                        <span itemProp="productID">{product.code}</span>
                    </a>
                </td>
                <td>
                    <a
                        itemProp="url"
                        title={product.name + ' категорії ' + product.category}
                        className='text-secondary fw-bold'
                        href={'/product/' + product.id +'/'+slugify(product.name+' '+product.category)}>
                        <span itemProp="name">{product.name}</span>
                        <link itemProp="availability" href="https://schema.org/InStock" content="In stock"/>
                    </a>
                </td>
                <td itemProp="category">
                    <span itemProp="category">{product.category}</span>
                </td>
                <td>{product.tara}{' '}{product.unit}</td>
                <td>
                    <link itemProp="availability" href="https://schema.org/InStock" content="In stock"/>
                    <span itemProp="priceCurrency" content="EUR"/>
                    <span itemProp="price" content={centsToEur(product.price_ko).toFixed(2)}>
                    {centsToEur(product.price_ko).toFixed(2)}
                    </span>
                </td>
            </tr>
        )
    };
    const ProductTable = products.map((product, id) => ProductRow(product, id))

    const TableHeader =
        <thead className='bg-secondary text-white'>
            <tr>
                <th className='d-none d-sm-block'>КОД</th>
                <th>НАЗВА</th>
                <th>КАТЕГОРІЯ</th>
                <th>УПАКОВКА</th>
                <th>ЦІНА, € за кг</th>
            </tr>
        </thead>

    useEffect(() => {
        // TODO ch API url to vars for prod
        axios.get(pubApiAddr + '/product')
            .then((response) => {
                if (response) {
                    setIsLoading(false);
                }
                if (response.status === 200 && response.data.products) {
                    setProducts(response.data.products)
                }
            });
    }, []);

    if (isLoading) {
        return (
            <Loader/>
        )
    }

    return (
        <Container>
            <Table striped bordered hover size='sm' className='text-center'>
                {TableHeader}
                <tbody>
                    {ProductTable}
                </tbody>
            </Table>
        </Container>
    )
};

export default PriceList;