import CarouselHome from "../components/Site/CarouselHome";
import Feedbacks from "../components/Site/Feedbacks";
import Contacts from "../components/Site/Contacts";
import OurScores from "../components/Site/OurScores";
import ContactUs from "../components/Site/ContactUs";
import ContactForm from "../components/Site/ContactForm";
import Prices from "../components/Site/Prices";
import Delivery from "../components/Site/Delivery";
import TopBar from "../components/Site/TopBar";
import Payment from "../components/Site/Payment";
import FootNavbar from "../components/Site/FootNavbar";
import DocumentMeta from "react-document-meta";

const Site = () => {
    // const meta = {
    //     title: 'Секонд хенд оптом Євромікс : прямі поставки секонд-хенд оптом з Європи ',
    //     description: 'Склад секонд хенд Євромікс - прямі поставки секонд хенд із Європи. Купити секонд хенд оптом ➽ Безкоштовна доставка по Україні ✆ 099-464-00-04 ✆ 067-464-04-04',
    //     meta: {
    //         name: {
    //             keywords: "секонд-хенд, секонд-хенд Україна, Секонд-хенд гурт, одяг секонд-хенд, гурт секонд-хенд, секонд-хенд, second-hand, гуртівня секонд-хенд",
    //             'google-site-verification': "Ln_Qcf7WexqF61OtmgRuFUKgGkF99juYJ7tvo8vdz-c"
    //         }
    //     }
    // }
    return (
        <>

            {/*<DocumentMeta {...meta}/>*/}

            <TopBar/>

            <CarouselHome/>

            <OurScores/>

            <Delivery/>

            <Prices/>

            <Payment/>

            <ContactUs/>

            <ContactForm/>

            <Feedbacks/>

            <FootNavbar/>

            <Contacts/>

        </>
    )

};

export default Site;
