import ArticleList from "../components/Site/ArticleList";
import TopBar from "../components/Site/TopBar";
import Contacts from "../components/Site/Contacts";
import React from "react";
import DocumentMeta from "react-document-meta";

const Articles = () => {
    const meta = {
        title: 'Секонд хенд оптом Євромікс : статті та інформація про секонд-хенд ',
        description: 'Ціквва інформація про секонд-хенд та бізнес ➽ Оптовий склад Євромікс ✆ 099-464-00-04 ✆ 067-464-04-04',
        meta: {
            name: {
                keywords: "секонд-хенд, секонд-хенд Україна, Секонд-хенд гурт, одяг секонд-хенд, гурт секонд-хенд, секонд-хенд, second-hand, гуртівня секонд-хенд",
                'google-site-verification' : "Ln_Qcf7WexqF61OtmgRuFUKgGkF99juYJ7tvo8vdz-c"
            }
        }

    }
    const breadcrumbs = () => {
        return (
            <div itemScope itemType="http://schema.org/BreadcrumbList" className='bg-danger bg-gradient rounded px-2'>
                <h3 className='text-light'>
                    <a
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                        href='/'
                        className='text-light'>
                        <meta itemProp="item" content="https://secondhand.uz.ua"/>
                        <meta itemProp="position" content="1"/>
                        <meta itemProp="name" content="Головна"/>
                        Головна
                    </a>
                    {' > '}
                    Статті
                </h3>
            </div>
        )
    }
    return(
            <>
                <DocumentMeta {...meta}/>

                <TopBar/>

                {breadcrumbs()}

                <ArticleList/>

                <Contacts/>
            </>
        )
};

export default Articles;

