import BaseNavbar from "../components/Base/BaseNavbar";
import NotificationList from "../components/Base/NotificationList";

const Notifications = () => {
    return(
            <>
                <BaseNavbar/>

                <NotificationList/>
            </>
        )
};

export default Notifications;
