import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {apiAddr, pubApiAddr} from "../../utils/api";
import {Loader} from "../Loader";
import Container from "react-bootstrap/Container";
import {Button, Col, Form, Image, Row} from "react-bootstrap";
import DocumentMeta from "react-document-meta";
import PushMessage from "../PushMessage";

const Product = () => {
    const {id} = useParams();
    const [product, setProduct] = useState(
        {
            id: 0,
            code: '',
            name: '',
            image: [],
            category: '',
            price_ko: 0,
            price_mo: 0,
            comment: '',
            description: '',
            tara: 0,
            unit: '',
            quantity: 0,
            is_top: false,
            is_deleted: false,
            show_web: true,
            show_price: true,
            is_hidden: false
        }
    );
    const breadcrumbs = (product) => {
        return (
            <div itemScope itemType="http://schema.org/BreadcrumbList" className='bg-danger bg-gradient rounded px-2'>
                <h3 className='text-light'>
                    <a
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                        href='/'
                        className='text-light'>
                        Головна
                        <meta itemProp="item" content="https://secondhand.uz.ua"/>
                        <meta itemProp="position" content="1"/>
                        <meta itemProp="name" content="Головна"/>
                    </a>
                    {' > '}
                    {product.name}
                </h3>
            </div>
        )
    }
    const meta = {
        title: product.name + ' ' + product.category + ' секонд хенд оптом Євромікс',
        description: product.name + ' секонд-хенд ' + product.category + ' ➽ Безкоштовна доставка по Україні ✆ 099-464-00-04 ✆ 067-464-04-04',
        meta: {
            name: {
                keywords: product.name + ', ' + product.category + ", секонд-хенд, секонд-хенд Україна, Секонд-хенд гурт, одяг секонд-хенд, гурт секонд-хенд, секонд-хенд, second-hand, гуртівня секонд-хенд",
            }
        }

    }
    const [isLoading, setIsLoading] = useState(true);
    const alter = product.name + ' секонд-хенд ' + product.category + " Секонд-хенд оптом із Європи"
    const [notification, setNotification] = useState({
        name: '',
        phone: '',
        date: new Date(),
        notrobot: false,
        comment: 'замовити'
    })
    const [sent, setSent] = useState(false);
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Дякуємо! Ми зателефонуємо Вам найближчим часом',
        variant: 'success'
    });
    const handleChange = event => {
        const {name, value} = event.target;
        setNotification({...notification, [name]: value});
    };
    const handleCheck = event => {
        let val = !notification.notrobot
        setNotification({...notification, notrobot: val});
    };
    const handleSubmit = () => {
        if (notification.notrobot && notification.name.length > 2 && notification.phone.length > 9) {
            const values =
                {
                    name: notification.name,
                    phone: notification.phone,
                    comment: 'замовити '+product.code,
                    status: 'Новий',
                    date: notification.date
                }
            fetch(apiAddr+'/notifications', {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 201) {
                        setToast({message: 'Дякуємо! Ми зателефонуємо Вам найближчим часом', variant: 'light'})
                        setShow(true);
                        setSent(true)
                    }
                })
                .catch((error) => {
                    setToast({message: error, variant: 'warning'})
                    setShow(true);

                });
        } else {
            setToast({message: 'Заповніть коректно Ваші дані', variant: 'warning'})
            setShow(true);
        }
    }
    useEffect(() => {
            setIsLoading(true)
            axios.get(pubApiAddr + '/product/' + id)
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        setProduct(response.data)
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setToast({message: error.message, variant: 'warning'})
                    setShow(true);
                });
        }
        , []);
    if (isLoading) {
        return (
            <Loader/>
        )
    }
    return (
        <>
            <DocumentMeta {...meta}/>
            {breadcrumbs(product)}
            <Container className='text-secondary'>
                <div>
                    <div itemScope itemType="https://schema.org/Product" className='row'>
                        <div className='text-secondary text-center'>
                            <h1 itemProp="name">
                                {product.name}
                                {' '}
                                секонд-хенд категорії
                                {' '}
                                {product.category}
                            </h1>
                        </div>

                        <div className='col-sm-6 p-3'>
                            {PushMessage({toast}, [show, setShow])}

                            {product.image.length < 1 ? <Image
                                    style={{opacity: 0.1}}
                                    alt={alter}
                                    src="/small_logo.jpeg"
                                    width="100%"
                                /> :
                                <Image
                                    itemProp="image"
                                    className='rounded'
                                    style={{opacity: 0.9}}
                                    alt={alter}
                                    src={product.image}
                                    width="100%"
                                />
                            }
                            <meta itemProp="image" content={product.image}/>
                            <link itemProp="availability" href="https://schema.org/InStock" content="In stock"/>
                        </div>
                        <div className='col-sm-6 mt-4 p-2'>
                            <h3 className='mt-3'>
                                <small>
                                    Код товару :
                                </small>
                                {' '}
                                <b className='text-danger'>
                                <span itemProp="productID">{product.code}</span>
                                </b>
                            </h3>
                            <h3 className='mt-3'>
                                <small>
                                    Категорія :
                                </small>
                                {' '}
                                <b className='text-danger'>
                                {product.category}
                                </b>
                            </h3>
                            <h3 className='mt-3'>
                                <small>
                                    Упаковка :
                                </small>
                                {' '}
                                <b className='text-danger'>
                                {product.tara}
                                {' '}
                                {product.unit}
                                </b>
                            </h3>
                            <h3 itemProp="offers" itemScope itemType="https://schema.org/Offer" className='mt-3'>
                                <link itemProp="availability" href="https://schema.org/InStock" content="In stock"/>
                                <small>
                                    Ціна :
                                </small>
                                {' '}
                                <b itemProp="price" content={(product.price_ko / 100).toFixed(2)} className='text-danger'>
                                {(product.price_ko / 100).toFixed(2)}
                                <span itemProp="priceCurrency" content="EUR">€</span>
                                </b>
                                {' за '}
                                {product.unit}
                            </h3>
                            <div className='border border-secondary rounded p-2 mt-5'>
                                <h4>
                                    Замовити в 1 клік:
                                </h4>
                                <Form className='row text-center '>
                                    <Form.Group className="mb-3 col-6" controlId="Phone">
                                        {/*<Form.Label><b className='text-white'>Ваш номер</b></Form.Label>*/}
                                        <Form.Control
                                            disabled={sent}
                                            name='phone'
                                            onChange={handleChange}
                                            type="text"
                                            placeholder="Телефон"/>
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-6" controlId="Name">
                                        {/*<Form.Label><b className='text-white'>Ваше імʼя</b></Form.Label>*/}
                                        <Form.Control
                                            disabled={sent}
                                            name='name'
                                            onChange={handleChange}
                                            type="text"
                                            placeholder="Імʼя"/>
                                    </Form.Group>
                                    <Form className='row mb-2 mt-3'>
                                        <Form.Group className="mb-3 col-5 text-left mx-3" controlId="formBasicCheckbox">
                                            {/*<Form.Label><nobr><b className='text-white'>Ви не робот?</b></nobr></Form.Label>*/}
                                            <Form.Check
                                                onClick={handleCheck}
                                                name='notrobot'
                                                className='mt-2'
                                                type="checkbox"
                                                label={<nobr>Я не робот!</nobr>}/>
                                        </Form.Group>
                                        <Col className='sm-7'>
                                            <Button
                                                disabled={sent}
                                                variant="danger"
                                                size='lg'
                                                onClick={handleSubmit}
                                            >
                                                Замовити
                                            </Button>
                                        </Col>

                                    </Form>
                                </Form>
                            </div>

                        </div>
                        <h4 itemProp="description">{product.name}
                            {' '}
                            секонд-хенд категорії
                            {' '}
                            {product.category}
                            {'. '}
                            {product.description}
                            {' '}
                            Акуратно складені і упаковані в прозорі поліетиленові мішки по
                            {' '}
                            {product.tara}
                            {' '}
                            {product.unit}
                            {'. '}

                        </h4>
                        <p>
                            При відправці замовленого товару через транспортну службу для Вашої зручності є можливість зашивати упаковки в непрозорі поліпропіленові мішки. Такі мішки захистять упаковки при транспортуванні від пошкоджень, забруднень і несанкціонованого розкриття. Вартість послуги - € 1.00 / упак.
                        </p>
                        <hr/>
                    </div>

                    <p>
                        <b>Секонд-хенд категорії КРЕМ </b>- якісні речі, переважно брендові, без слідів зносу і дефектів. Велика кількість нових і майже нових речей. Речі чисті і без плям, браку і сторонніх запахів.
                    </p>
                    <p>
                        <b> Секонд-хенд категорії ЛЮКС </b>- якісні речі без дефектів і браку з незначним або практично без зносу. Речі чисті і без плям, пошкоджень і сторонніх запахів. </p>
                    <p>
                        <b>Секонд-хенд 1 сорту </b>- якісні та чисті речі з невеликим зносом, придатні для використання за прямим призначенням. Рідко трапляються речі з усуненими дефектами і забрудненнями.
                    </p>
                    <p>
                        <b> Секонд-хенд категорії 2 сорт </b>- сортовані речі з зносом придатні до використання за прямим призначенням. Можливі незначні дефекти і забруднення, в т.ч. легко переборні.
                    </p>
                </div>
            </Container>
        </>
    );
};

export default Product;
