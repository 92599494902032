import Container from "react-bootstrap/Container";
import { Col, Image, Row} from "react-bootstrap";
import DeliveryNovaposhta from "../../image/delivery_novaposhta.png";
import DeliveryUkrposhta from "../../image/delivery_ukrposhta.png";
import DeliveryDelivery from "../../image/delauto-logo--small.jpeg";

const Delivery = () => {
    return (
            <Container id='delivery' className='text-center px-0 my-4'>
                <h2 className='text-secondary'>
                    Доставка по Україні
                </h2>

                <br/>
                <h5 className='text-secondary'>
                    Всі, хто працює із гуртовим складом секонд-хенд "Євромікс", можуть отримати своє замовлення у свому місті, не сплачуючи за доставку.
                    При оформленні будь-якого замовлення на нашому гуртовому складі ми пропонуємо безкоштовну доставку у Ваше місто.
                    Умови безкоштовної доставки поширюються на всі замовлення без обмежень в сумі і кількості товару, що замовляється.
                </h5>
                <Row className='my-4 justify-content-center'>
                    <Col xs={4} sm={4} >
                        <Image fluid src={DeliveryUkrposhta} width='200px' className='px-2 px-sm-3 mb-2'/>
                        <p className='d-sm-block d-none'>
                            Відправка в робочі дні в усі міста України і населені пункти України. Тип доставки відділення-відділення. Адресна доставка оплачується додатково згідно тарифів Укрпошти.
                        </p>
                    </Col>
                    <Col xs={4} sm={4} >
                        <Image fluid src={DeliveryNovaposhta} width='200px' className='px-2 px-sm-3 mb-2'/>
                        <p className='d-sm-block d-none'>
                            Відправка в робочі дні у всі міста України і населені пункти з відділеннями Нової Пошти. Доставка відділення-відділення. Адресна доставка оплачується додатково згідно тарифів Нової Пошти.
                        </p>
                    </Col>
                    <Col xs={4} sm={4} >
                        <Image fluid src={DeliveryDelivery} width='200px' className='px-2 px-sm-3 mb-3'/>
                        <p className='d-sm-block d-none'>
                            Відправка в робочі дні в усі міста України і населені пункти з відділеннями Делівері. Тип доставки відділення-відділення. Адресна доставка оплачується додатково згідно тарифів перевізника.
                        </p>
                    </Col>
                </Row>
                <p>
                    Відправлення великих оптових партій товару ми організуємо власним або найманим транспортом в будь-яку точку України.
                    Подробиці уточнюйте у наших менеджерів.
                </p>
                <hr/>
            </Container>
    )
};
export default Delivery;
