import React, {useEffect, useState} from 'react';
import BaseNavbar from "./BaseNavbar";
import {Button, Col, Form, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import PushMessage from "../PushMessage";
import {useNavigate} from "react-router-dom";
import {apiAddr} from "../../utils/api";
import axios from "axios";

const NewProduct = () => {
    const [product, setProduct] = useState(
        {
            code: '',
            name: '',
            category: '',
            cost_netto: 0,
            cost_brutto: 0,
            price_ko: 0,
            price_mo: 0,
            comment: '',
            description: '',
            tara: 0,
            unit: '',
            quantity: 0,
            image: '',
            is_top: false,
            is_deleted: false,
            show_web: true,
            show_price: true,
            is_hidden: false
        }
    );
    const [images, setImages] = useState([])
    const [imageUrls, setImageUrls] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
            if (images.length < 1) return;
            const newImageUrls = [];
            images.forEach(image => {
                newImageUrls.push(URL.createObjectURL(image))
            })
            setImageUrls(newImageUrls)
        }, [images]
    )
    const onImageChange = (event) => {
        setImages([...event.target.files])
    }
    const eurToCents = (cents) => {
        let c = Number(cents)
        let ch = Math.round(c * 100)
        return ch
    }
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Створено новий товар',
        variant: 'light'
    });
    const [uploaded, setUploaded] = useState(false);
    const handleChange = event => {
        const {name, value} = event.target;
        setProduct({...product, [name]: value});
    };
    const handleCheck = event => {
        const {name} = event.target;
        let val = !product[name]
        setProduct({...product, [name]: val});
    };
    const handleSubmit = () => {
        const values =
            {
                code: product.code,
                name: product.name,
                category: product.category,
                tara: Number(product.tara),
                unit: product.unit,
                costNetto: eurToCents(product.cost_netto),
                costBrutto:eurToCents(product.cost_brutto),
                priceKo: eurToCents(product.price_ko),
                priceMo: eurToCents(product.price_mo),
                description: product.description,
                comment: product.comment,
                isTop: product.is_top,
                isDeleted: false,
                isHidden: product.is_hidden,
                showPrice: product.show_price,
                showWeb: product.show_web,
                quantity: Number(product.quantity),
                image: product.image,
            }
        console.log(values)
        fetch(apiAddr + '/products', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {'Content-Type': 'application/json',}
        })
            .then((response) => {
                if (response.status === 201) {
                    setToast({message: 'Створено новий товар', variant: 'light'})
                    setShow(true);
                }
            })
            .catch((error) => {
                setShow(true);
                setToast({message: error, variant: 'warning'})
                console.log(error.message)
            });
    }

    const handleSaveImage = () => {
        const data = new FormData()
        data.append('file', images[0])
        axios.post(apiAddr + '/media_objects', data)
            .then((response) => {
                if (response.status === 201) {
                    setProduct({...product, image: response.data.contentUrl})
                    setToast({message: 'Фото завантажено на сервер', variant: 'info'})
                    setShow(true);
                    setUploaded(true)
                }
            })
            .catch((error) => {
                setShow(true);
                setToast({message: error.message, variant: 'warning'})
                console.log(error)
            });
    }
    return (
        <>
            <BaseNavbar/>
            <Container>
                {PushMessage({toast}, [show, setShow])}
                <Form.Group as={Row} className='mt-3'>
                    <Form.Group as={Col} className='mt-3'>
                        <Form.Group as={Row} className='mt-3'>
                            <Form.Label column sm="2">Код</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='code'
                                    value={product.code || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Назва</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='name'
                                    value={product.name || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Категорія</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='category'
                                    value={product.category || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Тара</Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    name='tara'
                                    value={product.tara || ''}
                                    onChange={handleChange}
                                    type="number"
                                />
                            </Col>
                            <Form.Label column sm="2">Одиниця</Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    name='unit'
                                    value={product.unit || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Закупка</Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    name='cost_netto'
                                    value={product.cost_netto || ''}
                                    onChange={handleChange}
                                    type="number"
                                />
                            </Col>
                            <Form.Label column sm="2">Собівартість</Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    name='cost_brutto'
                                    value={product.cost_brutto || ''}
                                    onChange={handleChange}
                                    type="number"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Ціна КО</Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    name='price_ko'
                                    value={product.price_ko || ''}
                                    onChange={handleChange}
                                    type="number"
                                />
                            </Col>
                            <Form.Label column sm="2">Ціна МО</Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    name='price_mo'
                                    value={product.price_mo || ''}
                                    onChange={handleChange}
                                    type="number"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-3'>
                            <Form.Group as={Col} className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    label="топ-товар"
                                    name='is_top'
                                    onChange={handleCheck}
                                    checked={product.is_top}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    label="в прайс"
                                    name='show_price'
                                    onChange={handleCheck}
                                    checked={product.show_price}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    label="на сайт"
                                    name='show_web'
                                    onChange={handleCheck}
                                    checked={product.show_web}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    label="сховати"
                                    name='is_hidden'
                                    onChange={handleCheck}
                                    checked={product.is_hidden}
                                />
                            </Form.Group>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4 text-center'>
                            <Col>
                                <Button
                                    className='mx-auto'
                                    onClick={() => navigate(-1)}
                                    variant='outline-danger'>
                                    Назад
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className='mx-auto'
                                    onClick={handleSubmit}
                                    variant="outline-success">
                                    ВПЕРЕД
                                </Button>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group as={Col} className='mt-3'>
                        <Form.Group as={Row} className='mt-3'>
                            <Form.Label column sm="2">Опис</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='description'
                                    value={product.description || ''}
                                    onChange={handleChange}
                                    as="textarea"
                                    rows={6}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Примітки</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='comment'
                                    value={product.comment || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Склад</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='quantity'
                                    value={product.quantity || ''}
                                    onChange={handleChange}
                                    type="number"
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Фото</Form.Label>
                            <Col sm="8">
                                <Form.Group
                                    controlId="formFile" className="mb-3">
                                    <Form.Control
                                        // multiple
                                        accept="image/*"
                                        onChange={onImageChange}
                                        type="file"/>
                                </Form.Group>
                            </Col >
                            <Col sm="2">
                                {(images.length > 0) && (uploaded === false) && <Button
                                    className='text-center'
                                    onClick={handleSaveImage}
                                    variant="outline-success">
                                    ДОДАТИ
                                </Button>}
                            </Col>
                        </Form.Group>
                       <Form.Group as={Row} className='mt-4 text-center'>
                           <Col className='ml-auto text-right'>
                               {imageUrls.map(imageSrc => <img
                                   alt={product.name}
                                   key={imageSrc}
                                   src={imageSrc}
                                   className='mx-5 px-2 mt-3 text-center'
                                   height='200'
                               />)}
                           </Col>
                        </Form.Group>
                    </Form.Group>
                </Form.Group>
            </Container>

        </>
    );
};

export default NewProduct;
