import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Col, Form,  Row, Table} from "react-bootstrap";
import dateNorm from "../../utils/dateForm";
import PushMessage from "../PushMessage";
import {apiAddr} from "../../utils/api";

const NotificationList = () => {

    const [notifications, setNotifications] = useState([
        {
            id: 0,
            name: '',
            phone: '',
            status: '',
            user: 0,
            date: new Date(),
        }
    ])
    const [users, setUsers] = useState([])
    const Statuses = [
        {name: "Новий"},
        {name: "Прийнятий"},
        {name: "Закритий"},
        {name: "Скасований"},
    ]
    const [filters, setFilters] = useState(
        {
            startDate: dateNorm(new Date),
            endDate: dateNorm(new Date),
            user: null,
            status: 'Всі'
        }
    )
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Зміни статусів збережені',
        variant: 'light'
    });
    const filteredNotes =
        notifications.filter(note =>
            ((filters.status !== 'Всі') ? (note.status === filters.status) : true)
            && dateNorm(note.date) >= filters.startDate
            && dateNorm(note.date) <= filters.endDate)

    const handleFilters = event => {
        const {name, value} = event.target;
        setFilters({...filters, [name]: value});
    };
    const handleStatus = (event, notification) => {
        event.preventDefault()
        const {name, value} = event.target;
        let Note = ({...notification, [name]: value})
        NoteUpdate(Note.id, Note)
    };
    const handleComment=(event, notification)=> {
        const {name, value} = event.target
        if (event.keyCode === 13) {
            let Note = ({...notification, [name]: value})
            NoteUpdate(Note.id, Note)
        }
    }
    const NoteUpdate = (id, Note) => {
        fetch(apiAddr +'/notifications/' + id, {
            method: 'PUT',
            body: JSON.stringify(Note),
            headers: {'Content-Type': 'application/json',}
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }
    const rowStyled = (note) => {
        if (note.status === "Скасований") return 'text-black-50 bg-light'
        if (note.status === "Новий") return ' bg-warning fw-bold'
        if (note.status === "Прийнятий") return 'text-success  fw-bolder'
        return 'text-muted bg-light'
    }

    useEffect(() => {
        // TODO ch API url to vars for prod
        axios.get(apiAddr+'/notifications')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setNotifications(response.data['hydra:member'])
                    setIsLoading1(false);
                }
            });
    }, [notifications]);

    useEffect(() => {
        // TODO ch API url to vars for prod
        axios.get(apiAddr+'/users')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setUsers(response.data['hydra:member'])
                    setIsLoading2(false);
                }
            });
    }, []);

    const [isLoading2, setIsLoading2] = useState(true);
    const [isLoading1, setIsLoading1] = useState(true);

    const Status = (notification, id) => {
        return (
            <Form.Select
                name='status'
                value={notification.status}
                onChange={event => handleStatus(event, notification)}>
                {Statuses.map((st, index) => <option key={index} value={st.name}>{st.name}</option>)}
            </Form.Select>
        )
    }

    const TableHeader =
        <thead className='text-center text-white bg-secondary'>
            <tr>
                <th>ФІО</th>
                <th>телефон</th>
                <th>статус</th>
                <th>дата</th>
                <th>прийняв</th>
                <th>коментар</th>
            </tr>
        </thead>

    const NotificationRow = (notification, id) => {
        return (
            <tr key={id} className={rowStyled(notification)}>
                <td>{notification.name}</td>
                <td className='text-center'>{notification.phone}</td>
                <td className='text-center'>{Status(notification, notification.id)}</td>
                <td className='text-center'>{dateNorm(notification.date)}</td>
                <td>
                    <Form.Select
                        name='user'
                        value={notification.user}
                        onChange={event => handleStatus(event, notification)}>
                        <option value=''>...</option>
                        {users.map((u, index) => <option key={index} value={u.name}>{u.name}</option>)}
                    </Form.Select>
                </td>
                <td className='text-center'>
                        <Form.Control
                            name='comment'
                            type='text'
                            defaultValue={notification.comment}
                            onKeyDown={event => handleComment(event, notification) }/>
                </td>
            </tr>
        )
    };
    const NotificationTable = filteredNotes.map((notification, id) => NotificationRow(notification, id))
    const Filters =
        <Form.Group as={Row} className='my-2'>
            Дата з
            <Col sm="2">
                <Form.Control
                    name='startDate'
                    value={filters.startDate}
                    onChange={handleFilters}
                    type="date"/>
            </Col>
            по
            <Col sm="2">
                <Form.Control
                    name='endDate'
                    value={filters.endDate}
                    onChange={handleFilters}
                    type="date"/>
            </Col>
            форма
            <Col sm="2">
                <Form.Select
                    name='status'
                    value={filters.status}
                    onChange={handleFilters}
                    type="text">
                    <option value='Всі'>Всі</option>
                    {Statuses.map((st, index) => <option key={index} value={st.name}>{st.name}</option>)}
                </Form.Select>
            </Col>
            <Col>
            </Col>
        </Form.Group>

    if ( isLoading1 && isLoading2 ) {
        return (
            <Loader/>
        )
    }

    return (
        <Container>
            {Filters}
            {PushMessage({toast}, [show, setShow])}
            <Table bordered responsive className='mt-3'>
                {TableHeader}
                <tbody>
                    {NotificationTable}
                </tbody>
            </Table>

        </Container>
    )
};

export default NotificationList;
