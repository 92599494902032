import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import {apiAddr} from "../../utils/api";
import BaseNavbar from "./BaseNavbar";

const Check = () => {

    const [products, setProducts] = useState([
        {
            id: '',
            code: '',
            name: '',
            category: '',
            cost_netto: '',
            cost_brutto: '',
            price_ko: '',
            price_mo: '',
            comment: '',
            tara: 0,
            unit: '',
            quantity: 0,
            ordered: false,
            ordered_quantity: 0,
            incomed_quantity: 0,
            fixed_state: 0,
            summ: 0
        }
    ]);

    const cellStyled = (q) => {
        if (q !== 0) return 'text-danger text-center'
        return 'text-black text-center'
    }

    const CalculateIncomed = (prs) => {
        let Quantity = 0
        prs.forEach(pr => {
            Quantity += (pr.incomed_quantity ? pr.incomed_quantity : 0)
        })
        return Quantity
    }
    const CalculateQuantity = (prs) => {
        let Quantity = 0
        prs.forEach(pr => {
            Quantity += (pr.ordered_quantity ? pr.ordered_quantity : 0)
        })
        return Quantity
    }
    const CalculateSumm = (prs) => {
        let Summ = 0
        prs.forEach(pr => {
            Summ += Number(pr.quantity ? pr.quantity : 0)
        })
        return Summ
    }
    const CalculateFixedSumm = (prs) => {
        let Summ = 0
        prs.forEach(pr => {
            Summ += Number(pr.incomed_quantity - pr.ordered_quantity)
        })
        return Summ
    }
    const CalculateProfit = (prs) => {
        let Summ = 0
        prs.forEach(pr => {
            Summ += Number(pr.summ - (pr.cost_brutto * pr.ordered_quantity))
        })
        return Summ
    }

    const compare = (a, b) => {
        if (a.code < b.code) {
            return -1;
        }
        if (a.code > b.code) {
            return 1;
        }
        return 0;
    }
    const upProducts = (prods) => {
        prods.forEach(function (element) {
            element.incomed_quantity = 0;
            element.ordered_quantity = 0;
            element.fixed_state = 0;
        });
        return (prods)
    }
    const fixState = (fid, value) => {
        fetch(apiAddr + '/products/' + fid, {
            method: 'PUT',
            body: JSON.stringify({
                quantity: value,
            }),
            headers: {'Content-Type': 'application/json',}
        }).then(response => {
                if (response.status === 200) {
                    setUpdated(true)
                    alert('Залишки виправлено')
                }
            }
        )
            .catch((error) => {
                console.log(error.message)
            });
    }

    const [isLoading, setIsLoading] = useState(true);
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        axios.get(apiAddr + '/products')
            .then((response) => {
                    if (response.status === 200 && response.data) {
                        const Arr = upProducts(response.data['hydra:member'])
                        axios.get(apiAddr + '/orders')
                            .then((response) => {
                                    if (response.status === 200 && response.data) {
                                        const Orders =
                                            response.data['hydra:member'].filter(order => !order.is_deleted)

                                        Orders.forEach(ord => {
                                            ord.products.forEach(pr => {
                                                let pid = Arr.findIndex(opr => opr.id === pr.id)
                                                Arr[pid].ordered_quantity += pr.ordered_quantity
                                            })
                                        })
                                        axios.get(apiAddr + '/invoices')
                                            .then((response) => {
                                                    if (response.status === 200 && response.data) {
                                                        const Invoices =
                                                            response.data['hydra:member'].filter(order => !order.is_deleted)
                                                        Invoices.forEach(ord => {
                                                            ord.products.forEach(pr => {
                                                                let pid = Arr.findIndex(opr => opr.id === pr.id)
                                                                Arr[pid].incomed_quantity += pr.ordered_quantity
                                                            })
                                                        })
                                                        setProducts(Arr)
                                                        setIsLoading(false)
                                                        setUpdated(false)
                                                    }
                                                }
                                            )
                                    }
                                }
                            )
                    }
                }
            )

    }, [updated]);

    const ProductRow = (product, id) => {
        const difference = product.quantity - product.incomed_quantity + product.ordered_quantity
        return (
            <tr key={id}>
                <td>
                    {product.code}
                </td>
                <td>
                    {product.name}
                </td>
                <td className='text-center fw-bold'>
                    {product.incomed_quantity}
                </td>
                <td className='text-center fw-bold'>
                    {product.ordered_quantity}
                </td>
                <td className={cellStyled(difference)}>
                    {product.quantity}
                </td>
                <td className='text-center'>
                    {product.incomed_quantity - product.ordered_quantity}
                </td>
                <td className='text-center'>
                    {difference !== 0 && <Button
                        onClick={() => fixState(product.id, (product.incomed_quantity - product.ordered_quantity))}
                        variant='danger'
                        size='sm'>
                        Виправити
                    </Button>}
                </td>

            </tr>
        )
    };
    const ProductTable = products.sort(compare).map((product, id) => ProductRow(product, id))
    const TableHeader =
        <thead>
            <tr className='text-center'>
                <th>КОД</th>
                <th>НАЗВА</th>
                <th>ЗАКУПЛЕНО</th>
                <th>ПРОДАНО</th>
                <th>СКЛАД</th>
                <th>МАЄ БУТИ</th>
                <th>ДІЯ</th>
            </tr>
        </thead>

    if (isLoading) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            <BaseNavbar/>
            <Container>
                <br/>
                <Table striped bordered hover responsive size='sm'>
                    {TableHeader}
                    <tbody>
                        {products.length > 0 && ProductTable}
                        <tr className='text-center'>
                            <td></td>
                            <td>Підсумок</td>
                            <td>{CalculateIncomed(products)} кг</td>
                            <td>{CalculateQuantity(products)} кг</td>
                            <td>{CalculateSumm(products)} кг</td>
                            <td>{CalculateFixedSumm(products)} кг</td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </>
    )
};

export default Check;
