import {Col, Row, Table} from "react-bootstrap";
import {Helmet} from "react-helmet";
import { faFacebook, faGooglePlus, faViber } from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Contacts = () => {
    return (
        <>
            <Table id='contacts' className='text-white-50 text-center bg-dark w-100 my-0'>
                <Row>
                    <h6 className='mt-3 text-light'>
                        Україна,
                        Ужгород,
                        вул. Гагаріна, 36
                    </h6>
                    <Col xs={6} sm={4}>
                        <a href="tel:+380994640004">099-464-00-04</a>
                        <br/>
                        <a href="tel:+380995619200">099-561-92-00</a>

                    </Col>
                    <Col xs={6} sm={4}>
                        <a href="tel:+380674640404">067-464-04-04</a>
                        <br/>
                        <a href="tel:+380681424444">068-142-44-44</a>
                    </Col>
                    <Col xs={12} sm={4}>
                        <a href="viber://chat?number=%2B380995619200" title="Viber" target='_blank' className="mx-1 mx-md-2">
                            <FontAwesomeIcon className="fa-2x" color="#8f5db7" icon={faViber}/>
                        </a>

                        <a href="https://www.facebook.com/euromix.ua" title="Facebook/Second.Store" target='_blank'>
                            <FontAwesomeIcon className="fa-2x mx-1" color="#3b5999" icon={faFacebook}/>
                        </a>

                        <a href="https://g.page/euromix-secondhand?share" title="Google+" target='_blank' className="mx-1 mx-md-2">
                            <FontAwesomeIcon className="fa-2x" color="#dd4b39" icon={faGooglePlus}/>
                        </a>
                    </Col>
                </Row>
                <Row className='mt-3 text-light'>
                    <h5>Графік роботи складу :</h5>
                    <p>
                        Понеділок - П'ятниця : 9:00 - 17:00
                        <br/>
                        Субота       : 9:00 - 15:00
                        <br/>
                        Неділя        : вихідний
                    </p>
                </Row>
                <Row>
                    <p>
                        © 2009-{new Date().getFullYear()} Секонд-хенд оптом EuroMix. All Rights Reserved. Designed by Voglas.
                    </p>
                </Row>
            </Table>
            <Helmet>
                <script
                    src='//widgets.binotel.com/chat/widgets/rpZvBcvsN6r870H8MHVS.js'
                    type="text/javascript"
                    async
                ></script>
            </Helmet>
        </>
    )

};

export default Contacts;