import Contacts from "../components/Site/Contacts";
import ContactUs from "../components/Site/ContactUs";
import ContactForm from "../components/Site/ContactForm";
import TopBar from "../components/Site/TopBar";
import FootNavbar from "../components/Site/FootNavbar";
import DocumentMeta from "react-document-meta";
import {Col, Row, Table} from "react-bootstrap";
import React from "react";

const WeAre = () => {
    const breadcrumbs = () => {
        return (
            <div itemScope itemType="http://schema.org/BreadcrumbList" className='bg-danger bg-gradient rounded px-2'>
                <h3 className='text-light'>
                    <a
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                        href='/'
                        className='text-light'>
                        <meta itemProp="item" content="https://secondhand.uz.ua"/>
                        <meta itemProp="position" content="1"/>
                        <meta itemProp="name" content="Головна"/>
                        Головна
                    </a>
                    {' > '}
                    Контакти
                </h3>
            </div>
        )
    }

    const meta = {
        title: 'Склад секонд-хенд ЄвроМікс : Україна, Ужгород, вул.Гагаріна 36 ',
        description: 'Секонд-хенд оптом ЄвроМікс : Україна, Ужгород, вул.Гагаріна 36 ✆ 099-464-00-04 ✆ 067-464-04-04. ➽ Безкоштовна доставка по Україні ✆ 099-464-00-04 ✆ 067-464-04-04',
        meta: {
            name: {
                keywords: "секонд-хенд, секонд-хенд Україна, Секонд-хенд гурт, одяг секонд-хенд, гурт секонд-хенд, секонд-хенд, second-hand, гуртівня секонд-хенд",
                'google-site-verification': "Ln_Qcf7WexqF61OtmgRuFUKgGkF99juYJ7tvo8vdz-c"
            }
        }
    }
    return (
        <>

            <DocumentMeta {...meta}/>

            <TopBar/>

            {breadcrumbs()}

            <Table>
                <Row className='text-center text-secondary'>
                    <Col sm={4} xs={12}>
                        <h3>
                            Адреса складу
                        </h3>
                        <h6 className='mt-3'>
                            Україна,
                            Ужгород,
                            вул. Гагаріна, 36
                        </h6>
                    </Col>
                    <Col sm={4} xs={12}>
                        <h3>
                            Наші телефони
                        </h3>
                        <a href="tel:+380994640004" className='text-secondary'>099-464-00-04</a>
                        <br/>
                        <a href="tel:+380995619200" className='text-secondary'>099-561-92-00</a>
                        <br/>
                        <a href="tel:+380674640404" className='text-secondary'>067-464-04-04</a>
                        <br/>
                        <a href="tel:+380681424444" className='text-secondary'>068-142-44-44</a>
                    </Col>
                    <Col sm={4} xs={12}>
                        <h3>Графік роботи</h3>
                        <p>
                            Понеділок - П'ятниця : 9:00 - 17:00
                            <br/>
                            Субота       : 9:00 - 15:00
                            <br/>
                            Неділя        : вихідний
                        </p>
                    </Col>
                </Row>

            </Table>
            <ContactUs/>

            <ContactForm/>

            <FootNavbar/>

            <Contacts/>

        </>
    )

};

export default WeAre;