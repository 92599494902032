import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Table} from "react-bootstrap";
import {apiAddr} from "../../utils/api";
import BaseNavbar from "./BaseNavbar";

const ProductListAdmin = () => {

    const [products, setProducts] = useState([
        {
            id: '',
            code: '',
            name: '',
            category: '',
            cost_netto: '',
            cost_brutto: '',
            price_ko: '',
            price_mo: '',
            comment: '',
            tara: 0,
            unit: '',
            quantity: 0,
            ordered: false,
            ordered_quantity: 0,
            summ: 0,
            image: []
        }
    ]);

    const [isLoading, setIsLoading] = useState(true);

    const cellStyled = (deleted) => {
        if (deleted === true) return 'text-muted text-center'
        return 'text-black text-center'
    }

    useEffect(() => {
            setIsLoading(true);
            axios.get(apiAddr + '/products')
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        setProducts(response.data['hydra:member'])
                        setIsLoading(false);
                    }
                })
        }
        , []);

    const ProductRow = (product, id) => {
        return (
            <tr key={id} className={cellStyled(product.is_deleted)}>
                <td><a className='text-dark fw-bold' href={'/base/product/' + product.id}>{product.code}</a></td>
                <td>{product.name}</td>
                <td className='text-center'>{product.category}</td>
                <td className='text-center'>
                    {(product.price_ko / 100).toFixed(2)}
                </td>
                <td className='text-center'>
                    {(product.price_mo / 100).toFixed(2)}
                </td>
                <td className='text-center'>
                    {(product.cost_brutto / 100).toFixed(2)}
                </td>
                <td className='text-center'>
                    {(product.cost_netto / 100).toFixed(2)}
                </td>
                <td className={cellStyled(product.quantity)}>{product.quantity}</td>
                <td>{product.comment}</td>
                <td className='text-center'>
                    {
                       product.image!=='[]'&&product.image!==''?
                           <img
                               height={32}
                               src={product.image}/>

                        :
                               '-'


                    }
                </td>
            </tr>
        )
    };
    const ProductTable = products.map((product, id) => ProductRow(product, id))
    const TableHeader =
        <thead>
            <tr>
                <th>КОД</th>
                <th>НАЗВА</th>
                <th>КАТЕГОРІЯ</th>
                <th>ЦІНА ко</th>
                <th>ЦІНА мо</th>
                <th>СВ</th>
                <th>ЗАКУПКА</th>
                <th>СКЛАД</th>
                <th>ПРИМ</th>
                <th>ФОТО</th>
            </tr>
        </thead>

    if (isLoading) {
        return (<Loader/>)
    }

    return (
        <>
            <BaseNavbar/>
            <Container className='mt-3'>
                <Table bordered hover responsive>
                    {TableHeader}
                    <tbody>
                        {ProductTable}
                    </tbody>
                </Table>
            </Container>
        </>
    )
};

export default ProductListAdmin;
