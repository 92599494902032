import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import dateNorm from "../../utils/dateForm";
import {apiAddr} from "../../utils/api";

const CustomerList = () => {

    const [customers, setCustomers] = useState([
        {
            id: 0,
            name: '',
            phone: '',
            city: '',
            region: '',
            comment: '',
            orders: [{
                id: 0,
                summ: 0,
                date: ''
            }],
            created: {
                user: '',
                date: ''
            }
        }
    ]);

    const filteredCustomers = (search, customers) => {
        if (!search) {
            return customers
        }
        return customers.filter(customer => customer.name.toLowerCase().includes(search)||customer.phone.includes(search)||customer.city.toLowerCase().includes(search))
    }

    const [search, setSearch] = useState('')

    useEffect(() => {
        setIsLoading(true);
        axios.get(apiAddr + '/customers')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setCustomers(response.data['hydra:member'])
                    setIsLoading(false);
                    console.log(response.data)
                }
            });
    }, []);


    const [isLoading, setIsLoading] = useState(true);

    const TableHeader =
        <thead className='text-center'>
            <tr>
                <th>ФІО</th>
                <th>телефон</th>
                <th>місто</th>
                <th>регіон</th>
                <th>примітки</th>
                <th>#</th>
                <th>накладна</th>
                <th>створений</th>
            </tr>
        </thead>

    const CustomerRow = (customer, id) => {
        return (
            <tr key={id}>
                <td>
                    <a className='text-dark fw-bold' href={'/base/customer/' + customer.id}>
                    {customer.name}
                    </a>
                </td>
                <td className='text-center'>{customer.phone}</td>
                <td className='text-center'>{customer.city}</td>
                <td className='text-center'>{customer.region}</td>
                <td>{customer.comment}</td>
                <td className='text-center'>{customer.orders? customer.orders.length:0}</td>
                <td className='text-center'>{customer.orders.length && dateNorm(customer.orders[0].date) || '-'}</td>
                <td className='text-center'>{dateNorm(customer.created)}</td>

            </tr>
        )
    };
    const CustomerTable = filteredCustomers(search, customers).map((customer, id) => CustomerRow(customer, id))

    if (isLoading) {
        return (
            <Loader/>
        )
    }

    return (
        <Container>
            <Table>
                <Row>
                    <Col>
                        <Button
                            className="mt-2"
                            onClick={(e) =>window.location ='/base/customer/new'}
                            variant='outline-success'>
                            Новий
                        </Button>
                    </Col>
                    <Col>
                        <Form className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="шукати..."
                                className="me-2"
                                aria-label="Search"
                                onChange={(e) => {setSearch(e.target.value.toLowerCase())}}
                            />
                            <Button
                                variant="outline-success"
                            >Знайти</Button>
                        </Form>

                    </Col>
                </Row>
            </Table>

            <Table striped bordered hover responsive>
                {TableHeader}
                <tbody>
                    {CustomerTable}
                </tbody>
            </Table>

        </Container>
    )
};

export default CustomerList;
