import React from 'react';
import axios from "axios";
import {apiAddr} from "../../utils/api";

const UpdateState = (type, ordered) => {
    ordered.forEach(product => {
        axios.get(apiAddr + '/products/' + product.id)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    let Qtt = (type === 'get') ? response.data.quantity - product.ordered_quantity : response.data.quantity + product.ordered_quantity
                    console.log(response.data.quantity, type, product.ordered_quantity,  Qtt)
                    fetch(apiAddr + '/products/' + product.id, {
                        method: 'PUT',
                        body: JSON.stringify({
                            quantity: Qtt,
                        }),
                        headers: {'Content-Type': 'application/json',}
                    })
                        .catch((error) => {
                            console.log(error.message)
                        });
                }
            })
    })
};

export default UpdateState;
