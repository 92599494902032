import Container from "react-bootstrap/Container";
import {Col, Image, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TopBar = () => {
    return (
                <>
                        <Table className='w-100 ml-0 pl-0'>
                            <Row className='justify-content-left ml-0 pl-0'>
                                <Col sm='2' xs='4'>
                                    <a href='/' className='d-none d-sm-block'>
                                        <Image
                                            alt="Секонд-хенд оптом із Європи"
                                            src="/small_logo.jpeg"
                                            width="140"
                                            height="140"
                                            className="align-bottom"
                                        />
                                    </a>
                                    <a href='/' className='d-block d-sm-none'>
                                        <Image
                                            alt="Секонд-хенд оптом із Європи"
                                            src="/small_logo.jpeg"
                                            width="120"
                                            height="120"
                                            className="align-bottom"
                                        />
                                    </a>
                                </Col>
                                <Col sm='6' className=' mt-auto d-sm-block d-none mb-1 mb-sm-5 text-danger'>
                                    <h1>секонд-хенд оптом  з Європи</h1>
                                </Col>
                                <Col sm='4' xs='7' className='mb-1 mt-4 text-danger justify-content-right text-end'>
                                    <Col className='mt-1'>
                                        <a href="tel:+380994640004" className='text-danger h4'>
                                            <nobr>
                                                <FontAwesomeIcon className='text-danger fa-rotate-90' icon="fa-solid fa-phone"/>
                                                {' '}
                                                099-464-00-04
                                            </nobr>
                                        </a>
                                    </Col>
                                    <Col className='mt-1'>
                                        <a href="tel:+380674640404" className='text-danger h4'>
                                            <nobr>
                                                <FontAwesomeIcon className='text-danger fa-rotate-90' icon="fa-solid fa-phone" />
                                                {' '}
                                                067-464-04-04
                                            </nobr>
                                        </a>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className='d-inline d-sm-none px-3 mx-auto h1 text-danger text-center'>
                                секонд-хенд оптом
                            </Row>
                        </Table>
                </>
    )
};

export default TopBar;
