import React from "react";
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ExportToXLS = ({exportData, fileName}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const productsMod = [
        {
            'КОД': '',
            'НАЗВА': '',
            'КАТЕГОРІЯ': '',
            'ЦІНА': 0,
            'ЗАМОВЛЕННЯ': ''
        }
    ];

    const exportXLS = (exportData, fileName) => {

        const modifiedData =
            exportData.forEach(product => {
                productsMod.push(
                    {
                        'КОД': product.code,
                        'НАЗВА': product.name,
                        'КАТЕГОРІЯ': product.category,
                        'УПАКОВКА': product.tara+' '+product.unit,
                        'ЦІНА кг': '€'+(product.price_ko/100).toFixed(2),
                        'ЗАМОВЛЕННЯ': ''
                    }
                )
            })

        const ws = XLSX.utils.json_to_sheet(productsMod.slice(1));
        ws['!cols'] = [{ width: 25 }, { width: 40 }, { width: 15 }, { width: 10 }, { width: 10 }, { width: 15 }];
        const wb = {Sheets: {'data': ws}, SheetNames: ['data'], rowNames: false};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    return (
        <Button
            onClick={(e) => exportXLS(exportData, fileName)}
            className=''
            variant="outline-secondary"
            type="submit">
            <h3>
                <FontAwesomeIcon className='text-success' icon="fa-solid fa-table" />
                {' '}
                Скачати прайс-лист
            </h3>
        </Button>
    )
}