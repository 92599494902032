import {Badge, Nav, Navbar, NavDropdown} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {useEffect, useState} from "react";
import axios from "axios";
import {apiAddr} from "../../utils/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BaseNavbar = () => {
    const [notifications, setNotifications] = useState({
        name: '',
        phone: '',
        date: new Date(),
        notrobot: false,
    })
    const [user, setUser] = useState({
        user: '',
        role: ''
    });
    const [currate, setCurrate] = useState(0)
    const handleLogout = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('operate')
        localStorage.removeItem('token')
        setUser({
            user: '',
            role: ''
            })
    }
    useEffect(() => {
        axios.get(apiAddr + '/notifications')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    let nots = response.data['hydra:member']
                    let newNots = nots.filter(nots => nots.status === 'Новий')
                    setNotifications(newNots)
                }
            });
    }, []);

    useEffect(() => {
            axios.get(apiAddr + '/rates')
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        let lastElement = response.data['hydra:member'][0].rate;
                        setCurrate(lastElement / 100)
                    }
                });
        }
        , []);

    useEffect(() => {
        let currentUser = localStorage.getItem('user')
        let operate = localStorage.getItem('operate')
        setUser(
            {
                user: currentUser,
                role: operate,
            })
    }, [localStorage]);

    return (
        <>
            <Navbar bg="dark" sticky="top" variant="dark" className='w-100 text-center bg-dark bg-gradient'>
                <Container>
                    <Navbar.Toggle/>
                    <Navbar.Collapse>
                        <Nav className="m-auto">
                            <Nav.Link className='mx-md-3' href="/base/products"><h6>Товари</h6></Nav.Link>
                            <Nav.Link className='mx-md-3' href="/base/orders"><h6>Накладні</h6></Nav.Link>
                            <Nav.Link className='mx-md-3' href="/base/customers"><h6>Клієнти</h6></Nav.Link>
                            <Nav.Link className='mx-md-3' href="/base/notifications">
                                <h6>Запити
                                    {(notifications.length > 0) ? <Badge bg="warning" text="dark"><b>{notifications.length}</b></Badge>:''}
                                </h6>
                            </Nav.Link>
                            {(user.role==='ROLE_ADMIN')&&(<Nav.Link className='mx-md-3' href="/base/analytics"><h6>Аналітика</h6></Nav.Link>)}
                            {(user.role==='ROLE_ADMIN')&&(<NavDropdown title="Адмін" className='mx-md-3'>
                                <NavDropdown.Item className='text-dark' href="/base/rate/new">
                                    Оновити курс
                                </NavDropdown.Item>
                                <NavDropdown.Item className='text-dark' href="/base/product/new">
                                    Внести товар
                                </NavDropdown.Item>
                                <NavDropdown.Item className='text-dark' href="/base/products/admin">
                                    Товари
                                </NavDropdown.Item>
                                <NavDropdown.Item className='text-dark' href="/base/invoices">
                                    Приходи
                                </NavDropdown.Item>
                                <NavDropdown.Item className='text-dark' href="/base/check">
                                    Залишки
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item className='text-dark' href="/base/masssms">
                                    Розсилка
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item className='text-dark' href="/base/users">
                                    Менеджери
                                </NavDropdown.Item>
                            </NavDropdown>)}
                            <Nav.Link className='text-warning'>
                                {currate.toFixed(2)}
                            </Nav.Link>
                            {(user.user !== '') && (<Nav.Link className='mx-md-5'
                                                             onClick={handleLogout}>
                                <h6> {user.user}
                                    {' '}
                                    <FontAwesomeIcon icon="fa-solid fa-power-off" size='xl'/>
                                </h6>
                            </Nav.Link>)}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )

};

export default BaseNavbar;
