import React, {useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import BaseNavbar from "./BaseNavbar";
import Container from "react-bootstrap/Container";
import PushMessage from "../PushMessage";
import axios from "axios";
import {apiAddr} from "../../utils/api";
import {Loader} from "../Loader";

const MassSmsSend = () => {
    const [customers, setCustomers] = useState([
        {
            id: 0,
            name: '',
            phone: '',
            city: '',
            region: '',
            comment: '',
            orders: [],
            created: {
                user: '',
                date: ''
            }
        }
    ]);
    const [readyXML, setReadyXML] = useState('');
    const [creds, setCreds] = useState({
        login: '',
        password: ''
    });
    const [reccs, setReccs] = useState([]);
    const year = 31556952000;
    const [period, setPeriod] = useState(0);
    const [message, setMessage] = useState('На склад поступив товар. Замовляйте по тел: \n' +
        '0994640004 \n' +
        '0674640404');
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Sms відправлено',
        variant: 'light'
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        axios.get(apiAddr + '/customers')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setCustomers(response.data['hydra:member'])
                    setIsLoading(false);
                }
            });
    }, []);

    useEffect(() => {
        setIsLoading(true);
        axios.get(apiAddr + '/sms_cred')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setCreds(response.data)
                    setIsLoading(false);
                }
            });
    }, []);

    useEffect(() => {

        genXML()

    }, [period]);

    const handleChange = event => {
        const { value } = event.target;
        setMessage(value);
    };
    const handleChangePeriod = event => {
        const { value } = event.target;
        setPeriod(Number(value));
    };
    const handleCheck = () => {
        axios.get(apiAddr + '/sms_cred')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    console.log('check')
                    fetch('https://api.smscentre.com.ua/http/get_smslimit.php?login='
                        + response.data.login
                        + '&passwd='
                        + response.data.password
                        , {
                            method: 'GET',
                            headers: {
                                'Accept': '*/*'
                            },
                        })
                        .then((response) => response.text())
                        .then((response) => {
                            if (response.includes('RETURNCODE=00')) {
                                let smslimit = response.slice(24)
                                setToast({
                                    message: 'Кількість доступних смс : ' + smslimit + ' sms',
                                    variant: 'light'
                                })
                                setShow(true)
                            }
                        })
                        .catch(error => {
                            setToast({
                                message: error.message,
                                variant: 'danger'
                            })
                            setShow(true)
                        })
                }
            })
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        if (!submitted) {

            axios.post('https://api.smscentre.com.ua/xml/bulk_sms.php', readyXML)
                .then((response) => {
                    setSubmitted(true)
                   if (response) {
                       setToast({
                           message: 'Sms розсилання відправлено успішно.',
                           variant: 'light'
                       })
                       setShow(true)
                   }
                })
                .catch(error => {
                    setToast({
                        message: error.message,
                        variant: 'danger'
                    })
                    setShow(true)
                })
        }
    }
    const genXML = () => {
        const today = new Date()
        const Arr = []
        const customersOrd = customers.filter(customer => (customer.orders[0]))
        customersOrd.forEach(customer => {
            const along = today - new Date(customer.orders[0].date)
            if (along < period) {
                Arr.push(customer)
            }
        })
        setReccs(Arr)
        const msg = message.slice(0, 70)
        const builder = require('xmlbuilder');
        const root = builder.create('packet');
        root.att('version', '1.0');
        const auth = root.ele('auth');
        auth.att('login', creds.login);
        auth.att('password', creds.password);
        const command = root.ele('command');
        command.att('name', 'sendmessage');
        const bulk = command.ele('bulk');
        bulk.att('id', '');
        bulk.att('type', 'sms');
        bulk.att('name', '');
        bulk.att('alphaname', 'EuroMix');
        const data = bulk.ele('data', {'charset': 'cyr'}, msg);
        // data.att('charset', 'cyr');
        const recipients = bulk.ele('recipients');
        Arr.forEach(customer => {
            const item = recipients.ele('recipient');
            item.att('id', customer.id);
            item.att('address', customer.phone);

        })
        const xml = root.end({pretty: true});
        console.log(xml);
        setReadyXML(xml)

    }
    if (isLoading) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            <BaseNavbar/>
            <Container className='w-50 p-1 mt-4'>
                <h4>
                    Кількість отримувачів :
                    {' '}
                    {reccs.length}
                </h4>
                <InputGroup as={Row} className='mt-4'>
                    <Col sm="4" className='text-center'>
                        <InputGroup.Text>Період</InputGroup.Text>
                    </Col>
                    <Col sm="8">
                        <Form.Select
                            name='period'
                            onChange={handleChangePeriod}
                            type='number'
                        >
                            <option value='0'>...</option>
                            <option value={year}>рік</option>
                            <option value={year / 2}>півроку</option>
                            <option value={year / 4}>квартал</option>
                            <option value={year / 12}>місяць</option>
                        </Form.Select>
                    </Col>
                    {PushMessage({toast}, [show, setShow])}
                </InputGroup>
                <Form.Group as={Row} className='mt-3'>
                    <Col sm="12">
                        <Form.Control
                            name='message'
                            placeholder='текст повідомлення...'
                            value={message.slice(0, 70) || ''}
                            onChange={handleChange}
                            as="textarea"
                            rows='4'
                        />
                    </Col>
                </Form.Group>
                <Row className='text-center px-3'>
                    <Button
                        disabled={submitted}
                        onClick={event => {
                            handleSubmit(event)
                        }}
                        className='mt-3'
                        variant='outline-primary'>
                        ВІДПРАВИТИ SMS РОЗСИЛКУ
                    </Button>

                    <Button
                        onClick={handleCheck}
                        className='mt-3'
                        variant='outline-warning'>
                        ПЕРЕВІРИТИ ЗАЛИШОК
                    </Button>
                </Row>
            </Container>
        </>
    );
};

export default MassSmsSend;
