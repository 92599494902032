import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import axios from "axios";
import {Loader} from "../components/Loader";
import CardHeader from "react-bootstrap/CardHeader";
import BaseNavbar from "../components/Base/BaseNavbar";
import PushMessage from "../components/PushMessage";
import {apiAddr} from "../utils/api";

const NewRate = () => {
    const navigate = useNavigate();
    const [currate, setCurrate] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Новий курс встановлено',
        variant: 'light'
    });
    useEffect(() => {
        axios.get(apiAddr+'/rates')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    let lastElement = response.data['hydra:member'][0].rate;
                    setCurrate(lastElement / 100)
                }
            });
        setIsLoading(false);
    }, []);
    const handleChange = event => {
        const {value} = event.target;
        setCurrate(value);
    };
    const handleSubmit = () => {
        const values =
            {
                date: new Date(),
                rate: currate * 100
            }
        fetch(apiAddr+'/rates', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {'Content-Type': 'application/json',}
        })
            .then((response) => {
                if (response.status === 201) {
                    setToast({message: 'Новий курс встановлено', variant: 'light'})
                    setShow(true);
                }
            })
            .catch((error) => {
                setShow(true);
                setToast({message: error.message, variant: 'warning'})
            });
    }

    if (isLoading) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            <BaseNavbar/>
            <Container className='text-center mt-5'>
                <Card className='w-xs-50 w-md-25 w-75 m-auto'>
                    <CardHeader>
                        <h1>
                            Встановити новий курс
                        </h1>
                    </CardHeader>
                    <Form>
                        <Form.Group className="mb-3 mx-auto p-3">
                            <Form.Label>Новий курс</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>EUR</InputGroup.Text>
                                <Form.Control
                                    value={currate}
                                    onChange={handleChange}
                                    className="text-center px-3 mx-auto"
                                    id="newRate"
                                    placeholder="00.00"
                                />
                                <InputGroup.Text>ГРН</InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <Row className='text-center mb-3 '>
                            {PushMessage({toast}, [show,  setShow])}
                            <Col>
                                <Button
                                    className='mr-5'
                                    onClick={() => navigate(-1)}
                                    variant='outline-danger'>
                                    Відмінити
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className='ml-5'
                                    variant='outline-success'
                                    onClick={handleSubmit}
                                >Ввести</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Container>
        </>

    );
}

export default NewRate;
