import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import dateNorm from "../../utils/dateForm";
import {apiAddr} from "../../utils/api";
import BaseNavbar from "./BaseNavbar";

const Analytics = () => {

    const [users, setUsers] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [isBasic, setIsBasic] = useState(true);
    const [filters, setFilters] = useState(
        {
            startDate: dateNorm(new Date),
            endDate: dateNorm(new Date),
            user_name: 'Всі',
            payment: 'Всі'
        }
    )


    const CalculateQuantity = (prs) => {
        let Quantity = 0
        prs.forEach(pr => {
            Quantity += (pr.ordered_quantity ? pr.ordered_quantity : 0)
        })
        return Quantity
    }
    const CalculateSumm = (prs) => {
        let Summ = 0
        prs.forEach(pr => {
            Summ += Number(pr.summ ? pr.summ : 0)
        })
        return Summ
    }
    const CalculateProfit = (prs) => {
        let Summ = 0
        prs.forEach(pr => {
            Summ += Number(pr.summ - (pr.cost_brutto * pr.ordered_quantity))
        })
        return Summ
    }
    const handleFilters = event => {
        event.preventDefault()
        const {name, value} = event.target;
        setFilters({...filters, [name]: value});
        console.log(name,  value)
    };
    const compare = ( a, b ) => {
        if ( a.code < b.code ){
            return -1;
        }
        if ( a.code > b.code ){
            return 1;
        }
        return 0;
    }
    const cellStyled = (cd) => {
        if (cd === 'XXX' || cd === 'YYY' || cd === 'ZZZ') return 'text-secondary'
    }
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);



    useEffect(() => {
        setIsLoading(true)
        const Type = isBasic? '/orders' : '/invoices'
        axios.get(apiAddr + Type)
            .then((response) => {
                    if (response.status === 200 && response.data) {
                        setIsLoading(false)
                        // setFilters({...filters, payment: 'Всі'})

                        const filteredOrders =
                            response.data['hydra:member'].filter(order =>
                                ((filters.payment !== 'Всі') ? (order.payment === filters.payment) : true)
                                && ((filters.user_name !== 'Всі') ? (order.user_name === filters.user_name) : true)
                                && dateNorm(order.date) >= filters.startDate
                                && dateNorm(order.date) <= filters.endDate
                                && !order.is_deleted)

                        const Arr = []
                        filteredOrders.forEach(ord => {
                            ord.products.forEach(pr => {
                                if (Arr.findIndex(arrpr => arrpr.id === pr.id)!==-1) {
                                    let pid = Arr.findIndex(arrpr => arrpr.id === pr.id)
                                    Arr[pid].ordered_quantity+=pr.ordered_quantity
                                    Arr[pid].summ+=pr.summ
                                    Arr[pid].quantity = products.find(prs => prs.id === pr.id).quantity

                                } else {
                                    Arr.push({...pr, quantity: products.find(prs => prs.id === pr.id).quantity})
                                }
                            })
                        })
                        setFilteredProducts([...Arr])
                    }
                }
            )
    }, [filters, products, isBasic]);

    useEffect(() => {
        setIsLoading1(true)
        axios.get(apiAddr + '/users')
            .then((response) => {
                    if (response.status === 200 && response.data) {
                        setUsers(response.data['hydra:member'].filter(u => !u.is_deleted))
                        setIsLoading1(false)
                        setFilters({...filters, user_name: 'Всі'})
                    }
                }
            )
    }, []);

    useEffect(() => {
        setIsLoading2(true)
        axios.get(apiAddr + '/products')
            .then((response) => {
                    if (response.status === 200 && response.data) {
                        setProducts(response.data['hydra:member'])
                        setIsLoading2(false)
                    }
                }
            )
    }, []);

    const ProductRow = (product, id) => {
        return (
            <tr key={id}>
                <td className={cellStyled(product.code)}>
                    {product.code}
                </td>
                <td className={cellStyled(product.code)}>
                    {product.name}
                </td>
                <td className={cellStyled(product.code)+' text-center fw-bold'}>
                    {product.ordered_quantity}
                </td>
                <td className='text-center'>
                    {product.quantity}
                </td>
                <td className='text-center'>
                    {(product.summ / 100).toFixed(2)}
                </td>
                {isBasic && <td className='text-center'>
                    {((product.summ - product.cost_brutto * product.ordered_quantity) / 100).toFixed(2)}
                </td>}

            </tr>
        )
    };
    const ProductTable = filteredProducts.sort(compare).map((product, id) => ProductRow(product, id))
    const TableHeader =
        <thead>
            <tr className='text-center'>
                <th>КОД</th>
                <th>НАЗВА</th>
                <th>КІЛЬКІСТЬ</th>
                <th>СКЛАД</th>
                <th>СУМА</th>
                {isBasic && <th>МАРЖА</th>}
            </tr>
        </thead>

    const Filters =
        <Form.Group as={Row} className='my-2'>
            <Col sm="2">
                {isBasic ? <Button
                    variant="outline-success"
                    onClick={() => setIsBasic(!isBasic)}>
                    Продаж
                </Button> : <Button
                    variant="outline-warning"
                    onClick={() => setIsBasic(!isBasic)}>
                    Приходи
                </Button>}
            </Col>
            з
            <Col sm="2">
                <Form.Control
                    name='startDate'
                    value={filters.startDate}
                    onChange={handleFilters}
                    type="date"/>
            </Col>
            по
            <Col sm="2">
                <Form.Control
                    name='endDate'
                    value={filters.endDate}
                    onChange={handleFilters}
                    type="date"/>
            </Col>
            форма
            <Col sm="2">
                <Form.Select
                    name='payment'
                    value={filters.payment}
                    onChange={handleFilters}
                    type="text">
                    <option value='Всі'>Всі</option>
                    <option value="Готівка">Готівка</option>
                    <option value="Безготівка">Безготівка</option>
                    <option value="Післяплата">Післяплата</option>
                </Form.Select>
            </Col>
            оформив
            <Col sm="2">
                <Form.Select
                    name='user_name'
                    value={filters.user_name}
                    onChange={handleFilters}
                    type="text">
                    <option value='Всі'>Всі</option>
                    {(users.length > 0) ? (users.map((us, id) => (
                                <option key={id} value={us.name}>
                                    {us.name}
                                </option>
                            )
                        ))
                        : ''}
                </Form.Select>
            </Col>
        </Form.Group>

    if (isLoading && isLoading1 && isLoading2) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            <BaseNavbar/>
            <Container>
                {Filters}

                <Table striped bordered hover responsive size='sm'>
                    {TableHeader}
                    <tbody>
                        {filteredProducts.length>0 && ProductTable}
                        <tr className='text-center'>
                            <td></td>
                            <td>Підсумок</td>
                            <td>{CalculateQuantity(filteredProducts.filter(ppp=>
                                ppp.code!=='XXX'&&
                                ppp.code!=='ZZZ'&&
                                ppp.code!=='YYY'
                            ))} кг</td>
                            <td></td>
                            <td>€{(CalculateSumm(filteredProducts) / 100).toFixed(2)}</td>
                            {isBasic && <td>€{(CalculateProfit(filteredProducts) / 100).toFixed(2)}</td>}
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </>
    )
};

export default Analytics;
