import React from 'react';

const OrderStatuses = [
    {
        id: 1,
        name: "Новий"
    },
    {
        id: 2,
        name: "Оплачений"
    },
    {
        id: 3,
        name: "Відправлено"
    }
]

export { OrderStatuses };

const InvoiceStatuses = [
    {
        id: 1,
        name: "Новий"
    },
    {
        id: 2,
        name: "Прийнято"
    }
]

export { InvoiceStatuses };

