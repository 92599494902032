import React, {useEffect, useState} from 'react';
import BaseNavbar from "./BaseNavbar";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import PushMessage from "../PushMessage";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {apiAddr} from "../../utils/api";
import {Loader} from "../Loader";
import dateNorm from "../../utils/dateForm";
import UpdateState from "./UpdateState";

const EditCustomer = () => {
    const {id} = useParams();
    const [customer, setCustomer] = useState(
        {
            id: 0,
            name: '',
            comment: '',
            phone: '',
            email: '',
            viber: '',
            phone2: '',
            region: '',
            city: '',
            address: '',
            delivery: '',
            orders: [],
            user: 0,
            created: new Date(),
            is_deleted: false,
            is_active: true,
            generation: 2
        }
    );
    const [orders, setOrders] = useState([
        {
            id: 0,
            code: '',
            date: new Date(),
            status: '',
            payment: '',
            delivery: '',
            comment: '',
            customer: null,
            customer_name: null,
            created: {},
            updated: {},
            products: [],
            uah: 0,
            eur: 0,
            usd: 0,
            quantity: 0,
            summ: 0,
            user: null,
            user_name: null,
            is_deleted: false
        }
    ])
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Дані клієнта оновлено',
        variant: 'light'
    });

    useEffect(() => {
            axios.get(apiAddr + '/customers/' + id)
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        setCustomer(response.data);
                        setIsLoading(false);
                    }
                });
        }
        , []);
    useEffect(() => {
        setIsLoading(true)
        axios.get(apiAddr + '/orders')
            .then((response) => {
                    if (response.status === 200 && response.data) {
                        setOrders(response.data['hydra:member'])
                        setIsLoading(false)
                    }
                }
            )
    }, []);
    const filteredOrders =
        orders.filter(order =>
            order.customer === customer.id
            && !order.is_deleted)

    const handleChange = event => {
        const {name, value} = event.target;
        setCustomer({...customer, [name]: value});
        console.log(name + ' : ' + value)
    };
    const handleCheck = event => {
        const {name} = event.target;
        let val = !customer[name]
        setCustomer({...customer, [name]: val});
        console.log(name + ' : ' + val)
    };
    const handleSubmit = () => {
        const values =
            {
                name: customer.name,
                comment: customer.comment,
                phone: customer.phone,
                email: customer.email,
                viber: customer.viber,
                phone2: customer.phone2,
                region: customer.region,
                city: customer.city,
                address: customer.address,
                delivery: customer.delivery,
                // orders: customer.orders,
                user: customer.user,
                created: customer.created,
                isDeleted: customer.is_deleted,
                isActive: customer.is_active,
                generation: 2
            }

        if (customer.phone.length!==13 || customer.phone.slice(0, 1)!=='+') {
            setToast({message: 'Введіть номер телефону в вірному форматі', variant: 'warning'})
            setShow(true)
        } else {
            fetch(apiAddr + '/customers/' + id, {
                method: 'PUT',
                body: JSON.stringify(values),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response)
                        setShow(true);
                    }
                })
                .catch((error) => {
                    setShow(true);
                    setToast({message: error, variant: 'warning'})
                    console.log(error)
                });
        }
    }

    const CalculateQuantity = (ords) => {
        let Quantity = 0
        ords.forEach(order => {
            Quantity = Quantity + (order.quantity ? order.quantity : 0)
        })
        return Quantity
    }
    const CalculateSumm = (ords) => {
        let Summ = 0
        ords.forEach(ord => {
            Summ = Summ + Number(ord.summ ? ord.summ : 0)
        })
        return Summ
    }

    const OrderRow = (order, id) => {
        return (
            <tr key={id}>
                <td>
                    <a className='text-dark fw-bold' href={'/base/order/' + order.id}>
                        <small>{order.code === '' ? ((36000 + order.id) + '-' + dateNorm(order.created)
                        ) : order.code}</small>
                    </a>
                </td>
                <td><small>{order.date
                    .toString()
                    .slice(0, 10)
                    .split('-').reverse().join('-')
                }</small></td>
                <td><b>{order.user_name ? order.user_name.slice(0, 1) : ''}</b></td>
                <td className='text-center'>{
                    order.uah ? order.uah + ' грн' : ''}
                    {order.usd ? ' $' + order.usd : ''}
                    {order.eur ? ' €' + order.eur : ''
                    }</td>
                <td className='text-center'><b>{order.payment.slice(0, 1)}</b></td>
                <td>{order.customer_name}</td>
                <td>
                    {order.status}
                </td>
                <td className='text-center'>{order.delivery}</td>
                <td>{order.comment}</td>
                <td className='text-center'>{order.quantity || ''}</td>
                <td className='text-center'>{(order.summ/100).toFixed(2) || ''}</td>
            </tr>
        )
    };
    const OrderTable = filteredOrders.map((order, id) => OrderRow(order, id))
    const TableHeader =
        <thead>
            <tr className='text-center'>
                <th>КОД</th>
                <th>ДАТА</th>
                <th colSpan={3}>ОПЛАТА</th>
                <th>ЗАМОВНИК</th>
                <th>СТАТУС</th>
                <th>ДОСТАВКА</th>
                <th>ПРИМ</th>
                <th>КГ</th>
                <th>СУМА</th>
            </tr>
        </thead>

    if (isLoading) {
        return (<Loader/>)
    }

    return (
        <>
            <BaseNavbar/>
            <Container>
                {PushMessage({toast}, [show, setShow])}
                <Form.Group as={Row} className='mt-2'>
                    <Form.Group as={Col} className='mt-3'>
                        <Form.Group as={Row} className='mt-4  text-danger'>
                            <Form.Label column sm="2">Імʼя</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='name'
                                    value={customer.name || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4  text-danger'>
                            <Form.Label column sm="2">Телефон</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='phone'
                                    value={customer.phone || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4  text-danger'>
                            <Form.Label column sm="2">Область</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='region'
                                    value={customer.region || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4 text-danger'>
                            <Form.Label column sm="2">Місто</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='city'
                                    value={customer.city || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Адреса</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='address'
                                    value={customer.address || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-3'>
                            <Form.Group as={Col} className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    label="активний"
                                    name='is_active'
                                    onChange={handleCheck}
                                    checked={customer.is_active}
                                />
                            </Form.Group>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group as={Col} className='mt-3'>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='email'
                                    value={customer.email || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Viber</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='viber'
                                    value={customer.viber || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Телефон2</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='phone2'
                                    value={customer.phone2 || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Доставка</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='delivery'
                                    value={customer.delivery || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mt-4'>
                            <Form.Label column sm="2">Примітки</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name='comment'
                                    value={customer.comment || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Container className='text-center'>
                            <Form.Group as={Row} className='mt-4 text-center'>
                                <Col>
                                    <Button
                                        className='mx-auto'
                                        onClick={() => navigate(-1)}
                                        variant='outline-danger'>
                                        Назад
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className='mx-auto'
                                        onClick={handleSubmit}
                                        variant="outline-success">
                                        ВПЕРЕД
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Container>

                    </Form.Group>
                </Form.Group>
            </Container>
            <Container className='bg-light'>
                <br/>
                <Table striped bordered hover responsive size='sm'>
                    {TableHeader}
                    <tbody>
                        {OrderTable}
                        <tr className='text-center'>
                            <td colSpan={8}>
                            </td>
                            <td>Підсумок</td>
                            <td>{CalculateQuantity(filteredOrders)} кг</td>
                            <td>€{(CalculateSumm(filteredOrders)/100).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </Table>
            </Container>

        </>
    );
};

export default EditCustomer;
