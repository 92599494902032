import React from 'react';
import {Toast, ToastContainer} from "react-bootstrap";

const PushMessage = ({toast}, [show, setShow]) => {
    return (
        <ToastContainer className='w-100'>
            <Toast
                bg={toast.variant}
                onClose={() => setShow(false)}
                show={show}
                delay={4000}
                autohide
                className='mx-auto'
            >
                <Toast.Header>
                    <strong className="me-auto">Адміністрування бази</strong>
                    <small>1 сек. тому</small>
                </Toast.Header>
                <Toast.Body>
                    <h4 className=' p-3 text-secondary'>
                        {toast.message}
                    </h4>
                </Toast.Body>
            </Toast>
        </ToastContainer>

    );
}

export default PushMessage;
