import React from "react";
import Container from "react-bootstrap/Container";
import {Button, Card, Col, Row} from "react-bootstrap";
import Articles from "./ArticlesText";

const ArticleList = () => {
    const ArticleCard = () => {
        return (
            Articles.map(article =>
                <Col  key={article.id} >
                    <Card className='text-center text-secondary m-1 p-0' >
                        <Card.Body  style={{ height: '24rem' }}>
                            <Card.Title>
                                <h3 className='text-dark'>
                                    {article.title}
                                </h3>
                            </Card.Title>
                            <Card.Text>
                                {article.content[0].text.slice(0, 300)}...
                            </Card.Text>
                        </Card.Body>
                        <div>
                            <Button
                                onClick={event => window.location.href='/articles/'+article.slug}
                                className='mx-2 mb-2 '
                                variant="outline-danger">Читати</Button>
                        </div>

                    </Card>
                </Col>

            )

        )
    };

    return (
        <Container className='my-4 w-100'>
            <Row xs={1} sm={3} className="g-4">

                <ArticleCard/>

            </Row>
        </Container>
    )
};

export default ArticleList;