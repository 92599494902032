import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import ReactToPrint from "react-to-print";
import axios from "axios";
import {Loader} from "../Loader";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Table} from "react-bootstrap";
import dateNorm from "../../utils/dateForm";
import {apiAddr} from "../../utils/api";

const PrintOrder = () => {
        const {id} = useParams();
        const componentRef = useRef();
        const navigate = useNavigate();
        const [order, setOrder] = useState({})
        const [customer, setCustomer] = useState({})
        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
                // TODO ch API url to vars for prod
                axios.get(apiAddr + '/orders/' + id)
                    .then((response) => {
                        if (response.status === 200 && response.data) {
                            setOrder(response.data)
                            console.log(response.data)
                            axios.get(apiAddr + '/customers/' + response.data.customer)
                                .then((response) => {
                                    if (response.status === 200 && response.data) {
                                        setCustomer(response.data)
                                        setIsLoading(false);
                                    }
                                })
                        }
                    })
            }
            , []);

        class ComponentToPrint extends React.PureComponent {
            render() {
                return (
                    <>
                        <Table
                            borderless
                            size="sm"
                            style={{fontSize: 12}}
                            className='mt-5 mx-4 fw-200'>
                            <tbody>
                                <tr className='w-100' >
                                    <td className='text-secondary'>
                                        накладна №
                                    </td>
                                    <td colSpan={2}>
                                        {order.code}
                                    </td>
                                    <td className='text-secondary'>
                                        отримувач
                                    </td>
                                    <td colSpan={3} className='fw-bold'>
                                        {customer.name}
                                    </td>
                                </tr>
                                <tr className='w-100 no-border'>
                                    <td className='text-secondary'>
                                        дата
                                    </td>
                                    <td colSpan={2}>
                                        {dateNorm(order.date)}
                                    </td>
                                    <td className='text-secondary'>
                                        телефон
                                    </td>
                                    <td colSpan={3}>
                                        {customer.phone}
                                    </td>
                                </tr>
                                <tr className='w-100'>
                                    <td className='text-secondary'>
                                        статус
                                    </td>
                                    <td colSpan={2}>
                                        {order.status}
                                    </td>
                                    <td className='text-secondary'>
                                        регіон
                                    </td>
                                    <td colSpan={3}>
                                        {customer.region}
                                    </td>
                                </tr>
                                <tr className='w-100'>
                                    <td className='text-secondary'>
                                        форма оплати
                                    </td>
                                    <td colSpan={2}>
                                        {order.payment}
                                    </td>
                                    <td className='text-secondary'>
                                        місто
                                    </td>
                                    <td colSpan={3}>
                                        {customer.city}
                                    </td>
                                </tr>
                                <tr className='w-100'>
                                    <td className='text-secondary'>
                                        доставка
                                    </td>
                                    <td colSpan={2}>
                                        {order.delivery}
                                    </td>
                                    <td className='text-secondary'>
                                        адреса
                                    </td>
                                    <td colSpan={3}>
                                        {customer.address}
                                    </td>
                                </tr>
                                <tr className='w-100'>
                                    <td className='text-secondary'>
                                        оплата
                                    </td>
                                    <td colSpan={2}>
                                        {(order.uah !== 0) ? order.uah + ' грн' : ''}
                                        {(order.eur !== 0) ? order.eur + ' євр' : ''}
                                        {(order.usd !== 0) ? order.usd + ' дол' : ''}
                                    </td>
                                    <td className='text-secondary'>
                                        примітки
                                    </td>
                                    <td colSpan={3}>
                                        {order.comment}
                                    </td>
                                </tr>
                                <tr>
                                    <td/>
                                </tr>
                                <tr className='bg-light rounded text-secondary text-center'>
                                    <td>
                                        код
                                    </td>
                                    <td>
                                        назва
                                    </td>
                                    <td>
                                        ціна
                                    </td>
                                    <td>
                                        од.
                                    </td>
                                    <td>
                                        сума
                                    </td>
                                    <td>
                                        контроль
                                    </td>
                                </tr>
                                {order.products.map(pr =>
                                    <tr key={pr.id} className="border-bottom">
                                        <td className='text-left'>
                                            &#9744;
                                            {' '}
                                            {pr.code}
                                        </td>
                                        <td>
                                            {pr.name}
                                        </td>
                                        <td className='text-center'>
                                            {(pr.price / 100).toFixed(2)}
                                        </td>
                                        <td className='text-center'>
                                            {pr.ordered_quantity}
                                            {' '}
                                            {pr.unit}
                                        </td>
                                        <td className='text-center'>
                                            {(pr.summ / 100).toFixed(2)}
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                )}
                                <tr className='text-center bg-light'>
                                    <td>
                                    </td>
                                    <td>
                                        підсумок
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        {order.quantity}
                                    </td>
                                    <td>
                                        {(order.summ / 100).toFixed(2)}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            </tbody>

                        </Table>
                        <Table
                            borderless
                            size="sm"
                            style={{fontSize: 12}}
                            className='mt-5 mx-4 fw-200'>
                            <tbody>

                            </tbody>
                        </Table>
                    </>
                );
            }
        }

        if (isLoading) {
            return (
                <Loader/>
            )
        }

        return (
            <Container className="container-fluid">

                <div>
                    <ComponentToPrint ref={componentRef}/>

                    <ReactToPrint
                        trigger={() =>
                            <div className='text-center'>
                                <Button
                                    className='mx-5'
                                    onClick={() => navigate(-1)}
                                    variant='outline-danger'>
                                    Назад
                                </Button>
                                <Button className='btn-secondary mx-5'>Друк</Button>
                            </div>
                        }
                        content={() => componentRef.current}
                    />

                </div>

            </Container>
        );
    }
;
export default PrintOrder;