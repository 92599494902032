import BaseNavbar from "../components/Base/BaseNavbar";
import OrderList from "../components/Base/OrderList";

const Orders = () => {
    return(
            <>
                <BaseNavbar/>

                <OrderList/>
            </>
        )
};

export default Orders;
