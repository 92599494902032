import Container from "react-bootstrap/Container";
import { Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Payment = () => {
    return (
            <Container id='payment' className='text-center px-0 my-4'>
                <h2 className='text-secondary'>
                    Умови оплати
                </h2>

                <br/>
                <h5 className='text-secondary'>
                    Оплата за товар проводиться в гривнях за комерційним курсом банку на момент розрахунку. Доступні форми оплати:
                </h5>

                <Row className='my-4'>


                    <Col>
                        <FontAwesomeIcon className='text-danger' icon="fa-solid fa-coins" size='2xl'/>
                        <h3>
                            Готівка
                        </h3>
                        <p>
                           При покупці товару на нашому складі або доставці нашим власним транспортом
                        </p>
                    </Col>
                    <Col>
                        <FontAwesomeIcon className='text-danger' icon="fa-solid fa-credit-card" size='2xl'/>
                        <h3>
                            Безготівка
                        </h3>
                        <p>
                            На банківський рахунок при оформленні накладної за виставленим рахунком
                        </p>
                    </Col>
                    <Col>
                        <FontAwesomeIcon className='text-danger' icon="fa-solid fa-money-check" size='2xl'/>
                        <h3>
                            Післяплата
                        </h3>
                        <p>
                            У відділенні служби доставки при отриманні замовлення або курʼєру.
                        </p>
                    </Col>
                </Row>


                <p>
                    Відправка товару здійснюється за передоплатою або післяплатою.
                    Мінімальна партія замовлення для відправки - 30 кг.
                </p>
                <p>
                    Оплата здійснюється в гривнях на рахунок продавця за курсом на день оплати і згідно виставленого рахунку.
                </p>

                <hr/>
            </Container>
    )
};
export default Payment;
