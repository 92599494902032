import {Badge, Col, Row, Table} from "react-bootstrap";
import Container from "react-bootstrap/Container";
// import "bootstrap/dist/css/bootstrap.min.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare} from '@fortawesome/fontawesome-free-solid'

const OurScores = () => {
    return (
        <Container className='text-center'>
            <Table>
                <Row className='text-center text-secondary'>
                    <Col className='col-sm-4 col-6'>
                        <h2>
                            <Badge pill className=' bg-danger bg-gradient mt-3 p-4'>
                                <b className='my-3 py-3 mx-2'>5</b>
                            </Badge>
                        </h2>
                        <h3>
                            тисяч партнерів
                        </h3>
                    </Col>
                    <Col className='col-sm-4 col-6'>
                        <h2>
                            <Badge pill className='bg-danger bg-gradient mt-3 p-4'>
                                <b className='my-3 py-3'>{new Date().getFullYear() - 2008}</b>
                            </Badge>
                        </h2>
                        <h3>
                            років на ринку
                        </h3>
                    </Col>
                    <Col className='d-none d-sm-block'>
                        <h2>
                            <Badge pill className='bg-danger bg-gradient mt-3 p-4'>
                                <b className='my-3 py-3 mx-2'>2</b>
                            </Badge>
                        </h2>
                        <h3>
                            дні доставка з Європи
                        </h3>
                    </Col>
                </Row>
                <Row className='text-secondary mt-4'>
                    <Col className='col col-12 col-md-4 col-sm-6'>
                        <Row>
                            <Col className='col-2 mx-0 px-0'>
                                <FontAwesomeIcon icon={faCheckSquare} size='3x' className='text-danger'/>
                            </Col>
                            <Col className='mx-0 px-0 text-center'>
                                <h6>
                                    Наша головна мета - бути надійним постачальником для наших партнерів
                                </h6>
                            </Col>
                        </Row>
                        <p>
                            Ми завжди допоможемо найбільш ефективно підібрати оптимальний асортимент, що задовільнить потреби Ваших покупців.
                            Крім того, ми готові надати допомогу тим, хто тільки починає цей бізнес.
                        </p>
                    </Col>
                    <Col className='col col-12 col-md-4 col-sm-6'>
                        <Row>
                            <Col className='col-2 mx-0 px-0'>
                                <FontAwesomeIcon icon={faCheckSquare} size='3x' className='text-danger'/>
                            </Col>
                            <Col className='mx-0 px-0 text-center'>
                                <h6>
                                    Ми працюємо тільки з перевіреними часом та надійними постачальниками секондхенду
                                </h6>
                            </Col>
                        </Row>
                        <p>
                            Перевірена якість та наявний на нашому складі асортимент товару дозволить максимально заповнити Ваш магазин секондхенду і задовільнити потреби покупців.
                        </p>
                    </Col>
                    <Col className='col col-12 col-md-4 col-sm-6'>
                        <Row>
                            <Col className='col-2 mx-0 px-0'>
                                <FontAwesomeIcon icon={faCheckSquare} size='3x' className='text-danger'/>
                            </Col>
                            <Col className='mx-0 px-0 text-center'>
                                <h6>
                                    Cклад в Ужгороді дозволяє швидко та часто доставляти товари
                                </h6>
                            </Col>
                        </Row>
                        <p>
                            Ми підтримуємо постійні та тісні контакти з постачальниками секондхенду в Європі.
                            Наш представник завжди присутній під час завантаження на складах постачальників в будь-якій країні Європи.
                        </p>
                    </Col>
                </Row>
                <Row className='text-secondary text-center mt-4 mb-4'>
                    <Col className='col col-12 col-md-4 col-sm-6'>
                        <Row>
                            <Col className='col-2 mx-0 px-0'>
                                <FontAwesomeIcon icon={faCheckSquare} size='3x' className='text-danger'/>
                            </Col>
                            <Col className='mx-0 px-0 text-center'>
                                <h6>
                                    Ми поставляємо тільки якісний сортований секонд-хенд
                                </h6>
                            </Col>
                        </Row>
                        <p>
                            Власна система контролю якості та робота із перевіреними постачальниками дозволяють тримати стабільну якість товару.
                            Тільки так ми можемо гарантувати, що покупець отримає той товар, за який заплатив.
                        </p>
                    </Col>
                    <Col className='col col-12 col-md-4 col-sm-6'>
                        <Row>
                            <Col className='col-2 mx-0 px-0'>
                                <FontAwesomeIcon icon={faCheckSquare} size='3x' className='text-danger'/>
                            </Col>
                            <Col className='mx-0 px-0 text-center'>
                                <h6>
                                    Швидка та безкоштовна доставка по Україні
                                </h6>
                            </Col>
                        </Row>
                        <p>
                            Ми розуміємо, що відстань між нами та нашими партнерами можуть ускладнити роботу.
                            Тому ми беремо на себе організацію та оплату доставки.
                            Ви ж можете присвятити свій час вашим покупцям.
                        </p>
                    </Col>
                    <Col className='col col-12 col-md-4 col-sm-6'>
                        <Row>
                            <Col className='col-2 mx-0 px-0'>
                                <FontAwesomeIcon icon={faCheckSquare} size='3x' className='text-danger'/>
                            </Col>
                            <Col className='mx-0 px-0 text-center'>
                                <h6>
                                    Гуртовий продаж секонд-хенду - наша єдина і любима справа
                                </h6>
                            </Col>
                        </Row>
                        <p>
                            Наш склад багато років спеціалізується тільки на гуртових поставках секонд-хенду.
                            <br/>
                            Не відволікаючись на інші справи, такі як роздріб, пересортування та інше, ми можемо якісно робити свою роботу
                        </p>
                    </Col>
                </Row>
            </Table>
            <hr/>
        </Container>
    )

};

export default OurScores;