import Container from "react-bootstrap/Container";
import {Button} from "react-bootstrap";

const NoPage = () => {
    return (
        <Container className='w-100 h-100 text-center my-5'>
            <h2 className='text-danger fw-bold'>
                Упс, щось пішло не так
            </h2>
            <br/>
            <h1>404</h1>
            <br/>
            <h2>
                Сторінку не знайдено
            </h2>
            <br/>
            <h3>
                Повернутися на головну
            </h3>
            <br/>
            <Button href='/' variant='danger'>
                НА ГОЛОВНУ
            </Button>
        </Container>
    );
};

export default NoPage;