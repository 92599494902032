import Container from "react-bootstrap/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Row} from "react-bootstrap";

const ContactUs = () => {
    return (
            <Container name='feedbacks' className='text-center px-0 mb-3'>
                <h2 className='text-secondary'>
                    Хочете дізнатися більше?
                </h2>

                <br/>
                <h5 className='text-secondary'>
                    Наш гуртовий склад знаходиться в Ужгороді.
                    Для початку успішної співпраці ми наполегливо рекомендуємо Вам хоча б раз відвідати нас, щоб оцінити асортимент і якість пропонованого нами товару.
                    Надалі процес замовлення і відправлення товару може здійснюватися дистанційно. Ви можете зателефонувати нам або заповнити форму нижче і ми зателефонуємо Вам.
                </h5>
                <Row className='my-4'>
                    <Col>
                        <FontAwesomeIcon className='text-danger fa-rotate-90' icon="fa-solid fa-phone" size='xl'/>
                        {' '}
                        <a href="tel:+380994640004" className='text-danger h3'>099-464-00-04</a>
                    </Col>
                    <Col>
                        <FontAwesomeIcon className='text-danger fa-rotate-90' icon="fa-solid fa-phone" size='xl'/>
                        {' '}
                        <a href="tel:+380674640404" className='text-danger h3'>067-464-04-04</a>
                    </Col>
                </Row>
            </Container>
    )
};
export default ContactUs;
