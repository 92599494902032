import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Button, Col, Form, InputGroup, Row, Table} from "react-bootstrap";
import PushMessage from "../PushMessage";
import dateForm from "../../utils/dateForm";
import {apiAddr} from "../../utils/api";
import BaseNavbar from "./BaseNavbar";
import dateNorm from "../../utils/dateForm";
import {InvoiceStatuses} from "../../utils/library";
import UpdateState from "./UpdateState";

const NewInvoice = () => {

    const [products, setProducts] = useState([
        {
            id: '',
            code: '',
            name: '',
            category: '',
            cost_netto: '',
            cost_brutto: '',
            price_ko: '',
            price_mo: '',
            comment: '',
            tara: 0,
            unit: '',
            quantity: 0,
            ordered: false,
            ordered_quantity: 0,
            summ: 0
        }
    ]);

    const [done, setDone] = useState(false)
    const [hideUnordered, setHideUnordered] = useState(false)

    const toggleHideUnordered = () => {
        setHideUnordered(!hideUnordered)
    }
    const centsToEur = (eur) => {
        return eur / 100
    }
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Нове замовлення створено',
        variant: 'light'
    });
    const [order, setOrder] = useState({
        id: null,
        name: null,
        seller: '',
        date: new Date(),
        status: 'Новий',
        comment: '',
        quantity: 0,
        summ: 0,
        isDeleted: false,
    })

    const [isFetching, setIsFetching] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const cellStyled = (q) => {
        if (q < 0) return 'text-danger text-center'
        return 'text-black text-center'
    }

    useEffect(() => {
            setIsLoading(true);
            axios.get(apiAddr + '/products')
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        setProducts(upProducts(response.data['hydra:member']))
                        setIsLoading(false);
                    }
                })
        }
        , []);

    const upProducts = (prods) => {
        prods.forEach(function (element) {
            element.ordered = false;
            element.ordered_quantity = 0;
            element.summ = 0;
        });
        return (prods)
    }

    const CalculateQuantity = () => {
        let Quantity = 0
        products.forEach(product => {
            Quantity = Quantity + product.ordered_quantity
        })
        return Quantity
    }
    const CalculateSumm = () => {
        let Summ = 0
        products.forEach(product => {
            Summ = Summ + Number(product.summ)
        })
        return Math.round(Summ*100)
    }
    const setOrderName = () => {
        if (order.name === null) {
            return (1100 + order.id) + '-' + dateNorm(order.date)
        } else {
            return order.name
        }
    }

    const handleChange = event => {
        const {name, value} = event.target;
        setOrder({...order, [name]: value});
    };


    const handleAddProduct = (event) => {
        const {name, value} = event.target;
        const Arr = [...products]
        let PV = Arr[name].ordered_quantity
        Arr[name].ordered_quantity = Number(value);
        Arr[name].ordered_quantity === 0 ? Arr[name].ordered = false : Arr[name].ordered = true;
        Arr[name].summ = Arr[name].ordered_quantity * centsToEur(Arr[name].cost_netto);
        Arr[name].quantity = Arr[name].quantity - PV + Number(value);
        setProducts(Arr);
    };

    const incrOrder = (event) => {
        const {name, value} = event.target;
        const Arr = [...products]
        Arr[name].ordered_quantity = Arr[name].ordered_quantity + Arr[name].tara;
        Arr[name].ordered_quantity === 0 ? Arr[name].ordered = false : Arr[name].ordered = true;
        Arr[name].summ = Arr[name].ordered_quantity * centsToEur(Arr[name].cost_netto);
        Arr[name].quantity = Arr[name].quantity + Arr[name].tara;
        setProducts(Arr);
    };
    const decrOrder = (event) => {
        const {name, value} = event.target;
        const Arr = [...products]
        Arr[name].ordered_quantity = Arr[name].ordered_quantity - Arr[name].tara;
        Arr[name].ordered_quantity === 0 ? Arr[name].ordered = false : Arr[name].ordered = true;
        Arr[name].summ = Arr[name].ordered_quantity * centsToEur(Arr[name].cost_netto);
        Arr[name].quantity = Arr[name].quantity - Arr[name].tara;
        setProducts(Arr);
    };
    const handleSubmit = () => {
        setIsFetching(true)
        const ordprod = products.filter(product => product.ordered === true);
        const p = []
        ordprod.forEach(o => {
            p.push({
                id: o.id,
                code: o.code,
                name: o.name,
                cost: o.cost_netto,
                ordered_quantity: o.ordered_quantity,
                summ: 100 * o.summ
            })
        })
        const values =
            {
                name: '',
                comment: order.comment,
                seller: order.seller,
                status: order.status,
                created: dateNorm(new Date()),
                date: order.date,
                isDeleted: false,
                products: p,
                quantity: CalculateQuantity(),
                summ: CalculateSumm()
            }
            console.log(values)
        fetch(apiAddr + '/invoices', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {'Content-Type': 'application/json',}
        })
            .then((response) => {
                if (response.status === 201 && response) {
                    UpdateState('add', p)
                    setToast({message: 'Накладну збережено', variant: 'light'})
                    setShow(true);
                }
            })
            .catch((error) => {
                setToast({message: error.message, variant: 'warning'})
                setShow(true);
            });
        setDone(true)
    }

    const Order = (id) => {
        return (
            <InputGroup className="">
                <Button
                    name={id}
                    variant="outline-secondary"
                    value={products[id].ordered_quantity || ''}
                    onClick={incrOrder}
                >
                    +
                </Button>
                <Form.Control
                    name={id}
                    type="number"
                    value={products[id].ordered_quantity || ''}
                    onChange={handleAddProduct}
                />
                <Button
                    name={id}
                    variant="outline-secondary"
                    value={products[id].ordered_quantity || ''}
                    onClick={decrOrder}
                >
                    -
                </Button>
            </InputGroup>
        )
    }

    const ProductRow = (product, id) => {
        return (
            <tr key={id} className={(hideUnordered) && (product.ordered !== true) ? 'd-none' : ''}>
                <td><a className='text-dark fw-bold' href={'/base/product/' + product.id}>{product.code}</a></td>
                <td width={160} className='text-center'>{Order(id)}</td>
                <td>{product.name}</td>
                <td className='text-center'>{product.category}</td>
                <td className='text-center'>
                    {centsToEur(product.cost_netto).toFixed(2)}
                </td>
                <td className={cellStyled(product.quantity)}>{product.quantity}</td>
                <td>{product.comment}</td>
                <td className='text-center'>{product.summ.toFixed(2) || ''}</td>
            </tr>
        )
    };
    const ProductTable = products.map((product, id) => ProductRow(product, id))
    const TableHeader =
        <thead>
            <tr>
                <th>КОД</th>
                <th>ЗАМОВЛЕННЯ</th>
                <th>НАЗВА</th>
                <th>КАТЕГОРІЯ</th>
                <th>ЦІНА</th>
                <th>СКЛАД</th>
                <th>СУМА</th>
            </tr>
        </thead>
    const InvoiceData =
        <>
            {!done && <Form.Group as={Row} className="mb-3">
                <Form.Group as={Col} className="mb-3">
                    <Form.Group as={Row}>
                        <Form.Label column sm="3" className='text-danger'>Постачальник</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name='seller'
                                value={order.seller || ''}
                                onChange={handleChange}
                                type="text"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm="3">Статус</Form.Label>
                        <Col sm="9">
                            <Form.Select
                                name='status'
                                value={order.status}
                                onChange={handleChange}
                                type="text">
                                {InvoiceStatuses.map(s => <option key={s.id} value={s.name}>{s.name}</option>)}
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">Дата</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name='date'
                                value={dateForm(order.date)}
                                onChange={handleChange}
                                type="date"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm="3">Коментар</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name='comment'
                                value={order.comment}
                                onChange={handleChange}
                                type="text"/>
                        </Col>
                    </Form.Group>
                </Form.Group>
            </Form.Group>}
        </>

    if (isLoading) {
        return (<Loader/>)
    }

    return (
        <>
            <BaseNavbar/>
            <Container className='mt-3'>
                <div className='text-center'>
                    <h2>
                        Накладна поставки №
                        {setOrderName()}
                    </h2>
                </div>
                {PushMessage({toast}, [show, setShow])}
                {InvoiceData}
                <Form.Group as={Row} className="mb-1">
                    <Form.Group as={Col} className="mb-1">
                        <Form.Check
                            type="checkbox"
                            label="Сховати порожні"
                            onClick={(event) => toggleHideUnordered()}
                        />
                    </Form.Group>
                </Form.Group>
                <Table bordered hover responsive>
                    {TableHeader}
                    <tbody>
                        {ProductTable}
                        <tr className='text-center'>
                            <td>{''}</td>
                            <td>{CalculateQuantity()}</td>
                            <td colSpan={5}>Сума</td>
                            <td>{CalculateSumm()/100}</td>
                        </tr>
                    </tbody>
                </Table>
                <Button
                    disabled={isFetching}
                    onClick={handleSubmit}
                    variant="outline-success">
                    ЗБЕРЕГТИ
                </Button>
                {done &&
                    <div className='text-center'>
                        <h3 className='text-success'>
                            Накладну збережено.
                        </h3>
                        <h4 className='text-secondary'>
                            Для оформлення наступної накладної оновіть сторінку
                        </h4>
                    </div>
                }
            </Container>
        </>
    )
};

export default NewInvoice;
