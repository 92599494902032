import React from 'react';


//TODO change api for production

const { REACT_APP_API_ENDPOINT } = process.env

const apiAddr  = REACT_APP_API_ENDPOINT

// const apiAddr  =   'http://localhost/api'

export {apiAddr};

const { REACT_APP_PUB_API_ENDPOINT } = process.env

const pubApiAddr  =  REACT_APP_PUB_API_ENDPOINT

// const pubApiAddr  =   'http://localhost/pub_api'

export {pubApiAddr};
