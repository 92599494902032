import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Loader} from "../Loader";
import {Button, Col, Form, Modal, Row, Table} from "react-bootstrap";
import dateNorm from "../../utils/dateForm";
import {apiAddr} from "../../utils/api";
import PushMessage from "../PushMessage";
import UpdateState from "./UpdateState";

const OrderList = () => {

    const [orders, setOrders] = useState([
        {
            id: 0,
            name: '',
            date: new Date(),
            status: '',
            comment: '',
            seller: null,
            created: new Date(),
            products: [],
            quantity: 0,
            summ: 0,
            is_deleted: false
        }
    ])
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    const handleDelete = (event, id) => {
        event.preventDefault()
        setId(id)
        handleShow()
    }
    const orderDelete = (event, Id) => {
        event.preventDefault()
        setIsFetching(true)
        setIsLoading(true);
        const order = orders.find(order => order.id === Id)
        if (order.products.length < 1) {
            fetch(apiAddr + '/invoices/' + Id, {
                method: 'PUT',
                body: JSON.stringify({
                    isDeleted: true,
                    products: [],
                    quantity: 0,
                    summ: 0
                }),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 200) {
                        setIsLoading(false)
                        setIsFetching(false)
                        setModalShow(false)
                        setShow(true)
                        setDeleted(true)
                    }
                })
                .catch((error) => {
                    console.log(error.message)
                });
        } else {
            fetch(apiAddr + '/invoices/' + Id, {
                method: 'PUT',
                body: JSON.stringify({
                    isDeleted: true,
                    products: [],
                    quantity: 0,
                    summ: 0
                }),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 200) {
                        UpdateState('get', order.products)
                        setIsLoading(false)
                        setIsFetching(false)
                        setModalShow(false)
                        setShow(true)
                        setDeleted(true)
                    }
                })
                .catch((error) => {
                    console.log(error.message)
                });
        }
    }
    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Накладну видалено ;-(',
        variant: 'light'
    });
    const [deleted, setDeleted] = useState(null)
    const [Id, setId] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        fetch(apiAddr + '/invoices', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                    if (data) {
                        setOrders(data)
                        setIsLoading(false)
                    }
                }
            )
    }, [deleted]);

    const statusStyled = (order) => {
        if (order.status === "Новий") return 'text-primary text-center fw-bolder'
        return 'text-muted bg-light'
    }

    const DeleteModal = (id) => {
        return (
            <>
                <Modal show={modalShow} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Підтвердити видалення</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Видалення накладної {id}-xxxx-xx-xx</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Відміна
                        </Button>
                        <Button variant="danger" onClick={event => orderDelete(event, Id)}>
                            ВИДАЛИТИ
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )

    }
    const OrderRow = (order, id) => {
        return (
            <tr key={id}>
                <td>
                    <a className='text-dark fw-bold' href={'/base/invoice/' + order.id}>
                        <small>{order.name === '' ? ((order.id) + '-' + dateNorm(order.created)
                        ) : order.code}</small>
                    </a>
                </td>
                <td><small>{order.date
                    .toString()
                    .slice(0, 10)
                    .split('-').reverse().join('-')
                }</small></td>
                <td>{order.seller}</td>
                <td className={statusStyled(order)}>
                    {order.status}
                </td>
                <td>{order.comment}</td>
                <td className='text-center'>{order.quantity || ''}</td>
                <td className='text-center'>{(order.summ / 100).toFixed(2) || ''}</td>

                <td
                    onClick={event => handleDelete(event, order.id)}
                    className='fw-bold text-danger'> X
                </td>
            </tr>
        )
    };
    const OrderTable = orders.filter(o => !o.is_deleted)
        .map((order, id) => OrderRow(order, id))
    const TableHeader =
        <thead>
            <tr className='text-center'>
                <th>КОД</th>
                <th>ДАТА</th>
                <th>ПОСТАЧАЛЬНИК</th>
                <th>СТАТУС</th>
                <th>ПРИМ</th>
                <th>КГ</th>
                <th>СУМА</th>
                <th></th>
            </tr>
        </thead>

    if (isLoading) {
        return (
            <Loader/>
        )
    }

    return (
        <Container>

            {PushMessage({toast}, [show, setShow])}

            {DeleteModal(Id)}
            <Button
                className="mt-4"
                onClick={(e) => window.location = '/base/invoice/new'}
                variant='outline-success'>
                Новий
            </Button>
            <Table striped bordered hover responsive size='sm' className='mt-4'>
                {TableHeader}
                <tbody>
                    {OrderTable}
                </tbody>
            </Table>
        </Container>
    )
};

export default OrderList;
