import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Loader} from "../Loader";
import {Button, Col, Form, InputGroup, Row, Table} from "react-bootstrap";
import PushMessage from "../PushMessage";
import dateForm from "../../utils/dateForm";
import {apiAddr} from "../../utils/api";
import UpdateState from "./UpdateState";

const ProductList = () => {

    const [products, setProducts] = useState([
        {
            id: '',
            code: '',
            name: '',
            category: '',
            cost_netto: '',
            cost_brutto: '',
            price_ko: '',
            price_mo: '',
            comment: '',
            tara: 0,
            unit: '',
            quantity: 0,
            ordered: false,
            ordered_quantity: 0,
            summ: 0
        }
    ]);
    const [customers, setCustomers] = useState([
        {
            id: 0,
            name: '',
            phone: ''
        }
    ]);
    const [done, setDone] = useState(false)
    const [users, setUsers] = useState([])
    const [client, setClient] = useState(null)
    const [hideUnordered, setHideUnordered] = useState(false)
    const [currate, setCurrate] = useState(0)
    const toggleHideUnordered = () => {
        setHideUnordered(!hideUnordered)
    }
    const centsToEur = (eur) => {
        return eur / 100
    }
    const [large, setLarge] = useState(true)
    const toggleLarge = (pos) => {
        setLarge(pos)
        pos ? setProducts(largeProducts(products)) : setProducts(littleProducts(products))
    }
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        message: 'Нове замовлення створено',
        variant: 'light'
    });
    const [order, setOrder] = useState({
        id: null,
        code: null,
        date: new Date(),
        status: 'Новий',
        payment: '',
        delivery: '',
        comment: '',
        customer: null,
        customer_name: '',
        created: {},
        updated: {},
        products: [],
        uah: null,
        eur: null,
        usd: null,
        quantity: 0,
        summ: 0,
        user: null,
        user_name: '',
    })

    const [isFetching, setIsFetching] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [isLoading3, setIsLoading3] = useState(true);

    const cellStyled = (q) => {
        if (q < 0) return 'text-danger text-center'
        return 'text-black text-center'
    }

    useEffect(() => {
            setIsLoading(true);
            axios.get(apiAddr + '/products')
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        setProducts(upProducts(response.data['hydra:member']))
                        setIsLoading(false);
                    }
                })
        }
        , []);

    useEffect(() => {
            setIsLoading1(true);
            axios.get(apiAddr + '/rates')
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        let lastElement = response.data['hydra:member'][0].rate;
                        setCurrate(lastElement / 100)
                        setIsLoading1(false);
                    }
                });
        }
        , []);

    useEffect(() => {
            setIsLoading2(true);
            axios.get(apiAddr + '/customers')
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        let custs = response.data['hydra:member'];
                        setCustomers(custs);
                        setIsLoading2(false);
                    }
                });
        }
        , []);

    useEffect(() => {
            setIsLoading3(true);
            axios.get(apiAddr + '/users')
                .then((response) => {
                    if (response.status === 200 && response.data) {
                        let usrs = response.data['hydra:member'].filter(u => !u.is_deleted);
                        setUsers(usrs);
                        setIsLoading3(false);
                    }
                });
        }
        , []);

    const upProducts = (prods) => {
        prods.forEach(function (element) {
            if (element.code === 'XXX') {
                element.ordered = true;
                element.ordered_quantity = 0;
                element.summ = 0;
            } else {
                element.ordered = false;
                element.ordered_quantity = 0;
                element.summ = 0;
                element.large = large
            }
        });
        return (prods)
    }

    const largeProducts = (prods) => {
        prods.forEach(function (element) {
            element.summ = centsToEur(element.price_ko) * element.ordered_quantity;
        });
        return (prods)
    }

    const littleProducts = (prods) => {
        prods.forEach(function (element) {
            element.summ = centsToEur(element.price_mo) * element.ordered_quantity;
        });
        return (prods)
    }

    const CalculateQuantity = () => {
        let Quantity = 0
        products.forEach(product => {
            if (product.category!=='послуга') {
                Quantity += product.ordered_quantity
            }
        })
        return Quantity
    }
    const CalculateSumm = () => {
        let Summ = 0
        products.forEach(product => {
            Summ = Summ + Number(product.summ * 100)
        })
        return Math.round(Summ*100)/100
    }

    const handleChange = event => {
        const {name, value} = event.target;
        if (name === 'customer') {
            let clnt = customers.find(el => el.id.toString() === value)
            setClient(clnt)
            setOrder({...order, customer_name: clnt.name, customer: clnt.id});
        } else if (name === 'user') {
            let usr = users.find(el => el.id.toString() === value)
            setOrder({...order, user_name: usr.name, user: usr.id});
        } else {
            setOrder({...order, [name]: value});
        }
    };
    const handleAddProduct = (event) => {
        const {name, value} = event.target;
        const Arr = [...products]
        let PV = Arr[name].ordered_quantity
        Arr[name].ordered_quantity = Number(value);
        Arr[name].ordered_quantity === 0 ? Arr[name].ordered = false : Arr[name].ordered = true;
        Arr[name].summ = large ? Arr[name].ordered_quantity * centsToEur(Arr[name].price_ko) : Arr[name].ordered_quantity * centsToEur(Arr[name].price_mo);
        Arr[name].quantity = Arr[name].quantity + PV - Number(value);
        setProducts(Arr);
    };
    const incrOrder = (event) => {
        const {name, value} = event.target;
        const Arr = [...products]
        Arr[name].ordered_quantity = Arr[name].ordered_quantity + Arr[name].tara;
        Arr[name].ordered_quantity === 0 ? Arr[name].ordered = false : Arr[name].ordered = true;
        Arr[name].summ = large ? Arr[name].ordered_quantity * centsToEur(Arr[name].price_ko) : Arr[name].ordered_quantity * centsToEur(Arr[name].price_mo);
        Arr[name].quantity = Arr[name].quantity - Arr[name].tara;
        setProducts(Arr);
    };
    const decrOrder = (event) => {
        const {name, value} = event.target;
        const Arr = [...products]
        Arr[name].ordered_quantity = Arr[name].ordered_quantity - Arr[name].tara;
        Arr[name].ordered_quantity === 0 ? Arr[name].ordered = false : Arr[name].ordered = true;
        Arr[name].summ = large ? Arr[name].ordered_quantity * centsToEur(Arr[name].price_ko) : Arr[name].ordered_quantity * centsToEur(Arr[name].price_mo);
        Arr[name].quantity = Arr[name].quantity + Arr[name].tara;
        setProducts(Arr);
    };
    const handleSubmit = () => {
        setIsFetching(true)
        if (Number(order.customer) > 0) {
            const ordprod = products.filter(product => product.ordered === true);
            const p = []
            ordprod.forEach(o => {
                p.push({
                    id: o.id,
                    code: o.code,
                    name: o.name,
                    cost_brutto: o.cost_brutto,
                    price: 100 * o.summ / o.ordered_quantity,
                    ordered_quantity: o.ordered_quantity,
                    summ: 100 * o.summ,
                    large: large
                })
            })

            const values =
                {
                    code: '',
                    customer: Number(order.customer),
                    customerName: order.customer_name,
                    comment: order.comment,
                    status: order.status,
                    payment: order.payment,
                    created: new Date(),
                    updated: new Date(),
                    user: Number(order.user),
                    userName: order.user_name,
                    uah: Number(order.uah),
                    eur: Number(order.eur),
                    usd: Number(order.usd),
                    date: order.date,
                    delivery: order.delivery,
                    isDeleted: false,
                    userUpdated: Number(order.user),
                    products: p,
                    quantity: CalculateQuantity(),
                    summ: Math.round(CalculateSumm())
                }
            fetch(apiAddr + '/orders', {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {'Content-Type': 'application/json',}
            })
                .then((response) => {
                    if (response.status === 201 && response) {
                        UpdateState('get', p)
                        // CUSTOMER ORDERS DATE UPD
                        fetch(apiAddr + '/customers/' + order.customer, {
                            method: 'PUT',
                            body: JSON.stringify({orders: [{date: order.date}]}),
                            headers: {'Content-Type': 'application/json',}
                        })
                            .then((response) => {
                                if (response.status === 200) {
                                    setToast({message: 'Нове замовлення створено', variant: 'light'})
                                    setTimeout(() => {
                                        setShow(true);
                                    }, 3000)
                                    console.log("ok")
                                }
                            })
                            .catch((error) => {
                                console.log(error.message)
                            });
                    }
                })
                .catch((error) => {
                    console.log(error.message)
                    setToast({message: error.message, variant: 'warning'})
                    setShow(true);
                });
            setDone(true)
        } else {
            setShow(true);
            setToast({message: 'Замовник не обраний', variant: 'warning'})
            setIsFetching(false)
        }
    }

    const Order = (id) => {
        return (
            <InputGroup className="">
                <Button
                    name={id}
                    variant="outline-secondary"
                    value={products[id].ordered_quantity || ''}
                    onClick={incrOrder}
                >
                    +
                </Button>
                <Form.Control
                    name={id}
                    type="number"
                    value={products[id].ordered_quantity || ''}
                    onChange={handleAddProduct}
                />
                <Button
                    name={id}
                    variant="outline-secondary"
                    value={products[id].ordered_quantity || ''}
                    onClick={decrOrder}
                >
                    -
                </Button>
            </InputGroup>
        )
    }

    const ProductRow = (product, id) => {
        return (
            <tr key={id} className={(hideUnordered) && (product.ordered !== true) ? 'd-none' : ''}>
                <td>{product.code}</td>
                <td width={160} className='text-center'>{Order(id)}</td>
                <td>{product.name}</td>
                <td className='text-center'>{product.category}</td>
                <td className='text-center'>{product.tara}{' '}{product.unit}</td>
                <td className='text-center'>
                    {large ?
                        product.price_ko ? centsToEur(product.price_ko).toFixed(2) : '0.00'
                        :
                        product.price_mo ? centsToEur(product.price_mo).toFixed(2) : '0.00'}
                </td>
                <td className={cellStyled(product.quantity)}>{product.quantity}</td>
                <td>{product.comment}</td>
                <td className='text-center'>{product.summ.toFixed(2) || ''}</td>
            </tr>
        )
    };
    const ProductTable = products.map((product, id) => ProductRow(product, id))
    const TableHeader =
        <thead>
            <tr>
                <th>КОД</th>
                <th>РЕЗЕРВ</th>
                <th>НАЗВА</th>
                <th>КАТЕГОРІЯ</th>
                <th>ТАРА</th>
                <th>ЦІНА</th>
                <th>СКЛАД</th>
                <th>ПРИМ</th>
                <th>СУМА</th>
            </tr>
        </thead>

    if (isLoading) {
        return (<Loader/>)
    }
    if (isLoading1) {
        return (<Loader/>)
    }
    if (isLoading2) {
        return (<Loader/>)
    }
    if (isLoading3) {
        return (<Loader/>)
    }

    return (
        <Container className='mt-3'>
            <Form>
                <Form.Check
                    inline
                    defaultChecked='true'
                    name='group'
                    label="Великий гурт"
                    type='radio'
                    onClick={(event) => toggleLarge(true)}
                    id='inline-radio-1'
                />
                <Form.Check
                    inline
                    name='group'
                    label="Дрібний гурт"
                    type='radio'
                    onClick={(event) => toggleLarge(false)}
                    id='inline-radio-2'
                />
            </Form>
            <Table bordered hover responsive>
                {TableHeader}
                <tbody>
                    {ProductTable}
                    <tr className='text-center'>
                        <td>{''}</td>
                        <td>{CalculateQuantity()}</td>
                        <td colSpan={6}>Сума</td>
                        <td>{(CalculateSumm()/100).toFixed(2)}</td>
                    </tr>
                </tbody>
            </Table>
            <Form.Group as={Row} className="mb-3">
                <Form.Group as={Col} className="mb-3">
                    <Form.Check
                        type="checkbox"
                        label="Сховати порожні"
                        onClick={(event) => toggleHideUnordered()}
                    />
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <Button variant="outline-success">
                        Курс : {currate}
                    </Button>
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <Button
                        onClick={() => navigator.clipboard.writeText((currate * CalculateSumm()/100).toFixed(0))}
                        variant="outline-success">
                        Сума грн : {(currate * CalculateSumm()/100).toFixed(0)}
                    </Button>
                </Form.Group>
            </Form.Group>
            {PushMessage({toast}, [show, setShow])}

            {!done && <Form.Group as={Row} className="mb-3">
                <Form.Group as={Col} className="mb-3">
                    <Form.Select
                        type="text"
                        name='customer'
                        onChange={handleChange}
                    >
                        <option>Оберіть клієнта</option>
                        {(customers.length > 0) ? (customers.map((cust, id) => (
                                    <option key={id} value={cust.id}>
                                        {cust.name}
                                    </option>
                                )
                            ))
                            : ''}
                    </Form.Select>
                    <Form.Group as={Row} className='mt-3'>
                        <Form.Label column sm="2">Телефон</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name='phone'
                                value={(client) ? client.phone : ''}
                                readOnly
                                type="text"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='mt-3'>
                        <Form.Label column sm="2">Viber</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name='viber'
                                value={(client) ? client.viber : ''}
                                readOnly
                                type="text"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='mt-3'>
                        <Form.Label column sm="2">Адреса</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name='address'
                                value={(client) ? (client.city + ', ' + client.region) : ''}
                                readOnly
                                type="text"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='mt-3'>
                        <Form.Label column sm="2">Доставка</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name='delivery'
                                value={(client) ? client.delivery : ''}
                                readOnly
                                type="text"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='mt-3'>
                        <Form.Label column sm="2">Примітки</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name='comment'
                                as="textarea"
                                value={(client) ? client.comment : ''}
                                readOnly
                                rows={4}/>
                        </Col>
                    </Form.Group>
                </Form.Group>

                <Form.Group as={Col} className="mb-3">
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">Дата</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name='date'
                                value={dateForm(order.date)}
                                onChange={handleChange}
                                type="date"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm="2">Оплата</Form.Label>
                        <Col sm="10">
                            <Form.Select
                                name='payment'
                                value={order.payment}
                                onChange={handleChange}
                                type="text"
                            >
                                <option>Форма оплати</option>
                                <option value="Готівка">Готівка</option>
                                <option value="Безготівка">Безготівка</option>
                                <option value="Післяплата">Післяплата</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm="2">Доставка</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name='delivery'
                                value={order.delivery}
                                onChange={handleChange}
                                type="text"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm="2">Оплата</Form.Label>
                        <Col sm="10">
                            <InputGroup>
                                <InputGroup.Text>ГРН</InputGroup.Text>
                                <Form.Control
                                    name='uah'
                                    value={order.uah}
                                    onChange={handleChange}
                                    type="number"/>
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>EUR</InputGroup.Text>
                                <Form.Control
                                    name='eur'
                                    value={order.eur}
                                    onChange={handleChange}
                                    type="number"/>
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>USD</InputGroup.Text>
                                <Form.Control
                                    name='usd'
                                    value={order.usd}
                                    onChange={handleChange}
                                    type="number"/>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm="2">Коментар</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name='comment'
                                value={order.comment}
                                onChange={handleChange}
                                type="text"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm="2">Оформив</Form.Label>
                        <Col sm="10">
                            <Form.Select
                                name='user'
                                onChange={handleChange}
                                type="text">
                                <option>Оберіть працівника</option>
                                {(users.length > 0) ? (users.map((us, id) => (
                                            <option key={id} value={us.id}>
                                                {us.name}
                                            </option>
                                        )
                                    ))
                                    : ''}
                            </Form.Select>
                        </Col>
                    </Form.Group>
                    <Button
                        disabled={isFetching}
                        onClick={handleSubmit}
                        variant="outline-success">
                        ВПЕРЕД
                    </Button>
                </Form.Group>
            </Form.Group>}
            {done &&
                <div className='text-center'>
                    <h3 className='text-success'>
                        Замовлення збережено.
                    </h3>
                    <h4 className='text-secondary'>
                        Для оформлення нового замовлення оновіть сторінку
                    </h4>
                </div>
            }
        </Container>
    )
};

export default ProductList;
